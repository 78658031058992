const jedJs = `const i18n = new Jed({
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        lang: "en",
        plural_forms: "nplurals=2; plural=(n != 1);",
      },

      "cake\u0004dessert": [
        "I would like to eat a cake.",
        "I would like to eat %d cakes.",
      ],
      "muffin\u0004dessert": [
        "I would like to eat a muffin.",
        "I would like to eat %d muffins.",
      ],
      dessert: ["I would like to eat something."],
    },
  },
})
`

export default jedJs
