import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getIsLoggedIn = () => {
  return axios.get(`${BASE_URL}/is_logged_in`, { withCredentials: true })
}

export const postLogin = ({
  username,
  password,
}: {
  username: string
  password: string
}) => {
  return axios.post(
    `${BASE_URL}/login`,
    {
      username: username,
      password: password,
    },
    { withCredentials: true },
  )
}

export const postRegister = ({
  username,
  password,
  mail,
}: {
  username: string
  password: string
  mail: string
}) => {
  return axios.post(
    `${BASE_URL}/register`,
    {
      username,
      password,
      mail,
    },
    { withCredentials: true },
  )
}

export const postLogout = () => {
  return axios.post(
    `${BASE_URL}/logout`,
    {},
    {
      withCredentials: true,
    },
  )
}

export const sendPasswordToken = ({ mail }: { mail: string }) => {
  return axios.post(
    `${BASE_URL}/generate_password_reset_token`,
    {
      mail,
    },
    { withCredentials: true },
  )
}

export const getUserByToken = ({
  passwordToken,
}: {
  passwordToken: string
}) => {
  return axios.get(`${BASE_URL}/get_user/${passwordToken}`, {
    withCredentials: true,
  })
}

export const resetPassword = ({
  passwordToken,
  newPassword,
}: {
  passwordToken: string
  newPassword: string
}) => {
  return axios.post(
    `${BASE_URL}/reset_password/${passwordToken}`,
    {
      passwordToken,
      newPassword,
    },
    { withCredentials: true },
  )
}
