import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getProjects = () => {
  return axios.get(`${BASE_URL}/projects`, {
    withCredentials: true,
  })
}

export const getProjectLangsProgressions = ({projectId}:{projectId: number}) => {
  return axios.get(`${BASE_URL}/project/${projectId}/lang_progressions`, {
    withCredentials: true,
  })
}

export const postProject = ({
  name,
  souceLang,
}: {
  name: string
  souceLang: string
}) => {
  return axios.post(
    `${BASE_URL}/project`,
    { project_name: name, source_lang: souceLang },
    {
      withCredentials: true,
    },
  )
}


export const postProjectLang = ({
  projectId,
  lang,
}: {
  projectId: number
  lang: string
}) => {
  return axios.post(
    `${BASE_URL}/project/${projectId}/new_lang`,
    { lang: lang },
    {
      withCredentials: true,
    },
  )
}

export const deleteProjectLang = ({
  projectLangId,
}: {
  projectLangId: number
}) => {
  return axios.delete(
    `${BASE_URL}/project/lang/${projectLangId}`,
    {
      withCredentials: true,
    }
  )
}

export const deleteProject = ({
  projectId,
}: {
  projectId: number
}) => {
  return axios.delete(
    `${BASE_URL}/project/${projectId}`,
    {
      withCredentials: true,
    }
  )
}

