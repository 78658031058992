import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { frFR } from "./fr-FR"
import { arAE } from "./ar-AE"
import { afZA } from "./af-ZA"
import { bgBG } from "./bg-BG"
import { csCZ } from "./cs-CZ"
import { daDK } from "./da-DK"
import { deDE } from "./de-DE"
import { elGR } from "./el-GR"
import { enUS } from "./en-US"
import { esES } from "./es-ES"
import { etEE } from "./et-EE"
import { fiFI } from "./fi-FI"
import { heIL } from "./he-IL"
import { hrHR } from "./hr-HR"
import { huHU } from "./hu-HU"
import { hyAM } from "./hy-AM"
import { idID } from "./id-ID"
import { isIS } from "./is-IS"
import { itIT } from "./it-IT"
import { jaJP } from "./ja-JP"
import { koKR } from "./ko-KR"
import { lbLU } from "./lb-LU"
import { nbNO } from "./nb-NO"
import { nlNL } from "./nl-NL"
import { ptBR } from "./pt-BR"
import { ptPT } from "./pt-PT"
import { roRO } from "./ro-RO"
import { ruRU } from "./ru-RU"
import { skSK } from "./sk-SK"
import { slSI } from "./sl-SI"
import { sqAL } from "./sq-AL"
import { svSE } from "./sv-SE"
import { thTH } from "./th-TH"
import { trTR } from "./tr-TR"
import { ukUA } from "./uk-UA"
import { viVN } from "./vi-VN"
import { zhCN } from "./zh-CN"

//empty for now
const resources = {
  "fr-FR": { translation: frFR },
  "ar-AE": { translation: arAE },
  "af-ZA": { translation: afZA },
  "bg-BG": { translation: bgBG },
  "cs-CZ": { translation: csCZ },
  "da-DK": { translation: daDK },
  "de-DE": { translation: deDE },
  "el-GR": { translation: elGR },
  "en-US": { translation: enUS },
  "es-ES": { translation: esES },
  "et-EE": { translation: etEE },
  "fi-FI": { translation: fiFI },
  "he-IL": { translation: heIL },
  "hr-HR": { translation: hrHR },
  "hu-HU": { translation: huHU },
  "hy-AM": { translation: hyAM },
  "id-ID": { translation: idID },
  "is-IS": { translation: isIS },
  "it-IT": { translation: itIT },
  "ja-JP": { translation: jaJP },
  "ko-KR": { translation: koKR },
  "lb-LU": { translation: lbLU },
  "nb-NO": { translation: nbNO },
  "nl-NL": { translation: nlNL },
  "pt-BR": { translation: ptBR },
  "pt-PT": { translation: ptPT },
  "ro-RO": { translation: roRO },
  "ru-RU": { translation: ruRU },
  "sk-SK": { translation: skSK },
  "sl-SI": { translation: slSI },
  "sq-AL": { translation: sqAL },
  "sv-SE": { translation: svSE },
  "th-TH": { translation: thTH },
  "tr-TR": { translation: trTR },
  "uk-UA": { translation: ukUA },
  "vi-VN": { translation: viVN },
  "zh-CN": { translation: zhCN },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lng",
    },
    compatibilityJSON: "v3",
    resources,
    fallbackLng: "en-US",
    supportedLngs: [
      "fr-FR",
      "ar-AE",
      "af-ZA",
      "bg-BG",
      "cs-CZ",
      "da-DK",
      "de-DE",
      "el-GR",
      "en-US",
      "es-ES",
      "et-EE",
      "fi-FI",
      "he-IL",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "ko-KR",
      "lb-LU",
      "nb-NO",
      "nl-NL",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "sk-SK",
      "sl-SI",
      "sq-AL",
      "sv-SE",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "vi-VN",
      "zh-CN",
    ],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n

// export const i18n = new I18n({
//     'fr': frFR,
//     'en': enUS,
//     'nl': nlNL
// });

// // Set the locale once at the beginning of your app.
// i18n.locale = Localization.locale;

// // When a value is missing from a language it'll fallback to another language with the key present.
// i18n.enableFallback = true;
// i18n.defaultLocale = 'fr';
