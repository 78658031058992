import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { sha1 } from "js-sha1"
import posthog from "posthog-js"
import ReactGA from "react-ga4"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "../../context/Auth"
import { getUserByToken, resetPassword } from "../../services/auth"

export const ResetPassword = () => {
  const { t } = useTranslation()
  let { token } = useParams()
  const navigate = useNavigate()

  const { currentUser } = useContext(AuthContext)

  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const [formError, setFormError] = useState<string>()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  useEffect(() => {
    document.title = t("auth.reset_password")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")

      ReactGA.send({
        hitType: "pageview",
        page: "/reset-password",
        title: "Reset Password",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadUser = async (passwordToken: string) => {
      return await getUserByToken({ passwordToken })
    }

    setIsSuccess(false)
    if (!token) {
      navigate("/forgot-password")
    } else {
      loadUser(token)
        .then((data_user) => {
          if (!data_user?.data?.id_user) {
            navigate("/forgot-password")
          }
        })
        .catch((err) => {
          navigate("/forgot-password")
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const postResetPassword = async () => {
    setFormError(undefined)

    if (!token) {
      setFormError("missing_token")
    } else if (newPassword.length < 8) {
      setFormError("password_length")
    } else if (newPassword !== newPasswordConfirmation) {
      setFormError("password_confirmation")
    } else {
      try {
        await resetPassword({
          passwordToken: token,
          newPassword: sha1(newPassword),
        })
        setFormError(undefined)
        setIsSuccess(true)
      } catch (error) {
        setFormError("server_error")
      }
    }
  }

  const handleNewPassword = (event: any) => {
    setNewPassword(event.target.value)
  }

  const handleNewPasswordConfirmation = (event: any) => {
    setNewPasswordConfirmation(event.target.value)
  }

  return (
    <div className="basic-container auth-container">
      <div className="login-title">
        <div className="logo-text">
          <h1 className="font-bold">{t("auth.reset_password")}</h1>
        </div>
      </div>

      <div className="flex-column white-box login-box">
        {!isSuccess && (
          <form
            onSubmit={(event) => {
              event.preventDefault()
              postResetPassword()
            }}
          >
            <div className="field">
              <label className="label text">{t("auth.new_password")}</label>

              <div className="control has-icons-left">
                <input
                  className={`full-width input ${formError ? "is-danger" : ""}`}
                  type="password"
                  placeholder={t("auth.password")}
                  value={newPassword}
                  onChange={handleNewPassword}
                />
                <span className="icon is-small is-left">
                  <span className="msymbol material-symbols-outlined">
                    lock
                  </span>
                </span>
              </div>
            </div>

            <div className="field">
              <label className="label text">
                {t("auth.password_confirmation")}
              </label>

              <div className="control has-icons-left">
                <input
                  className={`full-width input ${
                    formError && formError === "password_confirmation"
                      ? "is-danger"
                      : ""
                  }`}
                  type="password"
                  placeholder={t("auth.password")}
                  value={newPasswordConfirmation}
                  onChange={handleNewPasswordConfirmation}
                />
                <span className="icon is-small is-left">
                  <span className="msymbol material-symbols-outlined">
                    lock
                  </span>
                </span>
              </div>
            </div>

            <button
              className="button is-primary-gradient login-button"
              type="submit"
              disabled={newPasswordConfirmation.length < 8}
            >
              {t("auth.reset")}
            </button>

            {formError && formError === "missing_token" && (
              <p className="help is-danger">{t("auth.error_missing_token")}</p>
            )}
            {formError && formError === "password_length" && (
              <p className="help is-danger">
                {t("auth.error_password_length")}
              </p>
            )}
            {formError && formError === "password_confirmation" && (
              <p className="help is-danger">
                {t("auth.error_password_confirmation")}
              </p>
            )}
          </form>
        )}

        {isSuccess && (
          <>
            <h2 style={{ fontSize: "1.25rem" }}>
              {t("auth.success_reset_password")}
            </h2>

            {!currentUser ? (
              <Link
                className="button is-primary-gradient login-button"
                to="/auth"
              >
                {t("auth.login")}
              </Link>
            ) : (
              <Link className="button is-bordered login-button" to="/profile">
                {t("commons.back")}
              </Link>
            )}
          </>
        )}
      </div>

      {!currentUser && (
        <div className="flex-row flex-center text-center">
          <Link
            className="auth-link"
            to="/auth"
            style={{ marginRight: "0.5rem" }}
          >
            {t("auth.login")}
          </Link>
          <Link
            className="auth-link"
            to="/register"
            style={{ marginLeft: "0.5rem" }}
          >
            {t("auth.register")}
          </Link>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
