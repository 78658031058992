import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getApiKeys = () => {
  return axios.get(`${BASE_URL}/api_keys`, {
    withCredentials: true,
  })
}

export const postApiKey = ({ name }: { name: string }) => {
  return axios.post(
    `${BASE_URL}/api_key`,
    { name },
    {
      withCredentials: true,
    },
  )
}

export const revokeApiKey = ({ id_api_key }: { id_api_key: number }) => {
  return axios.put(
    `${BASE_URL}/revoke_key`,
    { id_api_key },
    {
      withCredentials: true,
    },
  )
}
