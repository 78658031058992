import { useCallback, useContext, useEffect, useState } from "react"
import { PaymentContext } from "../../context/Payment"
import {
  createPaymentIntent,
  createSubscription,
  updatePaymentIntent,
} from "../../services/credits"
import CheckoutForm from "./CheckoutFrom"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import AnimatedLoader from "./AnimatedLoader"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "")

type Props = {
  paymentAmount?: number
  priceId?: string
  refreshResult: () => void
  isLoaded: () => void
}

const ModalPayment = ({
  paymentAmount,
  priceId,
  refreshResult,
  isLoaded,
}: Props) => {
  const {
    paymentId,
    setPaymentId,
    resetPaymentId,
    subscriptionId,
    setSubscriptionId,
    resetSubscriptionId,
  } = useContext(PaymentContext)

  const [clientSecret, setClientSecret] = useState("")

  const create = useCallback(async () => {
    if (paymentAmount) {
      return await createPaymentIntent({ amount: paymentAmount })
    } else {
      throw new Error("paymentAmount missing")
    }
  }, [paymentAmount])

  const update = useCallback(async () => {
    if (paymentAmount) {
      return await updatePaymentIntent({
        amount: paymentAmount,
        pi_id: paymentId,
      })
    } else {
      throw new Error("paymentAmount missing")
    }
  }, [paymentAmount, paymentId])

  const createSub = useCallback(async () => {
    if (priceId) {
      return await createSubscription({
        priceId: priceId,
      })
    } else {
      throw new Error("priceId missing")
    }
  }, [priceId])

  const handleResult = useCallback(
    (result: any) => {
      setClientSecret(result?.data?.client_secret)
      setPaymentId(result?.data?.id)
      isLoaded()
    },
    [setClientSecret, setPaymentId, isLoaded],
  )

  const handleError = useCallback(
    (err: any) => {
      resetPaymentId()
      resetSubscriptionId()
    },
    [resetPaymentId, resetSubscriptionId],
  )

  useEffect(() => {
    if (paymentAmount) {
      if (paymentId) {
        update().then(handleResult).catch(handleError)
      } else {
        create().then((result) => {
          setClientSecret(result?.data?.client_secret)
          setPaymentId(result?.data?.id)
          isLoaded()
        })
      }
    } else if (priceId) {
      createSub()
        .then((result) => {
          setClientSecret(result?.data?.client_secret)
          setSubscriptionId(result?.data?.subscription_id)
          isLoaded()
        })
        .catch(handleError)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ minHeight: 365, maxHeight: 800 }}>
      {/* <h1 className="modal-title">{title}</h1> */}
      {(!clientSecret ||
        (paymentAmount && !paymentId) ||
        (priceId && !subscriptionId)) && (
        <div>
          <AnimatedLoader />
        </div>
      )}
      {clientSecret && paymentId && paymentAmount && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
            appearance: {
              theme: "flat",
              variables: {
                fontFamily: ' "Gill Sans", sans-serif',
                fontLineHeight: "1.5",
                borderRadius: "5px",
                colorBackground: "#F6F8FA",
                colorPrimaryText: "#262626",
                colorPrimary: "#6d28d9",
                colorIconTabHover: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "#fff"
                  : "#101016",
                colorIconTabSelected: "#2e1065",
                colorTextPlaceholder:
                  document.documentElement.className.includes("theme--dark")
                    ? "#2f2f2f"
                    : "#cccccc",
              },
              rules: {
                ".Block": {
                  backgroundColor: "var(--colorBackground)",
                  boxShadow: "none",
                  padding: "12px",
                },
                ".Input": {
                  padding: "12px",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#eee"
                    : "#101016",
                  backgroundColor: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "rgb(10, 15, 20)"
                    : "rgb(240, 245, 250)",
                  border: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "1px solid rgba(255, 255, 255, 0.05)"
                    : "1px solid rgba(0, 0, 0, 0.15)",
                },
                ".Input:disabled, .Input--invalid:disabled": {
                  color: "lightgray",
                },
                ".Input:focus": {
                  boxShadow: "0 0 0 1px #2e1065",
                  border: "1px solid #2e1065",
                },
                ".Tab": {
                  padding: "10px 12px 8px 12px",
                  border: "none",
                  backgroundColor: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "rgb(10, 15, 20)"
                    : "rgb(240, 245, 250)",
                },
                ".Tab:hover": {
                  border: "none",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#fff"
                    : "#101016",

                  boxShadow:
                    "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(109, 40, 217, 0.04)",
                },
                ".Tab:hover .Icon": {
                  fill: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#fff"
                    : "#101016",
                },
                ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
                  border: "none",
                  backgroundColor: "rgba(109, 40, 217, 0.1333333333)",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#fff"
                    : "#101016",
                  boxShadow: "none",
                },
                ".TabLabel": {
                  fontWeight: "bold",
                },
                ".Label": {
                  fontWeight: "600",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "rgba(255, 255, 255, 0.8)"
                    : "rgba(0, 0, 0, 0.54)",
                },
              },
            },
          }}
        >
          <CheckoutForm
            paymentAmount={paymentAmount}
            reloadCredits={refreshResult}
          />
        </Elements>
      )}

      {clientSecret && subscriptionId && priceId && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret,
            appearance: {
              theme: "flat",
              variables: {
                fontFamily: ' "Gill Sans", sans-serif',
                fontLineHeight: "1.5",
                borderRadius: "5px",
                colorBackground: "#F6F8FA",
                colorPrimaryText: "#262626",
                colorPrimary: "#6d28d9",
                colorIconTabHover: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "#fff"
                  : "#101016",
                colorIconTabSelected: "#2e1065",
                colorTextPlaceholder:
                  document.documentElement.className.includes("theme--dark")
                    ? "#2f2f2f"
                    : "#cccccc",
              },
              rules: {
                ".Block": {
                  backgroundColor: "var(--colorBackground)",
                  boxShadow: "none",
                  padding: "12px",
                },
                ".Input": {
                  padding: "12px",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#eee"
                    : "#101016",
                  backgroundColor: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "rgb(10, 15, 20)"
                    : "rgb(240, 245, 250)",
                  border: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "1px solid rgba(255, 255, 255, 0.05)"
                    : "1px solid rgba(0, 0, 0, 0.15)",
                },
                ".Input:disabled, .Input--invalid:disabled": {
                  color: "lightgray",
                },
                ".Input:focus": {
                  boxShadow: "0 0 0 1px #2e1065",
                  border: "1px solid #2e1065",
                },
                ".Input, .p-FauxInput": {
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#888"
                    : "#888",
                },
                ".Tab": {
                  padding: "10px 12px 8px 12px",
                  border: "none",
                  backgroundColor: document.documentElement.className.includes("theme--dark")
                  ? "rgb(10, 15, 20)"
                  : "rgb(240, 245, 250)",
                },
                ".Tab:hover": {
                  border: "none",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#fff"
                    : "#101016",

                  boxShadow:
                    "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(109, 40, 217, 0.04)",
                },
                ".Tab:hover .Icon": {
                  fill: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#fff"
                    : "#101016",
                },
                ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
                  border: "none",
                  backgroundColor: "rgba(109, 40, 217, 0.1333333333)",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "#fff"
                    : "#101016",
                  boxShadow: "none",
                },
                ".TabLabel": {
                  fontWeight: "bold",
                },
                ".Label": {
                  fontWeight: "600",
                  color: document.documentElement.className.includes(
                    "theme--dark",
                  )
                    ? "rgba(255, 255, 255, 0.8)"
                    : "rgba(0, 0, 0, 0.54)",
                },
              },
            },
          }}
        >
          <CheckoutForm priceId={priceId} reloadCredits={refreshResult} />
        </Elements>
      )}
    </div>
  )
}

export default ModalPayment
