import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { UIContext } from "../../context/UI"
import { TranslationContext } from "../../context/Translation"

type Props = {
  className?: string
  selected?: boolean
  rightMenu?: boolean
  hideIcon?: boolean
}

export const DropdownLanguage = ({
  className,
  selected,
  rightMenu,
  hideIcon,
}: Props) => {
  const { accountDropdownOpen, setAccountDropdownOpen } = useContext(UIContext)
  const { languages } = useContext(TranslationContext)
  const currentId = useRef("id" + Math.random().toString(16).slice(2))
  const { i18n } = useTranslation()

  const [selectedLang, setSelectedLang] = useState<string[]>([
    "en",
    "🇺🇸",
    "en-US",
  ])

  const { language: currentLanguage } = i18n

  useEffect(() => {
    let lang = languages.find((i) => i[2] === currentLanguage.toString())
    if (!lang) lang = languages[2]
    setSelectedLang(lang)
  }, [currentLanguage, languages])

  const selectLang = async (lang: string[]) => {
    setSelectedLang(lang)
    localStorage.setItem("currentLang", lang[2])
    await i18n.changeLanguage(lang[2])
  }

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  return (
    <div
      className={
        "dropdown language-selector " +
        (accountDropdownOpen === currentId.current ? "is-active " : "") +
        className
      }
    >
      <div className="dropdown-trigger full-width">
        <button
          className="button dropdown-button full-width"
          aria-controls="dropdown-menu"
          style={{ minWidth: 150 }}
          onClick={() => {
            if (accountDropdownOpen) {
              setAccountDropdownOpen(null)
            } else {
              setAccountDropdownOpen(currentId.current)
            }
          }}
          onBlur={() => {
            setAccountDropdownOpen(null)
          }}
        >
          <div className="dropdown-circle" style={{ marginLeft: "0.3rem" }}>
            <span className={getFlagClass(selectedLang[2])}></span>
          </div>
          <span
            className={"text-ellipsis flex-1 " + (selected ? "font-bold" : "")}
            style={{ marginLeft: "1rem" }}
          >
            {selectedLang[2]}
          </span>

          <span className="icon is-small" style={{ marginRight: "-0.25rem" }}>
            {accountDropdownOpen === currentId.current ? (
              <span className="msymbol material-symbols-outlined">
                keyboard_arrow_down
              </span>
            ) : (
              <span className="msymbol material-symbols-outlined">
                keyboard_arrow_up
              </span>
            )}
          </span>
        </button>
      </div>

      <div
        className="dropdown-menu"
        id="dropdown-menu"
        role="menu"
        style={
          rightMenu
            ? { minWidth: 150, top: -250, height: 250, left: 0, right: 0 }
            : { minWidth: 150, top: -250, height: 250, left: 0, right: 0 }
        }
      >
        <div className="dropdown-content">
          {languages.map((item) => (
            <div
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                selectLang(item)
              }}
              className="dropdown-item"
            >
              <div
                className={`dropdown-link flex-row ${
                  item[2] === currentLanguage.toString() ? "selected" : ""
                }`}
              >
                <div
                  className="dropdown-circle"
                  style={{ marginLeft: "0.3rem" }}
                >
                  <span className={getFlagClass(item[2])}></span>
                </div>
                <span
                  className="text-ellipsis flex-1 "
                  style={{ marginLeft: "1.3rem" }}
                >
                  {item[2]}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DropdownLanguage
