import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getPaymentMethods = () => {
  return axios.get(`${BASE_URL}/payment_methods`, {
    withCredentials: true,
  })
}

export const setPaymentMethodAsDefault = ({
  paymentMethodId,
}: {
  paymentMethodId: number
}) => {
  return axios.put(
    `${BASE_URL}/set_payment_method_as_default/${paymentMethodId}`,
    {},
    {
      withCredentials: true,
    },
  )
}

export const detachPaymentMethod = ({
  paymentMethodId,
}: {
  paymentMethodId: number
}) => {
  return axios.delete(`${BASE_URL}/detach_payment_methods/${paymentMethodId}`, {
    withCredentials: true,
  })
}
