import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { TranslationEditorContext } from "../../../context/TranslationEditor"
import { AuthContext } from "../../../context/Auth"
import Dropdown from "../../components/Dropdown"
import { deleteProject } from "../../../services/projects"
import ModalConfirmation from "../../components/ModalConfirmation"
import Modal from "../../components/Modal"
import ModalLimitReached from "../../components/ModalLimitReached"
import AmountLabeled from "../../components/AmountLabeled"
import AmountBar from "../../components/AmountBar"

export const Projects = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    currentBalance,
    projectCount,
    langUsed,
    editorKeyCount,
    userAccountTier,
  } = useContext(AuthContext)
  const {
    projects,
    setSelectedProject,
    setModalProjectIsShown,
    setPreventDoubleSubmit,
    refreshProjects,
    setSelectedLangs,
  } = useContext(TranslationEditorContext)

  const [showProjectDeleteConfirmation, setShowProjectDeleteConfirmation] =
    useState(false)
  const [projectToDelete, setProjectToDelete] = useState<any>(undefined)

  useEffect(() => {
    setSelectedLangs(undefined)
    setSelectedProject(undefined)
  }, [])

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  const handleDeleteProject = async (project_id: number) => {
    await deleteProject({
      projectId: project_id,
    })
    refreshProjects()
    setPreventDoubleSubmit(false)
  }

  return (
    <>
      <div
        className="basic-container editor-container"
        style={{
          maxWidth: "100%",
          overflow: "auto",
        }}
      >
        <AmountBar itemPerLine={4} />

        <h2
          className="sub-title"
          style={{
            margin: 0,
            marginBottom: "1rem",
          }}
        >
          {t("translationEditor.projects")}
        </h2>
        <div className="project-grid">
          <div
            className="add-grid-item flex-column flex-center bordered-div"
            onClick={() => setModalProjectIsShown(true)}
          >
            <div className="plus-image">
              <span className="msymbol material-symbols-outlined">add</span>
            </div>
            <h3 style={{ fontSize: 24, fontWeight: 700 }}>
              {t("translationEditor.create_a_project")}
            </h3>
          </div>

          {projects?.map((project) => (
            <div
              className="project-item"
              onClick={() => {
                navigate(`/editor/project/${project.id_project}/files`)
              }}
            >
              <div className="flex-row">
                <div className="project-circle">{project.name[0]}</div>
                <div className="flex-column flex-1">
                  <h3 style={{ fontSize: 18, fontWeight: 700 }}>
                    {project.name}
                  </h3>

                  <div className="full-width flex-row half-opacity">
                    <div style={{ fontWeight: 600 }}>
                      <div
                        className={getFlagClass(
                          project?.langs?.find(
                            (i: any) => i.is_source_lang === true,
                          ).lang,
                        )}
                        style={{ marginRight: "0.75rem" }}
                      ></div>

                      {
                        project?.langs?.find(
                          (i: any) => i.is_source_lang === true,
                        ).lang
                      }
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ margin: "1rem 0" }} />

              <div className="flex-1">
                <div className="full-width flex-row flex-center ">
                  <div className="flex-1 half-opacity" style={{fontSize: "0.9rem"}}>
                    {t("usage.languages")}
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {project?.langs?.length - 1}
                  </div>
                </div>
                <div className="full-width flex-row flex-center ">
                  <div className="flex-1 half-opacity" style={{fontSize: "0.9rem"}}>
                    {t("translationEditor.files")}
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {project?.files?.length}
                  </div>
                </div>
                <div className="full-width flex-row flex-center ">
                  <div className="flex-1 half-opacity" style={{fontSize: "0.9rem"}}>
                    {t("translationEditor.keys")}
                  </div>
                  <div style={{ fontWeight: "bold" }}>{(project?.keyCount || 0).toLocaleString()}</div>
                </div>
                <div className="full-width flex-row flex-center ">
                  <div className="flex-1 half-opacity" style={{fontSize: "0.9rem"}}>
                    {t("credits.used_editor_keys")}
                  </div>
                  <div style={{ fontWeight: "bold" }}>{(project?.stringCount || 0).toLocaleString()}</div>
                </div>
              </div>

              <div
                className="full-width flex-row"
                style={{ marginTop: "1rem" }}
              >
                <button
                  className="button is-primary-gradient flex-1"
                  style={{ marginRight: "0.75rem" }}
                  onClick={() => {
                    navigate(`/editor/project/${project.id_project}/files`)
                  }}
                >
                  {t("commons.select")}
                </button>

                <Dropdown
                  rightMenu={true}
                  buttonClassName="button is-bordered"
                  iconButton={"more_horiz"}
                  buttonStyle={{
                    padding: "0.5rem",
                    width: "40px",
                  }}
                  iconStyle={{ fontSize: 18 }}
                >
                  <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.stopPropagation()
                      setProjectToDelete(project)
                      setShowProjectDeleteConfirmation(true)
                    }}
                    className="dropdown-item dropdown-option "
                  >
                    <span className="msymbol material-symbols-outlined danger">
                      delete
                    </span>
                    {t("translationEditor.delete_project")}
                  </button>
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalConfirmation
        isShown={showProjectDeleteConfirmation}
        closeModal={() => {
          setProjectToDelete(undefined)
          setShowProjectDeleteConfirmation(false)
        }}
        onConfirm={() => {
          setPreventDoubleSubmit(true)
          handleDeleteProject(projectToDelete.id_project)

          setProjectToDelete(undefined)
          setShowProjectDeleteConfirmation(false)
        }}
        textToType={projectToDelete?.name}
      >
        <h1 className="modal-title">
          {t("translationEditor.confirm_delete_project_title")}
        </h1>

        <p>
          {t("translationEditor.confirm_delete_project_text")} <br />{" "}
          <b>{projectToDelete?.name}</b>
        </p>
      </ModalConfirmation>
    </>
  )
}

export default Projects
