import { useTranslation } from "react-i18next"

type Props = {
  currentPage: number
  totalItem: number
  itemPerPage: number
  changeCurrentPage: (page: number) => void
}

export const Paginator = ({
  currentPage,
  totalItem,
  itemPerPage,
  changeCurrentPage,
}: Props) => {
  const { t } = useTranslation()

  const maxPage = Math.ceil(totalItem / itemPerPage)

  const previousPage = () => {
    if (currentPage > 1) {
      changeCurrentPage(currentPage - 1)
    }
  }

  const nextPage = () => {
    if (itemPerPage * currentPage < totalItem) {
      changeCurrentPage(currentPage + 1)
    }
  }

  if (maxPage < 2) {
    return <></>
  } else {
    return (
      <div className="flex-row flex-center">
        <div className="paginator flex-row">
          <button
            disabled={currentPage === 1}
            className="button not-square"
            onClick={previousPage}
          >
            <span className="previous-icon material-symbols-outlined">
              keyboard_arrow_left
            </span>
            <span className="hide-phone">{t("commons.previous")}</span>
          </button>
          <button
            className={
              "button " + (currentPage === 1 ? "is-primary-gradient" : "")
            }
            onClick={() => changeCurrentPage(1)}
          >
            {1}
          </button>

          {currentPage - 3 > 1 && (
            <div className="more-item flex-center">...</div>
          )}

          {currentPage - 2 > 1 && (
            <button
              className="hide-phone button"
              onClick={() => changeCurrentPage(currentPage - 2)}
            >
              {currentPage - 2}
            </button>
          )}

          {currentPage - 1 > 1 && (
            <button
              className="button"
              onClick={() => changeCurrentPage(currentPage - 1)}
            >
              {currentPage - 1}
            </button>
          )}

          {currentPage !== 1 && currentPage !== maxPage && (
            <button className="button is-primary-gradient">
              {currentPage}
            </button>
          )}

          {currentPage + 1 < maxPage && (
            <button
              className="button"
              onClick={() => changeCurrentPage(currentPage + 1)}
            >
              {currentPage + 1}
            </button>
          )}

          {currentPage + 2 < maxPage && (
            <button
              className="hide-phone button"
              onClick={() => changeCurrentPage(currentPage + 2)}
            >
              {currentPage + 2}
            </button>
          )}
          {currentPage + 3 < maxPage && (
            <div className="more-item flex-center">...</div>
          )}

          <button
            className={
              "button " + (currentPage === maxPage ? "is-primary-gradient" : "")
            }
            onClick={() => changeCurrentPage(maxPage)}
          >
            {maxPage}
          </button>
          <button
            disabled={currentPage === maxPage}
            className="button not-square"
            onClick={nextPage}
          >
            <span className="hide-phone">{t("commons.next")}</span>
            <span className="next-icon material-symbols-outlined">
              keyboard_arrow_right
            </span>
          </button>
        </div>
      </div>
    )
  }
}

export default Paginator
