const i18nextJson = `{
  "dessert_cake_zero": "I would like to eat no cake.",
  "dessert_muffin_zero": "I would like to eat no muffin.",
  "dessert_cake_one": "I would like to eat a cake.",
  "dessert_muffin_one": "I would like to eat a muffin.",
  "dessert_cake_two": "I would like to eat two cakes.",
  "dessert_muffin_two": "I would like to eat two muffins.",
  "dessert_cake_few": "I would like to eat a few cakes.",
  "dessert_muffin_few": "I would like to eat a few muffins.",
  "dessert_cake_many": "I would like to eat many cakes.",
  "dessert_muffin_many": "I would like to eat many muffins.",
  "dessert_cake_other": "I would like to eat {{count}} cakes.",
  "dessert_muffin_other": "I would like to eat {{count}} muffins.",
  "dessert": "I would like to eat something."
}
`

export default i18nextJson
