import React, { CSSProperties, useState } from "react"
import { useTranslation } from "react-i18next"

type Props = {
  closeModal: () => void
  onConfirm: () => void
  isShown: boolean
  children: React.ReactNode
  textToType?: string
  containerStyle?: CSSProperties
  wrapperStyle?: CSSProperties
  overflow?: string
}

export const ModalConfirmation = ({
  closeModal,
  onConfirm,
  isShown,
  children,
  textToType,
  containerStyle,
  wrapperStyle,
  overflow,
}: Props) => {
  const { t } = useTranslation()

  const handleConfirmText = (event: any) => {
    setConfirmText(event.target.value)
  }

  const [confirmText, setConfirmText] = useState("")

  if (isShown) {
    return (
      <div
        className="modal-wrapper flex-column flex-center"
        style={{ ...wrapperStyle }}
      >
        <div
          className="modal-overlay flex-column flex-center"
          onClick={closeModal}
        ></div>
        <div
          className="modal-container"
          style={{ ...containerStyle, overflow: overflow ? overflow : "auto" }}
        >
          {children}

          {textToType && (
            <div className="field" style={{ marginTop: "1rem" }}>
              <div className="control">
                <input
                  className="full-width input"
                  type="text"
                  placeholder={t("apiKeys.name_long")}
                  value={confirmText}
                  onChange={handleConfirmText}
                />
              </div>
            </div>
          )}

          <div className="flex-row full-width" style={{ marginBottom: "1rem" }}>
            <button
              className="button is-bordered flex-1"
              onClick={closeModal}
              style={{ marginRight: "0.5rem" }}
            >
              {t("commons.cancel")}
            </button>
            <button
              className="button is-primary-gradient flex-1"
              onClick={onConfirm}
              style={{ marginLeft: "0.5rem" }}
              disabled={
                textToType !== undefined &&
                textToType !== "" &&
                confirmText !== textToType
              }
            >
              {t("commons.confirm")}
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default ModalConfirmation
