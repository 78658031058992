import { useEffect, useState } from "react"
import { TypeAnimation } from "react-type-animation"

type Props = {
  children: string
  waitBeforeShow: number
  isConsoleResponse?: boolean
}

const ConsoleLine = (props: Props) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, props.waitBeforeShow)
    return () => clearTimeout(timer)
  }, [props.waitBeforeShow])

  return isShown ? (
    <div className="ConsoleLine flex-row">
      {!props.isConsoleResponse && (
        <>
          <div className="no-selection" style={{ }}>
            <span style={{ opacity: 0.66, color: "#58e65f" }}>➜</span>
            &nbsp;&nbsp;
            <span className=" hide-phone" style={{ opacity: 0.66, color: "cyan" }}>your_super_app</span>{" "}
            <span className=" hide-phone" style={{ opacity: 0.66, color: "#0b57d0" }}>git:(</span>
            <span className=" hide-phone" style={{ opacity: 0.66, color: "red" }}>main</span>
            <span className=" hide-phone" style={{ opacity: 0.66, color: "#0b57d0", marginRight: "1ch" }}>)</span>
          </div>
          <TypeAnimation
            sequence={[400, props.children]}
            wrapper="span"
            speed={{ type: "keyStrokeDelayInMs", value: 30 }}
            cursor={false}
          />
        </>
      )}
      {props.isConsoleResponse && (
        <TypeAnimation
        className="no-selection"
          style={{ opacity: 0.65 }}
          sequence={[400, props.children]}
          wrapper="span"
          speed={{ type: "keyStrokeDelayInMs", value: 0 }}
          cursor={false}
        />
      )}
    </div>
  ) : null
}

export default ConsoleLine
