import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

import AnimatedLoader from "../../components/AnimatedLoader"

import Modal from "../../components/Modal"

import { Link } from "react-router-dom"
import { PaymentContext } from "../../../context/Payment"
import {
  detachPaymentMethod,
  setPaymentMethodAsDefault,
} from "../../../services/paymentMethods"
import ModalSetupIntent from "../../components/ModalSetupIntent"
import Dropdown from "../../components/Dropdown"
import PaymentMethodImage from "../../components/PaymentMethodImage"
import posthog from "posthog-js"
import { UIContext } from "../../../context/UI"
import ReactGA from "react-ga4"

export const Invoices = () => {
  const { t } = useTranslation()
  const { setDropdownOpen } = useContext(UIContext)

  const {
    preventDoubleSubmit,
    invoices,
    paymentMethods,
    userAddress,
    modalSetupIsShown,
    setPreventDoubleSubmit,
    setIsStripePaymentLoaded,
    setModalSetupIsShown,
    refreshPaymentMethods,
  } = useContext(PaymentContext)

  useEffect(() => {
    document.title = t("credits.invoices")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/billing/invoices",
        title: "Invoices",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDetachPaymentMethod = async (paymentMethodId: number) => {
    await detachPaymentMethod({ paymentMethodId })
    await refreshPaymentMethods()
    setPreventDoubleSubmit(false)
  }

  const handleSetAsDefault = async (paymentMethodId: number) => {
    await setPaymentMethodAsDefault({ paymentMethodId })
    await refreshPaymentMethods()
    setPreventDoubleSubmit(false)
  }

  return (
    <>
      {!invoices && (
        <div className="champo-page-container">
          <AnimatedLoader />
        </div>
      )}

      {invoices !== null && invoices?.length > 0 && (
        <>
          <div className="champo-page-container">
            <div className="billing-infos">
              <div className="billing-methods flex-column">
                <p className="infos-credits" style={{ marginBottom: 0 }}>
                  <b className="infos-creits-label">
                    {t("credits.payment_methods")}
                  </b>
                </p>

                <div className="full-width flex-1">
                  {paymentMethods?.map((item: any) => (
                    <div
                      className="flex-row"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        margin: "0.5rem 0 0",
                        alignItems: "center",
                      }}
                    >
                      <PaymentMethodImage
                        name={item.brand ? item.brand : item.type}
                      />
                      <div className="flex-row flex-column-phone flex-1">
                        <div
                          style={{
                            minWidth: 150,
                            flexDirection: "row",
                            fontSize: 14,
                            display: "flex",
                            alignItems: "center",
                            lineHeight: "20px",
                          }}
                        >
                          {!item.brand && item.type}
                          {item.last4 && " •••• "}
                          {item.last4}
                          {item?.is_default === true && (
                            <div
                              className="success-badge"
                              style={{
                                marginLeft: "0.5rem",
                                fontSize: 12,
                              }}
                            >
                              {t("credits.as_default")}
                            </div>
                          )}
                        </div>
                        <div className="expires-at-infos">
                          {item.exp_month && t("credits.expires_at") + " "}
                          {item?.exp_month && item.exp_month < 10 && "0"}
                          {item.exp_month}
                          {item.exp_month && "/"}
                          {item.exp_year}
                        </div>
                      </div>

                      <div className="flex-center">
                        <Dropdown
                          iconButton={"more_horiz"}
                          rightMenu={true}
                          style={{
                            padding: 0,
                            width: "32px",
                            height: "32px",
                            margin: "0.125rem",
                          }}
                          buttonStyle={{
                            width: "32px",
                            height: "32px",
                          }}
                        >
                          <button
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              setPreventDoubleSubmit(true)
                              handleSetAsDefault(item.id_payment_method)
                              setDropdownOpen(null)
                            }}
                            className="dropdown-item dropdown-option"
                            disabled={preventDoubleSubmit || item.is_default}
                          >
                            <span className="msymbol material-symbols-outlined half-opacity">
                              verified
                            </span>
                            {t("credits.set_as_default")}
                          </button>
                          <button
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              setPreventDoubleSubmit(true)
                              handleDetachPaymentMethod(item.id_payment_method)
                              setDropdownOpen(null)
                            }}
                            disabled={preventDoubleSubmit || item.is_default}
                            className="dropdown-item dropdown-option"
                          >
                            <span className="msymbol material-symbols-outlined danger">
                              delete
                            </span>
                            {t("credits.remove_payment_method")}
                          </button>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <button
                    style={{
                      marginTop: "1rem",
                      marginBottom: "0.5rem",
                    }}
                    className="button is-primary-gradient"
                    onClick={() => {
                      setIsStripePaymentLoaded(false)
                      setModalSetupIsShown(true)
                    }}
                  >
                    {t("credits.add_payment_method")}
                  </button>
                </div>
              </div>
              <div className="billing-address flex-column">
                <p className="infos-credits" style={{ marginBottom: "0.5rem" }}>
                  <b className="infos-creits-label">
                    {t("credits.billing_address")}
                  </b>
                </p>

                <div
                  className="flex-1"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <p>{userAddress?.name}</p>
                  <p>{userAddress?.line1}</p>
                  {userAddress?.line2 && <p>{userAddress?.line2}</p>}
                  <p>
                    {userAddress?.postal_code} {userAddress?.city}
                  </p>
                  <p>
                    {userAddress?.country} {userAddress?.state}
                  </p>
                </div>
                <div>
                  {/* <button
                              style={{
                                marginTop: "1rem",
                                marginBottom: "0.5rem",
                              }}
                              className="button is-bordered"
                              onClick={() => {
                                setIsStripePaymentLoaded(false)
                                setModalSetupIsShown(true)
                              }}
                            >
                              {t("credits.change_billing_address")}
                            </button> */}
                </div>
              </div>
            </div>

            <hr className="full-width" style={{ margin: "1.5rem 0" }} />

            <p className="infos-credits" style={{ marginBottom: "0.5rem" }}>
              <b className="infos-creits-label">{t("credits.invoices")}</b>
            </p>
            <div className="flex-column table-list">
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  minWidth: "100%",
                }}
              >
                <div className="flex-row table-item header-item">
                  <div
                    style={{
                      width: "8rem",
                      minWidth: "8rem",
                    }}
                  >
                    {t("credits.invoice_status")}
                  </div>
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                    }}
                  >
                    {t("credits.billing_reason")}
                  </div>
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                    }}
                  >
                    {t("credits.payed_on")}
                  </div>

                  <div className=" flex-1"></div>

                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                      textAlign: "right",
                    }}
                  >
                    {t("credits.invoice")}
                  </div>
                </div>

                {invoices.map((item: any, index: number) => (
                  <div
                    key={item.id_invoice + "_list_100"}
                    className={
                      "table-item flex-row " +
                      (index % 2 === 0 ? "even" : "odd")
                    }
                  >
                    <div
                      style={{
                        width: "8rem",
                        minWidth: "8rem",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "6px",
                          fontSize: "14px",
                          minWidth: "6.75rem",
                          maxWidth: "6.75rem",
                          fontWeight: "bold",
                        }}
                        className={
                          "flex-row flex-center " +
                          (item.status === "paid"
                            ? "success-badge"
                            : "warn-badge")
                        }
                      >
                        {
                          <b>
                            {t("credits.invoice_status_list." + item.status)}
                          </b>
                        }
                      </div>
                    </div>
                    <div
                      style={{
                        width: "12rem",
                        minWidth: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b>
                          {t("credits.billing_reasons." + item.billing_reason)}
                        </b>
                      </p>
                    </div>
                    <div
                      style={{
                        width: "12rem",
                        minWidth: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b>{new Date(item.createdAt).toLocaleString()}</b>
                      </p>
                    </div>

                    <div className="flex-column flex-1"></div>
                    <div className="flex-row">
                      <div
                        style={{
                          width: "10rem",
                          minWidth: "10rem",
                          textAlign: "right",
                          fontWeight: "bold",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          display: "flex",
                          marginRight: "1rem",
                        }}
                      >
                        <b>
                          {Number(item.amount).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                          €
                        </b>
                      </div>

                      {item.billing_reason === "buy_credits" ? (
                        <Link
                          to={item.stripe_link}
                          target="_blank"
                          style={{
                            height: 32,
                            marginRight: "-0.25rem",
                            minWidth: "7rem",
                            textAlign: "right",
                            justifyContent: "flex-end",
                          }}
                          className="button is-bordered"
                        >
                          {t("credits.receipt")}
                        </Link>
                      ) : (
                        <Link
                          to={item.stripe_link}
                          target="_blank"
                          style={{
                            height: 32,
                            marginRight: "-0.25rem",
                            minWidth: "7rem",
                            textAlign: "right",
                            justifyContent: "flex-end",
                          }}
                          className="button is-bordered"
                        >
                          {t("credits.invoice")}
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        isShown={modalSetupIsShown}
        closeModal={() => {
          setIsStripePaymentLoaded(false)
          setModalSetupIsShown(false)
        }}
      >
        <ModalSetupIntent
          isLoaded={() => {}}
          refreshResult={() => {
            refreshPaymentMethods()
          }}
        />
      </Modal>
    </>
  )
}

export default Invoices
