import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Trans, useTranslation } from "react-i18next"
import ConsoleLine from "../components/ConsoleLine"

import { javascript } from "@codemirror/lang-javascript"
import ReactCodeMirror, { EditorView } from "@uiw/react-codemirror"
import { Link } from "react-router-dom"

import afZALanding from "../../translations/af-ZA/landingPage.json"
import arAELanding from "../../translations/ar-AE/landingPage.json"
import bgBGLanding from "../../translations/bg-BG/landingPage.json"
import csCZLanding from "../../translations/cs-CZ/landingPage.json"
import daDKLanding from "../../translations/da-DK/landingPage.json"
import deDELanding from "../../translations/de-DE/landingPage.json"
import elGRLanding from "../../translations/el-GR/landingPage.json"
import enUSLanding from "../../translations/en-US/landingPage.json"
import esESLanding from "../../translations/es-ES/landingPage.json"
import etEELanding from "../../translations/et-EE/landingPage.json"
import fiFILanding from "../../translations/fi-FI/landingPage.json"
import frFRLanding from "../../translations/fr-FR/landingPage.json"
import heILLanding from "../../translations/he-IL/landingPage.json"
import hrHRLanding from "../../translations/hr-HR/landingPage.json"
import huHULanding from "../../translations/hu-HU/landingPage.json"
import hyAMLanding from "../../translations/hy-AM/landingPage.json"
import idIDLanding from "../../translations/id-ID/landingPage.json"
import isISLanding from "../../translations/is-IS/landingPage.json"
import itITLanding from "../../translations/it-IT/landingPage.json"
import jaJPLanding from "../../translations/ja-JP/landingPage.json"
import koKRLanding from "../../translations/ko-KR/landingPage.json"
import lbLULanding from "../../translations/lb-LU/landingPage.json"
import nbNOLanding from "../../translations/nb-NO/landingPage.json"
import nlNLLanding from "../../translations/nl-NL/landingPage.json"
import ptBRLanding from "../../translations/pt-BR/landingPage.json"
import ptPTLanding from "../../translations/pt-PT/landingPage.json"
import roROLanding from "../../translations/ro-RO/landingPage.json"
import ruRULanding from "../../translations/ru-RU/landingPage.json"
import skSKLanding from "../../translations/sk-SK/landingPage.json"
import slSILanding from "../../translations/sl-SI/landingPage.json"
import sqALLanding from "../../translations/sq-AL/landingPage.json"
import svSELanding from "../../translations/sv-SE/landingPage.json"
import thTHLanding from "../../translations/th-TH/landingPage.json"
import trTRLanding from "../../translations/tr-TR/landingPage.json"
import ukUALanding from "../../translations/uk-UA/landingPage.json"
import viVNLanding from "../../translations/vi-VN/landingPage.json"
import zhCNLanding from "../../translations/zh-CN/landingPage.json"

import Dropdown from "../components/Dropdown"
import PremiumItem from "../components/PremiumItem"
import { TopBar } from "../navbar/TopBar"

import { getEncoding } from "js-tiktoken"
import posthog from "posthog-js"
import ReactGA from "react-ga4"
import { NavLink } from "react-router-dom"
import { AuthContext } from "../../context/Auth"
import { UIContext } from "../../context/UI"
import fluentJs from "../../i18n-file-type/fluent.js"
import gettext from "../../i18n-file-type/gettext"
import i18nJs from "../../i18n-file-type/i18n-js.json"
import i18nextJson from "../../i18n-file-type/i18next.json"
import i18nextTs from "../../i18n-file-type/i18next.ts"
import icuMessageFormat from "../../i18n-file-type/icu-message-format.ts"
import jedJs from "../../i18n-file-type/jed.js"
import polyglotJs from "../../i18n-file-type/polyglot-js.json"
import vueI18n from "../../i18n-file-type/vue-i18n.ts"
import { getLandingCounters } from "../../services/landing"

const encoder = getEncoding("o200k_base")

function getRandomNumber(min: number, max: number) {
  return Number((Math.random() * (max - min) + min).toFixed()) as number
}

function LandingPage() {
  const { t } = useTranslation()

  const featuresRef = useRef<null | HTMLDivElement>(null)
  const pricingRef = useRef<null | HTMLDivElement>(null)
  const topRef = useRef<null | HTMLDivElement>(null)
  const faqRef = useRef<null | HTMLDivElement>(null)

  const { isDark } = useContext(UIContext)
  const { isLoggedIn } = useContext(AuthContext)

  const [heroTitle, setHeroTitle] = useState<string | null>()

  const [selectedWorkflowStep, setSelectedWorkflowStep] = useState<
    number | null
  >(1)
  const [selectedEditorStep, setSelectedEditorStep] = useState<number | null>(2)
  const [userCount, setUserCount] = useState<number | null>(7)

  useEffect(() => {
    document.title = "ChampoAI"

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")

      ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Landing Page",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCounters = async () => {
    const counter_result = await getLandingCounters()
    if (counter_result?.data?.user_count) {
      setUserCount(counter_result.data.user_count)
    }
  }

  useEffect(() => {
    getCounters()
  }, [])

  const scrollToTop = () =>
    topRef.current!.scrollIntoView({ behavior: "smooth" })
  const scrollToFeatures = () =>
    featuresRef.current!.scrollIntoView({ behavior: "smooth" })
  const scrollToPricing = () =>
    pricingRef.current!.scrollIntoView({ behavior: "smooth" })

  const heroTitles = useMemo(() => {
    return [
      frFRLanding.hero_title,
      arAELanding.hero_title,
      afZALanding.hero_title,
      bgBGLanding.hero_title,
      csCZLanding.hero_title,
      daDKLanding.hero_title,
      deDELanding.hero_title,
      elGRLanding.hero_title,
      enUSLanding.hero_title,
      esESLanding.hero_title,
      etEELanding.hero_title,
      fiFILanding.hero_title,
      heILLanding.hero_title,
      hrHRLanding.hero_title,
      huHULanding.hero_title,
      hyAMLanding.hero_title,
      idIDLanding.hero_title,
      isISLanding.hero_title,
      itITLanding.hero_title,
      jaJPLanding.hero_title,
      koKRLanding.hero_title,
      lbLULanding.hero_title,
      nbNOLanding.hero_title,
      nlNLLanding.hero_title,
      ptBRLanding.hero_title,
      ptPTLanding.hero_title,
      roROLanding.hero_title,
      ruRULanding.hero_title,
      skSKLanding.hero_title,
      slSILanding.hero_title,
      sqALLanding.hero_title,
      svSELanding.hero_title,
      thTHLanding.hero_title,
      trTRLanding.hero_title,
      ukUALanding.hero_title,
      viVNLanding.hero_title,
      zhCNLanding.hero_title,
    ]
  }, [])

  const languages: [string, string, string, any][] = useMemo(() => {
    return [
      ["fr", "🇫🇷", "fr-FR", frFRLanding],
      ["ar", "🇦🇪", "ar-AE", arAELanding],
      ["af", "🇿🇦", "af-ZA", afZALanding],
      ["bg", "🇧🇬", "bg-BG", bgBGLanding],
      ["cs", "🇨🇿", "cs-CZ", csCZLanding],
      ["da", "🇩🇰", "da-DK", daDKLanding],
      ["de", "🇩🇪", "de-DE", deDELanding],
      ["el", "🇬🇷", "el-GR", elGRLanding],
      ["en", "🇺🇸", "en-US", enUSLanding],
      ["es", "🇪🇸", "es-ES", esESLanding],
      ["et", "🇪🇪", "et-EE", etEELanding],
      ["fi", "🇫🇮", "fi-FI", fiFILanding],
      ["he", "🇮🇱", "he-IL", heILLanding],
      ["hr", "🇭🇷", "hr-HR", hrHRLanding],
      ["hr", "🇭🇺", "hu-HU", huHULanding],
      ["hy", "🇦🇲", "hy-AM", hyAMLanding],
      ["id", "🇮🇩", "id-ID", idIDLanding],
      ["is", "🇮🇸", "is-IS", isISLanding],
      ["it", "🇮🇹", "it-IT", itITLanding],
      ["ja", "🇯🇵", "ja-JP", jaJPLanding],
      ["ko", "🇰🇷", "ko-KR", koKRLanding],
      ["lb", "🇱🇺", "lb-LU", lbLULanding],
      ["nb", "🇳🇴", "nb-NO", nbNOLanding],
      ["nl", "🇳🇱", "nl-NL", nlNLLanding],
      ["br", "🇧🇷", "pt-BR", ptBRLanding],
      ["pt", "🇵🇹", "pt-PT", ptPTLanding],
      ["ro", "🇷🇴", "ro-RO", roROLanding],
      ["ru", "🇷🇺", "ru-RU", ruRULanding],
      ["sk", "🇸🇰", "sk-SK", skSKLanding],
      ["sl", "🇸🇮", "sl-SI", slSILanding],
      ["sq", "🇦🇱", "sq-AL", sqALLanding],
      ["sv", "🇸🇪", "sv-SE", svSELanding],
      ["th", "🇹🇭", "th-TH", thTHLanding],
      ["tr", "🇹🇷", "tr-TR", trTRLanding],
      ["uk", "🇺🇦", "uk-UA", ukUALanding],
      ["vi", "🇻🇳", "vi-VN", viVNLanding],
      ["zh", "🇨🇳", "zh-CN", zhCNLanding],
    ]
  }, [])

  const [closeAnimation, setCloseAnimation] = useState(false)
  const [indexLang1, setIndexLang1] = useState<number>(0)
  const [indexLang2, setIndexLang2] = useState<number>(8)

  const beginAnimChangeHero = () => {
    setTimeout(() => {
      let newIndex = getRandomNumber(0, languages.length - 1)
      let newIndex2 = getRandomNumber(0, languages.length - 1)
      setIndexLang1(newIndex)
      setIndexLang2(newIndex2)
    }, 2000)
  }

  const changeHero = (newHeroTitle: string) => {
    setHeroTitle(newHeroTitle)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCloseAnimation(true)
      beginAnimChangeHero()
    }, 4000)

    return () => clearInterval(interval)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // FEATURE DIV
  const [tokenLength, setTokenLength] = useState<number>(0)
  const [tokenPreviewLength, setTokenPreviewLength] = useState<number>(0)

  const [inputCode, setInputCode] = useState(
    JSON.stringify(frFRLanding, null, 2),
  )
  const [outputCode, setOutputCode] = useState(
    JSON.stringify(enUSLanding, null, 2),
  )
  const [inputCodePreview, setInputCodePreview] = useState<string>()

  const [inputLang, setInputLang] = useState<[string, string, string, any]>([
    "fr",
    "🇫🇷",
    "fr-FR",
    frFRLanding,
  ])
  const [outputLang, setOutputLang] = useState<[string, string, string, any]>([
    "en",
    "🇺🇸",
    "en-US",
    enUSLanding,
  ])
  const [modelUsed, setModelUsed] = useState<string>("gpt-4o")

  const filePrice = useMemo(() => {
    const gptInputTokenPrice = modelUsed === "gpt-4o" ? 0.01 : 0.0005
    const gptOutputTokenPrice = modelUsed === "gpt-4o" ? 0.03 : 0.0015
    const marginMultiplier = modelUsed === "gpt-4o" ? 12.5 : 50

    return (
      tokenLength > 0
        ? (tokenLength + 134) * (gptInputTokenPrice / 1000) * marginMultiplier +
          tokenLength * (gptOutputTokenPrice / 1000) * marginMultiplier
        : 0
    ).toFixed(2)
  }, [tokenLength, modelUsed])

  const filePricePreview = useMemo(() => {
    const gptInputTokenPrice = modelUsed === "gpt-4o" ? 0.01 : 0.0005
    const gptOutputTokenPrice = modelUsed === "gpt-4o" ? 0.03 : 0.0015
    const marginMultiplier = modelUsed === "gpt-4o" ? 12.5 : 50

    return (
      tokenPreviewLength > 0
        ? (tokenPreviewLength + 134) *
            (gptInputTokenPrice / 1000) *
            marginMultiplier +
          tokenPreviewLength * (gptOutputTokenPrice / 1000) * marginMultiplier
        : 0
    ).toFixed(2)
  }, [tokenPreviewLength, modelUsed])

  const onChange = useCallback((value: any) => {
    // const token = encode(value)
    const token = encoder.encode(value)
    setTokenLength(token?.length || 0)
    setInputCode(value)
  }, [])

  const onChangePreview = useCallback((value: any) => {
    // const token = encode(value)
    const token = encoder.encode(value)
    setTokenPreviewLength(token?.length || 0)
    setInputCodePreview(value)
  }, [])

  useEffect(() => {
    // const token = encode(inputCode)
    const token = encoder.encode(inputCode)
    setTokenLength(token?.length || 0)
  }, [inputCode])

  useEffect(() => {
    setOutputCode(JSON.stringify(outputLang[3], null, 2))
  }, [outputLang])

  const programmingLanguages = useMemo(
    () => [
      ["i18next JSON", i18nextJson],
      ["i18next Typescript", i18nextTs],
      ["ICU Message Format", icuMessageFormat],
      ["Vue-i18n", vueI18n],
      ["i18n-js", i18nJs],
      ["Polyglot.js", polyglotJs],
      // ["FBT", fbtXml],
      ["Gettext", gettext],
      ["Fluent", fluentJs],
      ["Jed", jedJs],
      // ["Other", other],
    ],
    [],
  )

  const [fileTypeCode, setFileTypeCode] = useState<string>("")
  const [fileTypeIndex, setFileTypeIndex] = useState<number>(1)

  useEffect(() => {
    setFileTypeCode(programmingLanguages[fileTypeIndex][1])
  }, [fileTypeIndex, programmingLanguages])

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null)

  const setQuestion = (index: number) => {
    if (selectedQuestion === index) {
      setSelectedQuestion(null)
    } else {
      setSelectedQuestion(index)
    }
  }

  const fakeWallOfLove = [
    {
      image: "pic-1.png",
      name: "Gab Franck",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_1"),
    },
    {
      image: "pic-2.png",
      name: "Negative Gab",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_2"),
    },
    {
      image: "pic-3.png",
      name: "Gab Withsunglasses",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_3"),
    },
    {
      image: "pic-4.png",
      name: "Gab Withahat",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_4"),
    },
    {
      image: "pic-5.png",
      name: "Reversed Gab",
      tag: "@GabFrk",
      message: "!loot siht evol I",
    },
    {
      image: "pic-6.png",
      name: "Honest Gab",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_6"),
    },
    {
      image: "pic-7.png",
      name: "Cropped Gab",
      tag: "@GabFrk",
      message: "I just discover this tool and I really love to be able to...",
    },
    {
      image: "pic-8.png",
      name: "Gab Thebot",
      tag: "@GabFrk",
      message: "Boop beep boop. Good tool !",
    },
    {
      image: "pic-9.png",
      name: "Withglasses Gab",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_9"),
    },
    {
      image: "pic-10.png",
      name: "Nomoreidea Gab",
      tag: "@GabFrk",
      message:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure hic molestias repellendus quae excepturi? Accusamus facere adipisci architecto reprehenderit nobis eveniet quaerat beatae. Aliquam laborum aut possimus sint minus temporibus!",
    },
  ]

  const getTestimonialItem = (index: number) => {
    return (
      <div className="bordered-div" style={{ marginTop: "1rem" }}>
        <div className="flex-row" style={{ marginBottom: "0.75rem" }}>
          <img
            src={"/assets/testimonial-pics/" + fakeWallOfLove[index].image}
            alt=""
            style={{
              width: "54px",
              height: "54px",
              borderRadius: 32,
              marginRight: "0.5rem",
            }}
          />

          <div className="flex-column" style={{ justifyContent: "center" }}>
            <h3
              className="font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              {fakeWallOfLove[index].name}
            </h3>
            <p
              className="half-opacity"
              style={{
                fontSize: "0.8rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {fakeWallOfLove[index].tag}
            </p>
          </div>
        </div>
        <p>{fakeWallOfLove[index].message}</p>

        <div
          style={{
            fontSize: "0.85rem",
            marginTop: "0.5rem",
            marginBottom: "-0.25rem",
            fontStyle: "italic",
            opacity: 0.5,
            textAlign: "right",
          }}
        >
          {new Date("2024-05-08").toLocaleDateString()}
        </div>
      </div>
    )
  }

  const guideSteps = [
    {
      title: t("landingPage.workflow_step1"),
      text: "",
      command_list: [
        "npm install champo-ai --save-dev",
        "export CHAMPO_API_KEY=[YOUR API KEY]",
      ],
    },
    {
      title: t("landingPage.workflow_step2"),
      text: "",
      command_list: ["npx champo init"],
    },
    {
      title: t("landingPage.workflow_step3"),
      text: "",
      command_list: [
        "npx champo push",
        "npx champo translate",
        "npx champo pulll",
      ],
    },
  ]

  return (
    <>
      <TopBar
        scrollToTop={scrollToTop}
        scrollToFeatures={scrollToFeatures}
        scrollToPricing={scrollToPricing}
      ></TopBar>
      <div className="LandingPage" id="landing-scroll">
        <div className="landing-container" ref={topRef}>
          <div
            className="flex-column flex-center"
            style={{ maxWidth: "1200px", margin: "0", width: "100%" }}
          >
            {/* {heroTitle ? (
              <h1
                className={`hero-title ${closeAnimation ? "close-hero" : ""}`}
              >
                <span>
                  <Trans>{heroTitle}</Trans>
                </span>
              </h1>
            ) : (
              <h1
                className={`hero-title ${closeAnimation ? "close-hero" : ""}`}
              >
                <span>
                  <Trans>{t("landingPage.hero_title")}</Trans>
                </span>
              </h1>
            )} */}

            {/* <div className="first-catchline">
              {t("landingPage.catchphrase")}
            </div> */}
            <h1
              className={`text-center hero-title ${closeAnimation ? "close-hero" : ""}`}
              style={{ maxWidth: "600px" }}
            >
              <span>
                <Trans>{t("landingPage.hero_title")}</Trans>
              </span>
            </h1>

            {/* <h2
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 800,
                fontSize: "1.25rem",
                marginBottom: "2rem",
              }}
              className="hero-sub-title"
            >
              {t("landingPage.hero_sub_title")}
            </h2> */}

            <p
              className="main-paragraph text-center"
              style={{ maxWidth: "600px" }}
            >
              <Trans>{t("landingPage.hero_label")}</Trans>
            </p>

            <div className="main-button flex-column flex-center">
              <div
                className="flex-row flex-column-phone"
                style={{ alignItems: "center" }}
              >
                <Link
                  className="button is-primary-gradient glow-on-hover"
                  to="/register"
                  style={{ maxWidth: "100%", whiteSpace: "normal" }}
                >
                  <Trans>{t("landingPage.last_part_cta")}</Trans>
                </Link>
                {/* <span className="half-opacity hide-phone">
                  {t("getStarted.or")}
                </span>
                <Link className="button is-bordered hide-phone" to="/auth">
                  {t("auth.login_action_you")}
                </Link> */}
              </div>
            </div>

            <div className="user-counter flex-row">
              <img
                className="profile-pic"
                src="/assets/landing-people-profile/img1.jpg"
                alt=""
              />
              <img
                className="profile-pic"
                src="/assets/landing-people-profile/img2.jpg"
                alt=""
              />
              <img
                className="profile-pic"
                src="/assets/landing-people-profile/img3.jpg"
                alt=""
              />
              <img
                className="profile-pic"
                src="/assets/landing-people-profile/img4.jpg"
                alt=""
              />
              <img
                className="profile-pic"
                src="/assets/landing-people-profile/img5.jpg"
                alt=""
              />

              <div
                className="flex-column"
                style={{ padding: "4px 0", marginLeft: "0.5rem" }}
              >
                <div className="flex-row">
                  <img className="star-image" src="/assets/star.svg" alt="" />
                  <img className="star-image" src="/assets/star.svg" alt="" />
                  <img className="star-image" src="/assets/star.svg" alt="" />
                  <img className="star-image" src="/assets/star.svg" alt="" />
                  <img className="star-image" src="/assets/star.svg" alt="" />
                </div>
                <p className="counter-text">
                  <Trans>
                    {t("landingPage.hero_dev_count", {
                      userCount: userCount,
                    })}
                  </Trans>
                </p>
              </div>
            </div>

            <div className="flex-center flex-column">
              <div
                className="flex-row flex-column-phone"
                style={{ marginBottom: "2rem" }}
              >
                <div
                  className="flex-row"
                  style={{ alignItems: "center", marginTop: "1rem" }}
                >
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{
                      opacity: 0.5,
                      fontSize: 28,
                      marginRight: "0.5rem",
                    }}
                  >
                    featured_seasonal_and_gifts
                  </span>
                  <p style={{ fontSize: "0.95rem", marginRight: "2.5rem" }}>
                    {t("landingPage.try_for_free")}
                  </p>
                </div>

                <div
                  className="flex-row"
                  style={{ alignItems: "center", marginTop: "1rem" }}
                >
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{
                      opacity: 0.5,
                      fontSize: 28,
                      marginRight: "0.5rem",
                    }}
                  >
                    credit_card_off
                  </span>
                  <p style={{ fontSize: "0.95rem" }}>
                    {t("landingPage.no_credit_card")}
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                maxWidth: "calc(100% + 2rem)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="full-width bordered-div flex-column white-box flex-center"
                style={{
                  padding: 0,
                  borderRadius: "16px",
                  position: "relative",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                    // filter: "blur(1px)",
                    opacity: 0.5,
                  }}
                  src={`/assets/${isDark ? "landing-dark" : "landing-light"}/screen-editor.png`}
                  alt=""
                />

                <ScrollAnimation
                  className="bordered-div shadow-item"
                  animateIn="fade-in-top"
                  scrollableParentSelector="#landing-scroll"
                  style={{
                    position: "absolute",
                    bottom: "40%",
                    left: "-1rem",
                    height: "13%",
                    minHeight: "50px",
                    padding: 0,
                    zIndex: 999,
                  }}
                >
                  <img
                    style={{ height: "100%", borderRadius: "9px" }}
                    src={`/assets/hero-item/${isDark ? "dark" : "light"}_add.png`}
                    alt=""
                  />
                </ScrollAnimation>

                <ScrollAnimation
                  className="bordered-div shadow-item"
                  animateIn="fade-in-top"
                  scrollableParentSelector="#landing-scroll"
                  style={{
                    position: "absolute",
                    bottom: "-1rem",
                    height: "13%",
                    minHeight: "50px",
                    padding: 0,
                    zIndex: 998,
                  }}
                >
                  <img
                    style={{ height: "100%", borderRadius: "9px" }}
                    src={`/assets/hero-item/${isDark ? "dark" : "light"}_translate.png`}
                    alt=""
                  />
                </ScrollAnimation>

                <ScrollAnimation
                  className="bordered-div shadow-item"
                  animateIn="fade-in-top"
                  scrollableParentSelector="#landing-scroll"
                  style={{
                    position: "absolute",
                    right: "-1rem",
                    height: "13%",
                    minHeight: "50px",
                    bottom: "20%",
                    padding: 0,
                    zIndex: 997,
                  }}
                >
                  <img
                    style={{ height: "100%", borderRadius: "9px" }}
                    src={`/assets/hero-item/${isDark ? "dark" : "light"}_valid.png`}
                    alt=""
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>

        <ScrollAnimation
          animateIn="fade-in-top"
          scrollableParentSelector="#landing-scroll"
        >
          <div
            className="flex-column flex-center"
            style={{ marginBottom: "2.5rem" }}
          >
            <div
              className="flex-row"
              style={{
                minWidth: "150px",
                maxWidth: "200px",
                width: "calc(100% - 8rem)",
                position: "relative",
                zIndex: 9,
              }}
            >
              <div style={{ position: "relative", height: "150px" }}>
                <div className="step-circle-anim-revert">
                  <div
                    className={getFlagClass(languages[indexLang1][2])}
                    style={{ fontSize: "20px", position: "absolute" }}
                  ></div>
                </div>

                <div className="step-circle-anim-revert delay-1">
                  <div
                    className={getFlagClass(languages[indexLang2][2])}
                    style={{ fontSize: "20px", position: "absolute" }}
                  ></div>
                </div>
                <svg width="10" height="150">
                  <path
                    d="M 0 0 L 0 150"
                    strokeMiterlimit="10"
                    fill="none"
                    stroke="#3835B9"
                    opacity="0.25"
                    strokeWidth="10"
                    strokeDasharray="10"
                    strokeDashoffset="1"
                  >
                    <animate
                      attributeName="stroke-dashoffset"
                      values="100;0"
                      dur="3s"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>

              <div className="flex-1"></div>

              <div style={{ position: "relative", height: "150px" }}>
                <div className="step-circle-anim">
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{ fontSize: "30px" }}
                  >
                    file_copy
                  </span>
                </div>

                <div className="step-circle-anim delay-1">
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{ fontSize: "30px" }}
                  >
                    file_copy
                  </span>
                </div>

                <svg width="10" height="150">
                  <path
                    d="M 0 0 L 0 150"
                    strokeMiterlimit="10"
                    fill="none"
                    stroke="#3835B9"
                    opacity="0.25"
                    strokeWidth="10"
                    strokeDasharray="10"
                    strokeDashoffset="1"
                  >
                    <animate
                      attributeName="stroke-dashoffset"
                      values="0;100"
                      dur="3s"
                      calcMode="linear"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>
            </div>

            <div
              className="console-window-container flex-column"
              style={{
                position: "relative",
                zIndex: 10,
              }}
            >
              <div className="console-window">
                <div className="console-header">
                  <div className="console-buttons">
                    <div className="console-button console-button-red"></div>
                    <div className="console-button console-button-yellow"></div>
                    <div className="console-button console-button-green"></div>
                  </div>

                  <div className="console-title-div">
                    <span className="console-title no-selection">
                      your_super_app
                    </span>
                  </div>
                </div>

                <div className="console-body code">
                  <ConsoleLine waitBeforeShow={500 + 0}>
                    npm install champo-ai
                  </ConsoleLine>

                  <ConsoleLine waitBeforeShow={500 + 21 * 30 + 600}>
                    export CHAMPO_API_KEY=********
                  </ConsoleLine>
                  <ConsoleLine waitBeforeShow={500 + 21 * 30 + 30 * 30 + 1200}>
                    npx champo init
                  </ConsoleLine>
                  <ConsoleLine
                    waitBeforeShow={500 + 21 * 30 + 30 * 30 + 15 * 30 + 1400}
                    isConsoleResponse={true}
                  >
                    Project: Your Super App
                  </ConsoleLine>
                  <ConsoleLine
                    waitBeforeShow={500 + 21 * 30 + 30 * 30 + 15 * 30 + 1400}
                    isConsoleResponse={true}
                  >
                    Source: fr-FR
                  </ConsoleLine>
                  <ConsoleLine
                    waitBeforeShow={500 + 21 * 30 + 30 * 30 + 15 * 30 + 1400}
                    isConsoleResponse={true}
                  >
                    Target: 36 lang selected
                  </ConsoleLine>

                  <ConsoleLine
                    waitBeforeShow={500 + 21 * 30 + 30 * 30 + 15 * 30 + 2800}
                  >
                    npx champo sync
                  </ConsoleLine>

                  <ConsoleLine
                    waitBeforeShow={
                      500 + 21 * 30 + 30 * 30 + 15 * 30 + 20 * 30 + 3000
                    }
                    isConsoleResponse={true}
                  >
                    ✅ Missing translations generated
                  </ConsoleLine>
                  <ConsoleLine
                    waitBeforeShow={
                      500 +
                      21 * 30 +
                      30 * 30 +
                      15 * 30 +
                      20 * 30 +
                      15 * 30 +
                      3500
                    }
                    isConsoleResponse={true}
                  >
                    ✅ All your I18n files are up to date
                  </ConsoleLine>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <div
          className="pricing-container flex-column flex-center"
          style={{
            marginTop: "2.5rem",
          }}
        >
          <div
            className="pricing-content flex-column"
            style={{ maxWidth: 1200, fontSize: 16, width: "calc(100% - 2rem)" }}
          >
            <div className="flex-column flex-1">
              <h2
                className="landing-sub-title"
                style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
              >
                <Trans>{t("landingPage.start_locating_command")}</Trans>
              </h2>
              {guideSteps.map((item, index) => (
                <div className="flex-row flex-column-tablet">
                  <div className="guide-text flex-row flex-1">
                    <div
                      className="flex-column"
                      style={{ marginTop: "1rem", alignItems: "flex-start" }}
                    >
                      <div className="guide-number flex-row flex-center">
                        {index + 1}
                      </div>
                      <div
                        className={
                          "guide-stepper flex-1 " +
                          (index + 1 >= guideSteps.length ? "no-border" : "")
                        }
                      ></div>
                    </div>

                    <div className="guide-text-container flex-column flex-1 ">
                      <h3 className="guide-title">
                        <Trans>{item.title}</Trans>
                      </h3>
                      <p>
                        {" "}
                        <Trans>{item.text}</Trans>
                      </p>

                      <div className="guide-image flex-1 hide-gt-tablet">
                        <div className="bordered-div flex-column">
                          {item.command_list.map((command) => (
                            <p>
                              <code style={{ userSelect: "none" }}>$</code>
                              <code style={{ color: "#fff" }}>{command}</code>
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="guide-image flex-1 hide-tablet">
                    <div className="bordered-div flex-column">
                      {item.command_list.map((command) => (
                        <p>
                          <code style={{ userSelect: "none" }}>$</code>
                          <code style={{ color: "#fff" }}>{command}</code>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="pricing-container flex-column flex-center"
          style={{ justifyContent: "center" }}
        >
          <div
            className="pricing-content flex-row flex-center flex-reverse-column-tablet"
            style={{ maxWidth: 1200, fontSize: 16, width: "calc(100% - 2rem)" }}
          >
            <div
              className="feature-image-container flex-column flex-1"
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                aspectRatio: "1.4/1",
              }}
            >
              {/* load all image */}
              {[1, 2].map((i) => (
                <>
                  <img
                    src={`/assets/${isDark ? "landing-dark" : "landing-light"}/editor-step${i}-back.png`}
                    alt=""
                    style={{
                      zIndex: 9,
                      top: 0,
                      right: 0,
                      position: "absolute",
                      width: "70%",
                      opacity: 0.5,
                      display: selectedEditorStep === i ? "block" : "none",
                    }}
                  />
                  <img
                    src={`/assets/${isDark ? "landing-dark" : "landing-light"}/editor-step${i}.png`}
                    alt=""
                    style={{
                      zIndex: 10,
                      bottom: 0,
                      left: 0,
                      position: "absolute",
                      width: "90%",
                      display: selectedEditorStep === i ? "block" : "none",
                    }}
                  />
                </>
              ))}
            </div>

            <div
              className="flex-column flex-1 landing-paragraph-container-right"
              style={{}}
            >
              <h2
                className="landing-sub-title"
                style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
              >
                <Trans>{t("landingPage.editor_title")}</Trans>
              </h2>

              <p
                style={{
                  marginBottom: "2.5rem",
                  fontFamily: "Poppins, sans-serif",
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                <Trans>{t("landingPage.editor_infos")}</Trans>
              </p>

              <div
                onClick={() => {
                  setSelectedEditorStep(1)
                }}
                className={
                  "step-row anim1 flex-row full-width " +
                  (selectedEditorStep === 1 ? "selected" : "")
                }
              >
                <span className="title-icon msymbol material-symbols-outlined">
                  file_copy
                </span>
                <div className="step-content flex-1">
                  <h1>{t("landingPage.editor_step1")}</h1>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedEditorStep(2)
                }}
                className={
                  "step-row anim2 flex-row full-width " +
                  (selectedEditorStep === 2 ? "selected" : "")
                }
              >
                <span className="title-icon msymbol material-symbols-outlined">
                  translate
                </span>
                <div className="step-content flex-1">
                  <h1>{t("landingPage.editor_step2")}</h1>
                </div>
              </div>
              <div
                onClick={() => {
                  // setSelectedEditorStep(3)
                }}
                className={
                  "step-row anim3 flex-row full-width " +
                  (selectedEditorStep === 3 ? "selected" : "")
                }
                style={{ marginBottom: "2.5rem" }}
              >
                <span className="title-icon msymbol material-symbols-outlined">
                  handyman
                </span>
                <div className="step-content flex-1">
                  <h1 style={{ display: "block" }}>
                    {t("landingPage.editor_step3")}
                    <span
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "normal",
                        marginLeft: "0.5rem",
                      }}
                      className="warn-badge"
                    >
                      {t("credits.soon")}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="features-container">
          <div
            className="features-content flex-column flex-center"
            style={{ maxWidth: 1200, fontSize: 16, width: "calc(100% - 2rem)" }}
          >
            <h2
              className="landing-sub-title-center"
              style={{ marginTop: "2.5rem", marginBottom: "1.5rem" }}
            >
              <Trans>{t("landingPage.everything_you_need")}</Trans>
            </h2>

            {/* <div className="feature-buttons flex-row flex-column-phone full-width">
            <button className="button flex-1 is-primary-gradient">
              {t('landingPage.translation')}
            </button>
            <button disabled className="button flex-1 is-bordered">
              {t('landingPage.improvement')}
            </button>
            <button disabled className="button flex-1 is-bordered">
              {t('landingPage.extraction')}
            </button>
          </div> */}

            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                width: "100%",
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              <Trans>{t("landingPage.translation_paragraph")}</Trans>
            </p>

            <hr
              className="full-width"
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            />

            <div className="language-buttons flex-row">
              <div
                className="button-div flex-column"
                style={{ marginRight: "0.5rem" }}
              >
                <p className="infos-credits" style={{ margin: 0 }}>
                  <b className="infos-creits-label">
                    {t("translate.inputLang")}
                  </b>
                </p>
                <Dropdown
                  disabled
                  className="full-width language-selector"
                  title={
                    inputLang ? (
                      <div className="flex-row">
                        <div>
                          {" "}
                          <span className={getFlagClass(inputLang[2])}></span>
                        </div>
                        <div style={{ marginLeft: "1rem" }}>{inputLang[2]}</div>
                      </div>
                    ) : (
                      t("translate.select_language")
                    )
                  }
                  rightMenu={true}
                ></Dropdown>
              </div>

              <div
                className="button-div flex-column"
                style={{ marginLeft: "0.5rem" }}
              >
                <p className="infos-credits" style={{ margin: 0 }}>
                  <b className="infos-creits-label">
                    {t("translate.outputLang")}
                  </b>
                </p>
                <Dropdown
                  className="full-width language-selector"
                  customMenuStyle={{ maxHeight: 300, right: 0, left: "unset" }}
                  rightMenu={true}
                  title={
                    outputLang ? (
                      <div className="flex-row">
                        <div>
                          <span className={getFlagClass(outputLang[2])}></span>
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                          {outputLang[2]}
                        </div>
                      </div>
                    ) : (
                      t("translate.select_language")
                    )
                  }
                >
                  {languages.map((item) => (
                    <div
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        setOutputLang(item)
                      }}
                      className="dropdown-item"
                    >
                      <div
                        className={`dropdown-link flex-row ${
                          outputLang && item[2] === outputLang[2]
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div
                          className="dropdown-circle"
                          style={{ marginLeft: "0.3rem" }}
                        >
                          <span className={getFlagClass(item[2])}></span>
                        </div>
                        <span
                          className="text-ellipsis flex-1 "
                          style={{ marginLeft: "1.3rem" }}
                        >
                          {item[2]}
                        </span>
                      </div>
                    </div>
                  ))}
                </Dropdown>
              </div>
            </div>

            <div className="translation-container flex-row">
              <ReactCodeMirror
                className="codemirror-container"
                placeholder={t("translate.input_code_placeholder")}
                height="100%"
                style={{
                  marginRight: "0.5rem",
                  opacity: 0.6,
                }}
                value={inputCode}
                theme="dark"
                extensions={[javascript(), EditorView.lineWrapping]}
                onChange={onChange}
                editable={false}
              />

              <ReactCodeMirror
                className="codemirror-container"
                height="100%"
                placeholder={t("translate.output_code_placeholder")}
                style={{
                  marginLeft: "0.5rem",
                  opacity: 0.6,
                }}
                value={outputCode}
                theme="dark"
                extensions={[javascript(), EditorView.lineWrapping]}
                onChange={onChange}
                editable={false}
              />
            </div>

            <div
              className="translate-buttons flex-row"
              style={{ marginTop: "0.5rem", marginBottom: "2.5rem" }}
            >
              <Link
                to="/register"
                className="translate-button button is-primary-gradient"
              >
                {t("landingPage.try_for_free")}
              </Link>
            </div>
          </div>
        </div>
        <div
          className="features-container flex-column flex-center"
          style={{ justifyContent: "center" }}
        >
          <div
            className="features-content flex-row flex-center flex-column-tablet"
            style={{ maxWidth: 1200, fontSize: 16, width: "calc(100% - 2rem)" }}
          >
            <h2
              className="landing-sub-title-center hide-gt-tablet"
              style={{
                marginTop: "2.5rem",
                marginBottom: "2.5rem",
                textAlign: "left",
              }}
            >
              <Trans>{t("landingPage.all_file_type_title")}</Trans>
            </h2>
            <div className="flex-column flex-1 file-type-feature">
              <div
                className="translation-container flex-row"
                style={{
                  height: "unset",
                  maxHeight: "unset",
                  minHeight: "unset",
                }}
              >
                <div
                  className="flex-column"
                  style={{
                    marginRight: "1rem",
                    width: "28%",
                    maxWidth: "175px",
                  }}
                >
                  {programmingLanguages.map((progLanguage, index) => (
                    <button
                      className={
                        "button text-ellipsis " +
                        (index === fileTypeIndex
                          ? "is-primary-gradient"
                          : "is-bordered")
                      }
                      style={{
                        display: "block",
                        width: "100%",
                        textAlign: "left",
                        justifyContent: "flex-start",
                        marginBottom:
                          index === programmingLanguages.length - 1
                            ? 0
                            : "1rem",
                      }}
                      onClick={() => setFileTypeIndex(index)}
                    >
                      {progLanguage[0]}
                    </button>
                  ))}
                </div>
                <ReactCodeMirror
                  className="codemirror-container"
                  placeholder={t("translate.input_code_placeholder")}
                  height="100%"
                  style={{
                    width: "100%",
                    opacity: 0.6,
                  }}
                  value={fileTypeCode}
                  theme="dark"
                  extensions={[javascript(), EditorView.lineWrapping]}
                  onChange={onChange}
                  editable={false}
                />
              </div>
            </div>
            <div className="flex-column flex-1 landing-paragraph-container-right">
              <h2
                className="landing-sub-title-center hide-tablet"
                style={{
                  marginTop: 0,
                  marginBottom: "2.5rem",
                  textAlign: "left",
                }}
              >
                <Trans>{t("landingPage.all_file_type_title")}</Trans>
              </h2>

              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                {t("landingPage.all_file_type_infos")}
              </p>

              {/* <div className="flex-row">
                <Link
                  className="button is-primary-gradient"
                  to="/register"
                  style={{
                    margin: "2rem 0rem",
                    marginTop: "2rem",
                    marginBottom: "2.5rem",
                    fontSize: "1.25rem",
                    padding: "0.5rem 2rem",
                  }}
                >
                  {t("landingPage.beta_access")}
                </Link>
              </div> */}
            </div>
          </div>
        </div>

        {/* <div className="pricing-container flex-column flex-center">
          <div
            className="pricing-content flex-row flex-center flex-reverse-column-tablet"
            style={{
              maxWidth: 1200,
              fontSize: 16,
              width: "calc(100% - 2rem)",
              alignItems: "stretch",
            }}
          >
            <div className="flex-column flex-1 landing-paragraph-container-half">
              <h2
                className="landing-sub-title-center"
                style={{
                  marginTop: "2.5rem",
                  marginBottom: "2.5rem",
                  textAlign: "left",
                }}
              >
                <Trans>{t("landingPage.data_respect_title")}</Trans>
              </h2>

              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                {t("landingPage.data_respect_infos")}
              </p>

              <div
                className="flex-row full-width"
                style={{ marginTop: "1rem" }}
              >
                <div
                  className="flex-row flex-center"
                  style={{
                    margin: "1rem",
                    marginLeft: 0,
                    width: "33.3%",
                    aspectRatio: "1/1",
                    padding: "2rem",
                  }}
                >
                  <img
                    src="assets/ai-partners-logo/openai.svg"
                    alt=""
                    style={{ width: 100 }}
                  />
                </div>
                <div
                  className="flex-row flex-center"
                  style={{
                    margin: "1rem",
                    width: "33.3%",
                    aspectRatio: "1/1",
                    padding: "2rem",
                    opacity: 0.5,
                    position: "relative",
                  }}
                >
                  <img
                    src="assets/ai-partners-logo/mistral.png"
                    alt=""
                    style={{ width: 100 }}
                  />
                  <div
                    className="soon-div flex-row flex-center"
                    style={{
                      fontSize: 12,
                      height: 20,
                      position: "absolute",
                      bottom: 0,
                    }}
                  >
                    {t("credits.soon")}
                  </div>
                </div>
                <div
                  className="flex-row flex-center"
                  style={{
                    margin: "1rem",
                    marginRight: 0,
                    width: "33.3%",
                    padding: "2rem",
                    aspectRatio: "1/1",
                    opacity: 0.5,
                    position: "relative",
                  }}
                >
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{
                      width: 100,
                      fontSize: 100,
                      opacity: 0.5,
                      position: "absolute",
                    }}
                  >
                    more_horiz
                  </span>
                </div>
              </div>
            </div>
            <div
              className="flex-column flex-1"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <div
                className="feature-image-container flex-column"
                style={{
                  position: "relative",
                  width: "100%",
                  aspectRatio: "1.42 / 1",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  // maxWidth: "600px"
                }}
              >
                <img
                  src={`/assets/${isDark ? "landing-dark" : "landing-light"}/secure.png`}
                  alt=""
                  style={{
                    zIndex: 5,
                    top: 0,
                    position: "absolute",
                    width: "50%",
                  }}
                />
                <img
                  src={`/assets/${isDark ? "landing-dark" : "landing-light"}/secure.png`}
                  alt=""
                  style={{
                    zIndex: 6,
                    top: "4%",
                    position: "absolute",
                    width: "60%",
                  }}
                />
                <img
                  src={`/assets/${isDark ? "landing-dark" : "landing-light"}/secure.png`}
                  alt=""
                  style={{
                    zIndex: 7,
                    top: "8%",
                    position: "absolute",
                    width: "70%",
                  }}
                />
                <img
                  src={`/assets/${isDark ? "landing-dark" : "landing-light"}/secure.png`}
                  alt=""
                  style={{
                    zIndex: 8,
                    top: "12%",
                    position: "absolute",
                    width: "80%",
                  }}
                />
                <img
                  src={`/assets/${isDark ? "landing-dark" : "landing-light"}/secure.png`}
                  alt=""
                  style={{
                    zIndex: 9,
                    top: "16%",
                    position: "absolute",
                    width: "90%",
                  }}
                />
                <img
                  src={`/assets/${isDark ? "landing-dark" : "landing-light"}/secure.png`}
                  style={{
                    zIndex: 10,
                    top: "20%",
                    position: "absolute",
                    width: "100%",
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="pricing-container flex-column flex-center"
          style={{ justifyContent: "center" }}
        >
          <div
            className="pricing-content flex-column flex-center "
            style={{
              maxWidth: 1200,
              fontSize: 16,
              width: "calc(100% - 2rem)",
            }}
          >
            <h2
              className="landing-sub-title"
              style={{
                marginTop: "2.5rem",
                marginBottom: "2.5rem",
                textAlign: "center",
              }}
            >
              <Trans>{t("landingPage.testimonials_title")}</Trans>
            </h2>

            <p className="landing-sub-infos">
              {t("landingPage.testimonials_infos")}
            </p>

            <div
              className="testimonial-4-col flex-row full-width"
              style={{ marginBottom: "2.5rem" }}
            >
              <div
                className="flex-column"
                style={{ width: "25%", marginRight: "0.5rem" }}
              >
                {[0, 1].map((i) => getTestimonialItem(i))}
              </div>
              <div
                className="flex-column"
                style={{
                  width: "25%",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              >
                {[2, 3, 4].map((i) => getTestimonialItem(i))}
              </div>
              <div
                className="flex-column"
                style={{
                  width: "25%",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              >
                {[5, 6, 7].map((i) => getTestimonialItem(i))}
              </div>
              <div
                className="flex-column"
                style={{ width: "25%", marginLeft: "0.5rem" }}
              >
                {[8, 9].map((i) => getTestimonialItem(i))}
              </div>
            </div>

            <div
              className="testimonial-3-col flex-row full-width"
              style={{ marginBottom: "2.5rem" }}
            >
              <div
                className="flex-column"
                style={{ width: "33%", marginRight: "0.5rem" }}
              >
                {[0, 1, 2].map((i) => getTestimonialItem(i))}
              </div>
              <div
                className="flex-column"
                style={{
                  width: "33%",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              >
                {[3, 4, 5, 6].map((i) => getTestimonialItem(i))}
              </div>
              <div
                className="flex-column"
                style={{
                  width: "33%",
                  marginLeft: "0.5rem",
                }}
              >
                {[7, 8, 9].map((i) => getTestimonialItem(i))}
              </div>
            </div>

            <div
              className="testimonial-2-col flex-row full-width"
              style={{ marginBottom: "2.5rem" }}
            >
              <div
                className="flex-column"
                style={{ width: "50%", marginRight: "0.5rem" }}
              >
                {[0, 1, 2, 3, 4].map((i) => getTestimonialItem(i))}
              </div>
              <div
                className="flex-column"
                style={{
                  width: "50%",
                  marginLeft: "0.5rem",
                }}
              >
                {[5, 6, 7, 8, 9].map((i) => getTestimonialItem(i))}
              </div>
            </div>

            <div
              className="testimonial-1-col flex-row full-width"
              style={{ marginBottom: "2.5rem" }}
            >
              <div className="flex-column" style={{ width: "100%" }}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) =>
                  getTestimonialItem(i),
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="pricing-container flex-column flex-center">
          <div
            className="pricing-content flex-column flex-center"
            style={{ maxWidth: 1200, fontSize: 16, width: "calc(100% - 2rem)" }}
          >
            <h2
              ref={pricingRef}
              className="landing-sub-title-center"
              style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
            >
              <Trans>{t("landingPage.simple_pricing")}</Trans>
            </h2>

            <div
              className="white-box no-padding premium-item full-width flex-row flex-column-tablet flex-1 premium-item-tier-0"
              style={{ marginBottom: "1rem" }}
            >
              <div className="premium-item-header flex-column header-row">
                <h3 className="tier-title flex-row">Tier 0</h3>
                <h3 className="price-title flex-row half-opacity">
                  {t("credits.free")}
                </h3>
              </div>

              <hr style={{ margin: 0 }} className="hide-gt-tablet" />
              <ul
                className="flex-1 flex-column"
                style={{ justifyContent: "center" }}
              >
                <li>
                  <Trans>
                    {t("credits.free_tokens", {
                      amount: Number(5000).toLocaleString(),
                    })}
                  </Trans>
                </li>
                <li>
                  <Trans>{t("credits.project_limit", { count: 1 })}</Trans>
                </li>
                <li>
                  <Trans>{t("credits.source_keys", { amount: 1000 })}</Trans>
                </li>
              </ul>

              <div className="flex-column flex-center">
                <Link
                  to="/register"
                  style={{
                    margin: "0.75rem",
                    marginBottom: "0.75rem",
                    width: "calc(100% - 1.5rem)",
                  }}
                  className="button is-primary-gradient"
                >
                  {t("landingPage.try_for_free")}
                </Link>
              </div>
            </div>

            <div
              className="flex-row full-width flex-column-custom"
              style={{ marginBottom: "1rem" }}
            >
              <div className="flex-row flex-1 flex-column-phone-custom">
                <PremiumItem
                  style={{
                    marginRight: "0.5rem",
                  }}
                  premiumTier={1}
                >
                  <Link
                    style={{
                      margin: "0.75rem",
                      height: "48px",
                      marginLeft: "0.75rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      fontSize: "20px",
                    }}
                    className="button is-primary-gradient"
                    to="/register"
                  >
                    {t("credits.get_item")}
                  </Link>
                </PremiumItem>
                <PremiumItem
                  style={{
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  premiumTier={2}
                >
                  <Link
                    style={{
                      margin: "0.75rem",
                      height: "48px",
                      marginLeft: "0.75rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      fontSize: "20px",
                    }}
                    className="button is-primary-gradient"
                    to="/register"
                  >
                    {t("credits.get_item")}
                  </Link>
                </PremiumItem>
              </div>

              <div className="flex-row flex-1 flex-column-phone-custom">
                <PremiumItem
                  style={{
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  premiumTier={3}
                >
                  <Link
                    style={{
                      margin: "0.75rem",
                      height: "48px",
                      marginLeft: "0.75rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      fontSize: "20px",
                    }}
                    className="button is-primary-gradient"
                    to="/register"
                  >
                    {t("credits.get_item")}
                  </Link>
                </PremiumItem>
                <PremiumItem
                  style={{
                    marginLeft: "0.5rem",
                  }}
                  premiumTier={4}
                >
                  <Link
                    style={{
                      margin: "0.75rem",
                      height: "48px",
                      marginLeft: "0.75rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      fontSize: "20px",
                    }}
                    className="button is-warn-gradient"
                    to="/register"
                  >
                    {t("credits.get_item")}
                  </Link>
                </PremiumItem>
              </div>
            </div>

            <p
              style={{
                fontSize: "18px",
                marginBottom: "2rem",
                width: "100%",
              }}
            >
              {t("credits.premium_infos")}
              <br />
              {t("credits.premium_infos_2")}
            </p>

            <div
              className="full-width flex-row"
              style={{ marginBottom: "2.5rem", alignItems: "center" }}
            >
              <a
                className="button is-bordered mail-button"
                href="mailto:support@champo.ai"
              >
                <span
                  className="msymbol material-symbols-outlined half-opacity"
                  style={{ marginRight: "0.5rem" }}
                >
                  mail
                </span>
                {t("commons.send_mail")}
              </a>

              <Link
                className="social-link flex-row flex-center"
                to="https://x.com/intent/follow?screen_name=GabFrk"
                target="_blank"
                style={{ marginLeft: "1.5rem", marginRight: "2.5rem" }}
              >
                <img
                  style={{ width: 32, height: 32 }}
                  src={
                    "/assets/monochrome-social/" +
                    (isDark ? "x-white.svg" : "x.svg")
                  }
                  alt=""
                />
              </Link>

              <Link
                className="social-link flex-row flex-center"
                to="https://www.linkedin.com/in/gabriel-franck-546a009b"
                target="_blank"
              >
                <img
                  style={{ width: 32, height: 32 }}
                  src={
                    "/assets/monochrome-social/" +
                    (isDark ? "linkedin-white.svg" : "linkedin.svg")
                  }
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>

        <div
          ref={faqRef}
          className="pricing-container flex-column flex-center"
          // style={{ justifyContent: "flex-start" }}
        >
          <div
            className="pricing-content flex-column "
            style={{
              maxWidth: 1200,
              fontSize: 16,
              width: "calc(100% - 2rem)",
            }}
          >
            <span
              className="landing-sub-title"
              style={{ fontSize: "1.5rem", marginTop: "2.5rem" }}
            >
              <strong style={{ fontWeight: "600" }}>FAQ</strong>
            </span>
            <div
              className="flex-row flex-column-tablet"
              style={{
                marginBottom: "7.5rem",
              }}
            >
              <div className="faq-title flex-column">
                <h2 className="landing-sub-title">
                  <Trans>{t("landingPage.faq_title")}</Trans>
                </h2>
              </div>

              <div className="faq-questions-container flex-column">
                <div className="faq-questions flex-column">
                  <h3
                    className={selectedQuestion === 1 ? "selected" : ""}
                    onClick={() => {
                      setQuestion(1)
                    }}
                  >
                    <div className="flex-1">
                      <span className="question-text">
                        {t("landingPage.question_1")}
                      </span>
                    </div>

                    <span className="msymbol material-symbols-outlined">
                      {selectedQuestion === 1
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </span>
                  </h3>

                  {selectedQuestion === 1 && (
                    <div className="answer-content fade-in-top flex-column">
                      <p
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        {t("landingPage.answer_1")}
                      </p>
                    </div>
                  )}

                  <hr />

                  <h3
                    className={selectedQuestion === 2 ? "selected" : ""}
                    onClick={() => {
                      setQuestion(2)
                    }}
                  >
                    <div className="flex-1">
                      <span className="question-text">
                        {t("landingPage.question_2")}
                      </span>
                    </div>

                    <span className="msymbol material-symbols-outlined">
                      {selectedQuestion === 2
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </span>
                  </h3>
                  {selectedQuestion === 2 && (
                    <div className="answer-content fade-in-top flex-column">
                      <p
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        {t("landingPage.answer_2")}
                      </p>
                    </div>
                  )}

                  <hr />

                  <h3
                    className={selectedQuestion === 3 ? "selected" : ""}
                    onClick={() => {
                      setQuestion(3)
                    }}
                  >
                    <div className="flex-1">
                      <span className="question-text">
                        {t("landingPage.question_3")}
                      </span>
                    </div>

                    <span className="msymbol material-symbols-outlined">
                      {selectedQuestion === 3
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </span>
                  </h3>

                  {selectedQuestion === 3 && (
                    <div className="answer-content fade-in-top flex-column">
                      <p
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        {t("landingPage.answer_3")}
                      </p>
                    </div>
                  )}

                  <hr />

                  <h3
                    className={selectedQuestion === 4 ? "selected" : ""}
                    onClick={() => {
                      setQuestion(4)
                    }}
                  >
                    <div className="flex-1">
                      <span className="question-text">
                        {t("landingPage.question_4")}
                      </span>
                    </div>

                    <span className="msymbol material-symbols-outlined">
                      {selectedQuestion === 4
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </span>
                  </h3>

                  {selectedQuestion === 4 && (
                    <div className="answer-content fade-in-top flex-column">
                      <p
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        {t("landingPage.answer_4")}
                      </p>
                    </div>
                  )}

                  <hr />

                  <h3
                    className={selectedQuestion === 5 ? "selected" : ""}
                    onClick={() => {
                      setQuestion(5)
                    }}
                  >
                    <div className="flex-1">
                      <span className="question-text">
                        {t("landingPage.question_5")}
                      </span>
                    </div>

                    <span className="msymbol material-symbols-outlined">
                      {selectedQuestion === 5
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </span>
                  </h3>

                  {selectedQuestion === 5 && (
                    <div className="answer-content fade-in-top flex-column">
                      <p
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        {t("landingPage.answer_5")}
                      </p>

                      <div
                        className="flex-row"
                        style={{
                          marginTop: "0.75rem",
                        }}
                      >
                        <Link
                          className="social-link"
                          to="https://twitter.com/GabFrk"
                          target="_blank"
                          style={{ marginRight: "1.5rem" }}
                        >
                          <img
                            style={{ width: 24, height: 24 }}
                            src={
                              "/assets/monochrome-social/" +
                              (isDark ? "x-white.svg" : "x.svg")
                            }
                            alt=""
                          />
                        </Link>

                        <Link
                          className="social-link"
                          to="https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=gabriel-franck-546a009b"
                          target="_blank"
                        >
                          <img
                            style={{ width: 24, height: 24 }}
                            src={
                              "/assets/monochrome-social/" +
                              (isDark ? "linkedin-white.svg" : "linkedin.svg")
                            }
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  )}

                  <hr />

                  <h3
                    className={selectedQuestion === 6 ? "selected" : ""}
                    onClick={() => {
                      setQuestion(6)
                    }}
                  >
                    <div className="flex-1">
                      <span className="question-text">
                        {t("landingPage.question_6")}
                      </span>
                    </div>

                    <span className="msymbol material-symbols-outlined">
                      {selectedQuestion === 6
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </span>
                  </h3>

                  {selectedQuestion === 6 && (
                    <div className="answer-content fade-in-top flex-column">
                      <p
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        {t("landingPage.answer_6")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="pricing-container flex-column flex-center"
          style={{ minHeight: "unset", marginBottom: "2.5rem" }}
        >
          <div
            className="pricing-content flex-column flex-center"
            style={{
              maxWidth: 1200,
              fontSize: 16,
              width: "calc(100% - 2rem)",
              marginBottom: 0,
              height: "100%",
            }}
          >
            <h2
              className="landing-sub-title-center"
              style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
            >
              <Trans>{t("landingPage.last_part_title")}</Trans>
            </h2>

            <p className="landing-sub-infos">
              {t("landingPage.last_part_paragraph")}
            </p>

            <Link
              className="button is-primary-gradient glow-on-hover"
              to="/register"
              style={{
                maxWidth: "100%",
                whiteSpace: "normal",
                marginBottom: "5rem",
              }}
            >
              <Trans>{t("landingPage.last_part_cta")}</Trans>
            </Link>

            <div className="full-width">
              <div className="footer-image-container">
                <div className="footer-image-inset">
                  <img
                    className="footer-image"
                    src={`/assets/${isDark ? "landing-dark" : "landing-light"}/screen-editor.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="pricing-container flex-column flex-center"
          style={{
            minHeight: "unset",
            marginBottom: "7.5rem",
            marginTop: "5rem",
          }}
        >
          <div
            className="pricing-content flex-row flex-column-phone"
            style={{
              maxWidth: 1200,
              fontSize: 16,
              width: "calc(100% - 2rem)",
              marginBottom: 0,
              height: "100%",
            }}
          >
            <div
              className="flex-column"
              style={{
                width: "25%",
                minWidth: "300px",
                justifyContent: "flex-start",
              }}
            >
              <NavLink
                to="/"
                className="navbar-logo"
                style={{
                  margin: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  if (scrollToTop) scrollToTop()
                }}
              >
                <span className="link-text flex-row flex-center">
                  <img
                    src="/logo512.png"
                    alt=""
                    style={{
                      width: 40,
                      height: 40,
                      marginRight: "0.75rem",
                    }}
                  />

                  <h1 className="text-center">
                    Champo
                    <span className="primary-gradient-red-text font-bold">
                      AI
                    </span>
                  </h1>
                </span>
              </NavLink>
              <p
                style={{
                  fontSize: "0.8rem",
                  fontStyle: "italic",
                  opacity: 0.5,
                  marginTop: "1.5rem",
                }}
              >
                made with ❤️ by
              </p>
              <Link
                className="flex-row social-link"
                to="https://twitter.com/GabFrk"
                target="_blank"
                style={{
                  alignItems: "center",
                  marginTop: "0.5rem",
                }}
              >
                <img
                  src="/assets/testimonial-pics/pic-1.png"
                  alt=""
                  style={{
                    width: 48,
                    height: 48,
                    borderRadius: 24,
                    marginRight: "0.75rem",
                  }}
                />
                <div className="flex-column">
                  <b>Gab Franck</b>
                  <i style={{ fontSize: "0.8rem" }}>@GabFrk</i>
                </div>
              </Link>
              <div
                className="flex-row"
                style={{
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Link
                  className="social-link"
                  to="https://twitter.com/GabFrk"
                  target="_blank"
                  style={{ marginRight: "1.5rem" }}
                >
                  <img
                    style={{ width: 24, height: 24 }}
                    src={
                      "/assets/monochrome-social/" +
                      (isDark ? "x-white.svg" : "x.svg")
                    }
                    alt=""
                  />
                </Link>

                <Link
                  className="social-link"
                  to="https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=gabriel-franck-546a009b"
                  target="_blank"
                >
                  <img
                    style={{ width: 24, height: 24 }}
                    src={
                      "/assets/monochrome-social/" +
                      (isDark ? "linkedin-white.svg" : "linkedin.svg")
                    }
                    alt=""
                  />
                </Link>
              </div>
            </div>

            <div className="flex-row flex-column-tablet flex-1">
              <div className="footer-link-category flex-column">
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    marginBottom: "0.5rem",
                  }}
                  className="font-bold half-opacity"
                >
                  {t("landingPage.other_tools")}
                </h3>

                {/* <Link
                  className="footer-link"
                  to="https://champo.ai"
                  target="_blank"
                >
                  ChampoAI
                </Link> */}

                <Link
                  className="footer-link"
                  to="https://socialscreenshots.com"
                  target="_blank"
                >
                  SocialScreenshots
                </Link>
              </div>

              <div className="footer-link-category not-first-cat flex-column">
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    marginBottom: "0.5rem",
                  }}
                  className="font-bold half-opacity"
                >
                  {t("landingPage.links")}
                </h3>
                {isLoggedIn ? (
                  <>
                    <Link className="footer-link" to="/billing/offers">
                      {t("credits.title")}
                    </Link>
                    <Link className="footer-link" to="/profile">
                      {t("auth.profile")}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link className="footer-link" to="/auth">
                      {t("landingPage.sign_in")}
                    </Link>
                    <Link className="footer-link" to="/register">
                      {t("landingPage.sign_up")}
                    </Link>
                    <Link className="footer-link" to="/forgot-password">
                      {t("auth.forgot_password")}
                    </Link>
                  </>
                )}
              </div>

              <div className="footer-link-category not-first-cat flex-column">
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    marginBottom: "0.5rem",
                  }}
                  className="font-bold half-opacity"
                >
                  {t("landingPage.legal")}
                </h3>

                <Link className="footer-link" to="/">
                  {t("landingPage.legal_mention")}
                </Link>
                <Link className="footer-link" to="/">
                  {t("landingPage.conditions")}
                </Link>
                <Link className="footer-link" to="/">
                  {t("landingPage.privacy")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="features-container flex-column flex-center">
          <div
            className="features-content flex-column flex-center"
            style={{
              maxWidth: 1200,
              fontSize: 16,
              width: "calc(100% - 2rem)",
              marginBottom: "5rem",
            }}
          >
            <h2
              className="landing-sub-title-center"
              style={{
                marginTop: "2.5rem",
                marginBottom: "1rem",
                maxWidth: 700,
              }}
            >
              <Trans>{t("landingPage.preview_price_title")}</Trans>
            </h2>

            <div className="language-buttons flex-row">
              <div
                className="button-div flex-column"
                style={{ marginRight: "0.5rem" }}
              >
                <p className="infos-credits" style={{ margin: 0 }}>
                  <b className="infos-creits-label">
                    {t("translate.inputLang")}
                  </b>
                </p>
                <Dropdown
                  className="full-width language-selector"
                  title={
                    inputLang ? (
                      <div className="flex-row">
                        <div>
                          <span className={getFlagClass(inputLang[2])}></span>
                        </div>
                        <div style={{ marginLeft: "1rem" }}>{inputLang[2]}</div>
                      </div>
                    ) : (
                      t("translate.select_language")
                    )
                  }
                  rightMenu={true}
                >
                  {languages.map((item) => (
                    <div
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        setInputLang(item)
                      }}
                      className="dropdown-item"
                    >
                      <div
                        className={`dropdown-link flex-row ${
                          outputLang && item[2] === outputLang[2]
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div
                          className="dropdown-circle"
                          style={{ marginLeft: "0.3rem" }}
                        >
                          <span className={getFlagClass(item[2])}></span>
                        </div>
                        <span
                          className="text-ellipsis flex-1 "
                          style={{ marginLeft: "1.3rem" }}
                        >
                          {item[2]}
                        </span>
                      </div>
                    </div>
                  ))}
                </Dropdown>
              </div>

              <div
                className="button-div flex-column"
                style={{ marginLeft: "0.5rem" }}
              >
                <p className="infos-credits" style={{ margin: 0 }}>
                  <b className="infos-creits-label">
                    {t("translate.outputLang")}
                  </b>
                </p>
                <Dropdown
                  className="full-width language-selector"
                  customMenuStyle={{ maxHeight: 300, right: 0, left: "unset" }}
                  rightMenu={true}
                  title={
                    outputLang ? (
                      <div className="flex-row">
                        <div>
                          <span className={getFlagClass(outputLang[2])}></span>
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                          {outputLang[2]}
                        </div>
                      </div>
                    ) : (
                      t("translate.select_language")
                    )
                  }
                >
                  {languages.map((item) => (
                    <div
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        setOutputLang(item)
                      }}
                      className="dropdown-item"
                    >
                      <div
                        className={`dropdown-link flex-row ${
                          outputLang && item[2] === outputLang[2]
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div
                          className="dropdown-circle"
                          style={{ marginLeft: "0.3rem" }}
                        >
                          <span className={getFlagClass(item[2])}></span>
                        </div>
                        <span
                          className="text-ellipsis flex-1 "
                          style={{ marginLeft: "1.3rem" }}
                        >
                          {item[2]}
                        </span>
                      </div>
                    </div>
                  ))}
                </Dropdown>
              </div>
            </div>

            <div className="translation-container preview-cost-container flex-row">
              <ReactCodeMirror
                className="codemirror-container full-width"
                placeholder={t("translate.input_code_placeholder")}
                height="100%"
                value={inputCodePreview}
                theme="dark"
                extensions={[javascript(), EditorView.lineWrapping]}
                onChange={onChangePreview}
              />
            </div>

            <div
              className="translate-buttons flex-row"
              style={{ marginTop: "0.5rem", marginBottom: "2.5rem" }}
            >
              <Link
                to="/register"
                className="translate-button button is-primary-gradient"
              >
                {t("translate.translate", {
                  amount: filePricePreview,
                })}
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default LandingPage
