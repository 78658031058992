import { Link } from "react-router-dom"
import PremiumItem from "./PremiumItem"
import { useContext } from "react"
import { AuthContext } from "../../context/Auth"
import { Trans, useTranslation } from "react-i18next"

type Props = {
  limitType: string
  closeModal: () => void
}

const ModalLimitReached = ({ limitType, closeModal }: Props) => {
  const { t } = useTranslation()

  const { userAccountTier } = useContext(AuthContext)

  return (
    <>
      <div className="premium-infos" style={{ marginBottom: "1.5rem" }}>
        <div className="subscription-info flex-column flex-1">
          {limitType === "source_keys" && (
            <>
              <h1 className="modal-title">
                {t("translationEditor.key_limit_reached")}
              </h1>

              <p style={{ marginBottom: "1rem" }}>
                <Trans>{t("translationEditor.key_limit_reached_infos")}</Trans>
              </p>

              <div className="flex-column full-width">
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier0-div font-bold">{t('credits.free')}</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier0-color">
                      {(1000).toLocaleString()}
                    </strong>{" "}
                    {t("translationEditor.source_keys_lower")}
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier1-div font-bold">Starter</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier1-color">
                      {(5000).toLocaleString()}
                    </strong>{" "}
                    {t("translationEditor.source_keys_lower")}
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier2-div font-bold">Pro</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier2-color">
                      {(25000).toLocaleString()}
                    </strong>{" "}
                    {t("translationEditor.source_keys_lower")}
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier3-div font-bold">Team</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier3-color">
                      {(50000).toLocaleString()}
                    </strong>{" "}
                    {t("translationEditor.source_keys_lower")}
                  </div>
                </div>
                <div className="flex-row">
                  <p className="tier4-div font-bold">Business</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier4-color">
                      {(200000).toLocaleString()}
                    </strong>{" "}
                    {t("translationEditor.source_keys_lower")}
                  </div>
                </div>
              </div>
            </>
          )}
          {limitType === "lang" && (
            <>
              <h1 className="modal-title">
                {t("translationEditor.lang_limit_reached")}
              </h1>

              <p style={{ marginBottom: "1rem" }}>
                <Trans>{t("translationEditor.lang_limit_reached_infos")}</Trans>
              </p>

              <div className="flex-column full-width">
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier0-div font-bold">{t('credits.free')}</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier0-color">1</strong>{" "}
                    {t("translationEditor.lang_lower")}
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier1-div font-bold">Starter</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier1-color">2</strong>{" "}
                    {t("translationEditor.langs_lower")}
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier2-div font-bold">Pro</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier2-color">5</strong>{" "}
                    {t("translationEditor.langs_lower")}
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier3-div font-bold">Team</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier3-color">10</strong>{" "}
                    {t("translationEditor.langs_lower")}
                  </div>
                </div>
                <div className="flex-row">
                  <p className="tier4-div font-bold">Business</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier4-color">
                      {t("translationEditor.unlimited_langs_lower")}
                    </strong>
                  </div>
                </div>
              </div>
            </>
          )}
          {limitType === "projects" && (
            <>
              <h1 className="modal-title">
                {t("translationEditor.project_limit_reached")}
              </h1>

              <p style={{ marginBottom: "1rem" }}>
                <Trans>
                  {t("translationEditor.project_limit_reached_infos")}
                </Trans>
              </p>

              <div className="flex-column full-width">
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier0-div font-bold">{t('credits.free')}</p>
                  <div
                    className="flex-1 strong-tier0-color"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {" "}
                      {t("translationEditor.project_lower", { count: 1 })}
                    </Trans>
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier1-div font-bold">Starter</p>
                  <div
                    className="flex-1 strong-tier1-color"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {t("translationEditor.project_lower", { count: 2 })}
                    </Trans>
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier2-div font-bold">Pro</p>
                  <div
                    className="flex-1 strong-tier2-color"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {" "}
                      {t("translationEditor.project_lower", { count: 3 })}
                    </Trans>
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier3-div font-bold">Team</p>
                  <div
                    className="flex-1 strong-tier3-color"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {t("translationEditor.project_lower", { count: 5 })}
                    </Trans>
                  </div>
                </div>
                <div className="flex-row">
                  <p className="tier4-div font-bold">Business</p>
                  <div
                    className="flex-1 strong-tier4-color"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {t("translationEditor.project_lower", { count: 50 })}
                    </Trans>
                  </div>
                </div>
              </div>
            </>
          )}
          {limitType === "tokens" && (
            <>
              <h1 className="modal-title">
                {t("translationEditor.token_limit_reached")}
              </h1>

              <p style={{ marginBottom: "1rem" }}>
                <Trans>
                  {t("translationEditor.token_limit_reached_infos")}
                </Trans>
              </p>

              <div className="flex-column full-width">
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier0-div font-bold">{t('credits.free')}</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>{t("credits.free_tokens", { amount: 5000 })}</Trans>
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier1-div font-bold">Starter</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {t("credits.mensual_credit", {
                        amount: (50000).toLocaleString(),
                      })}
                    </Trans>
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier2-div font-bold">Pro</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {t("credits.mensual_credit", {
                        amount: (150000).toLocaleString(),
                      })}
                    </Trans>
                  </div>
                </div>
                <div className="flex-row" style={{ marginBottom: "0.25rem" }}>
                  <p className="tier3-div font-bold">Team</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <Trans>
                      {t("credits.mensual_credit", {
                        amount: (300000).toLocaleString(),
                      })}
                    </Trans>
                  </div>
                </div>
                <div className="flex-row">
                  <p className="tier4-div font-bold">Business</p>
                  <div
                    className="flex-1"
                    style={{ textAlign: "right", alignItems: "flex-end" }}
                  >
                    <strong className="tier4-color">
                      <Trans>
                        {t("credits.mensual_credit", {
                          amount: (1000000).toLocaleString(),
                        })}
                      </Trans>
                    </strong>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="account-tier-infos">
          <PremiumItem premiumTier={userAccountTier} noPrice={true} style={{ height: "100%" }}>
            <></>
            {limitType === "tokens" && userAccountTier !== 4 && (
              <Link
                className="button is-primary-gradient"
                style={{ margin: "0.75rem" }}
                to="/billing/offers"
              >
                {t("credits.upgrade_account")}
              </Link>
            )}
          </PremiumItem>
        </div>
      </div>
      <div className="flex-row full-width" style={{ marginBottom: "1rem" }}>
        <button
          className="button is-bordered"
          style={{ marginRight: "0.5rem", flex: 1 }}
          onClick={closeModal}
        >
          {t("commons.back")}
        </button>
        {limitType === "tokens" ? (
          <Link
            className="button is-primary-gradient"
            style={{ marginLeft: "0.5rem", flex: 2 }}
            to="/billing/buy-credits"
          >
            {t("credits.getCredits")}
          </Link>
        ) : (
          <Link
            className="button is-primary-gradient"
            style={{ marginLeft: "0.5rem", flex: 2 }}
            to="/billing/offers"
          >
            {t("credits.upgrade_account")}
          </Link>
        )}
      </div>
    </>
  )
}

export default ModalLimitReached
