import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

import AnimatedLoader from "../../components/AnimatedLoader"
import Paginator from "../../components/Paginator"

import Modal from "../../components/Modal"

import { PaymentContext } from "../../../context/Payment"
import ModalPayment from "../../components/ModalPayment"
import posthog from "posthog-js"
import ReactGA from "react-ga4"

export const BuyCredits = () => {
  const { t } = useTranslation()

  const {
    currentPage,

    credits,
    countCredits,

    modalCreditsIsShown,
    paymentAmount,

    setCurrentPage,

    setModalCreditsIsShown,
    setPaymentAmount,

    setIsStripePaymentLoaded,

    refreshCredits,
    refreshInvoices,
    refreshPaymentMethods,
  } = useContext(PaymentContext)

  useEffect(() => {
    document.title = t('credits.buy_credits')

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview") 
      ReactGA.send({
        hitType: "pageview",
        page: "/billing/buy-credits",
        title: "Buy credits",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const changePage = (page_number: number) => {
    setCurrentPage(page_number)
  }

  const handleKeyName = (event: any) => {
    if (
      Number(event.target.value) <= 2000 &&
      ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
        event.target.value[event.target.value.length - 1],
      )
    ) {
      setPaymentAmount(Number(event.target.value))
    } else {
      // if (!Number.isNaN(event.target.value)) {
      // }
    }
  }

  return (
    <>
      <div className="payment-amount">
        <div className="flex-row">
          <div className="control has-icons-right">
            <input
              className="full-width input"
              type="number"
              placeholder={t("apiKeys.name_long")}
              value={paymentAmount}
              onChange={handleKeyName}
              style={{paddingLeft: "0.25rem", height: "100%"}}
            />
            <span className="icon is-small is-right" style={{height: "100%"}}>
              <span className="msymbol material-symbols-outlined">euro</span>
            </span>
          </div>

          <button
            className="button is-primary-gradient"
            style={{whiteSpace:"normal", height: "unset", padding: "0.5rem 1.5rem"}}
            onClick={() => {
              setIsStripePaymentLoaded(false)
              setModalCreditsIsShown(true)
            }}
          >
            {t("credits.get_credits", {amount: (paymentAmount / 0.5 * 1000).toLocaleString()})}
          </button>
        </div>

        <p style={{ marginTop: "0.35rem", color: "#888" }}>
          {t("credits.getCreditsInfos")} <b style={{ opacity: 1 }}>1€</b>{" "}
          {t("credits.and")} <b style={{ opacity: 1 }}>2000€</b>.
        </p>
      </div>

      <p className="infos-credits" style={{marginTop:"1rem"}}>
        {t("credits.paragraph1")}
        <br />
        {t("credits.paragraph2")}
      </p>

      {credits !== null && credits?.length > 0 && (
        <hr
          style={{
            margin: 0,
            marginTop: "1rem",
            marginBottom: "2rem",
            width: "100%",
          }}
        />
      )}

      {!credits && (
        <div className="champo-page-container">
          <AnimatedLoader />
        </div>
      )}
      

      {credits !== null && credits?.length > 0 && (
        <>
          <div className="champo-page-container">
            <div className="flex-column table-list">
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  minWidth: "100%",
                }}
              >
                <div className="flex-row table-item header-item">
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                    }}
                  >
                    {t("credits.createdAt")}
                  </div>
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                    }}
                  >
                    {t("credits.expires_at")}
                  </div>

                  <div className=" flex-1"></div>
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                      textAlign: "right",
                    }}
                  >
                    {t("credits.balance")}
                  </div>
                  <div
                    style={{
                      width: "10rem",
                      minWidth: "10rem",
                      textAlign: "right",
                    }}
                  >
                    {t("credits.cost")}
                  </div>
                </div>

                {credits.map((item: any, index: number) => (
                  <div
                    key={item.id_credit + "_list_100"}
                    className={
                      "table-item flex-row " +
                      (index % 2 === 0 ? "even" : "odd")
                    }
                  >
                    <div
                      style={{
                        width: "12rem",
                        minWidth: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b> {new Date(item.createdAt).toLocaleString()}</b>
                      </p>
                    </div>
                    <div
                      style={{
                        width: "12rem",
                        minWidth: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b> {new Date(item.expiresAt).toLocaleString()}</b>
                      </p>
                    </div>

                    <div className="flex-column flex-1"></div>
                    <div className="flex-row">
                      <div
                        style={{
                          width: "12rem",
                          minWidth: "12rem",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        <b>
                          {Number(item.current_balance).toLocaleString()}
                        </b>
                        {" / "}
                        {Number(item.total_credit).toLocaleString()}
                      </div>

                      <div
                        style={{
                          width: "10rem",
                          minWidth: "10rem",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        <b>
                          {Number(item.payment_amount).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                          €
                        </b>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Paginator
              currentPage={currentPage}
              totalItem={countCredits}
              itemPerPage={50}
              changeCurrentPage={changePage}
            />
          </div>
        </>
      )}

      <Modal
        isShown={modalCreditsIsShown}
        closeModal={() => {
          setIsStripePaymentLoaded(false)
          setModalCreditsIsShown(false)
        }}
      >
        <ModalPayment
          isLoaded={() => {}}
          paymentAmount={paymentAmount}
          refreshResult={() => {
            refreshCredits()
            refreshPaymentMethods()
            refreshInvoices()
          }}
        />
      </Modal>
    </>
  )
}

export default BuyCredits
