import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  getIsLoggedIn,
  postLogin,
  postLogout,
  postRegister,
} from "../services/auth"
import { sha1 } from "js-sha1"
import { getUserSubscription } from "../services/subscriptions"
import posthog from "posthog-js"
import { getTraducerInvitations, postJoinProject } from "../services/traducers"
import Modal from "../ui/components/Modal"

type AuthContextType = {
  loadingFinished: boolean
  isLoggedIn: boolean
  currentUser: any
  login: ({
    username,
    password,
  }: {
    username: string
    password: string
  }) => Promise<boolean>
  register: ({
    username,
    password,
    mail,
  }: {
    username: string
    password: string
    mail: string
  }) => Promise<boolean>
  logout: () => void

  setUserAccountTier: Dispatch<SetStateAction<number>>
  setUserSubscription: () => void
  setCurrentBalance: Dispatch<SetStateAction<number>>
  setEditorKeyCount: Dispatch<SetStateAction<number>>
  setProjectsCount: Dispatch<SetStateAction<number>>
  userAccountTier: number
  currentBalance: number
  alreadyGotFreeCredits: boolean
  editorKeyCount: number
  projectCount: number

  langUsed: number
  listLangUsed: [string, string, string][]
}

export const AuthContext = React.createContext<AuthContextType>({
  loadingFinished: false,
  isLoggedIn: false,
  currentUser: {},
  login: async () => false,
  register: async () => false,
  logout: async () => {},

  setUserSubscription: () => {},
  setUserAccountTier: () => {},
  setCurrentBalance: () => {},
  setEditorKeyCount: () => {},
  setProjectsCount: () => {},
  userAccountTier: 0,
  currentBalance: 0,
  alreadyGotFreeCredits: true,
  editorKeyCount: 0,
  projectCount: 0,

  langUsed: 0,
  listLangUsed: [],
})

type Props = {
  children: React.ReactNode
}

export function AuthProvider({ children }: Props) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [loadingFinished, setLoadingFinished] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<any>()

  const loadingStatus = useRef<boolean>(false)

  const languages = [
    ["ar", "🇦🇪", "ar-AE"],
    ["af", "🇿🇦", "af-ZA"],
    ["bg", "🇧🇬", "bg-BG"],
    ["cs", "🇨🇿", "cs-CZ"],
    ["da", "🇩🇰", "da-DK"],
    ["de", "🇩🇪", "de-DE"],
    ["el", "🇬🇷", "el-GR"],
    ["en", "🇺🇸", "en-US"],
    ["es", "🇪🇸", "es-ES"],
    ["et", "🇪🇪", "et-EE"],
    ["fi", "🇫🇮", "fi-FI"],
    ["fr", "🇫🇷", "fr-FR"],
    ["he", "🇮🇱", "he-IL"],
    ["hr", "🇭🇷", "hr-HR"],
    ["hr", "🇭🇺", "hu-HU"],
    ["hy", "🇦🇲", "hy-AM"],
    ["id", "🇮🇩", "id-ID"],
    ["is", "🇮🇸", "is-IS"],
    ["it", "🇮🇹", "it-IT"],
    ["ja", "🇯🇵", "ja-JP"],
    ["ko", "🇰🇷", "ko-KR"],
    ["lb", "🇱🇺", "lb-LU"],
    ["nb", "🇳🇴", "nb-NO"],
    ["nl", "🇳🇱", "nl-NL"],
    ["br", "🇧🇷", "pt-BR"],
    ["pt", "🇵🇹", "pt-PT"],
    ["ro", "🇷🇴", "ro-RO"],
    ["ru", "🇷🇺", "ru-RU"],
    ["sk", "🇸🇰", "sk-SK"],
    ["sl", "🇸🇮", "sl-SI"],
    ["sq", "🇦🇱", "sq-AL"],
    ["sv", "🇸🇪", "sv-SE"],
    ["th", "🇹🇭", "th-TH"],
    ["tr", "🇹🇷", "tr-TR"],
    ["uk", "🇺🇦", "uk-UA"],
    ["vi", "🇻🇳", "vi-VN"],
    ["zh", "🇨🇳", "zh-CN"],
  ]

  useEffect(() => {
    if (!loadingStatus.current) {
      loadingStatus.current = true

      const fetchData = async () => {
        return await getIsLoggedIn()
      }

      fetchData()
        .then((res) => {
          if (res.status === 200 && res.data?.id_user) {
            setIsLoggedIn(true)
            setCurrentUser(res.data)

            if (process.env.REACT_APP_ENV === "production") {
              posthog?.identify(res.data.id_user, {
                email: res.data?.mail,
              })
            }
          } else {
            setIsLoggedIn(false)
          }
          setLoadingFinished(true)
        })
        .catch((err) => {
          console.log(err)
          loadingStatus.current = true
          setIsLoggedIn(false)
          setLoadingFinished(true)
        })
    }
  }, [])

  const login = async ({
    username,
    password,
  }: {
    username: string
    password: string
  }) => {
    try {
      const loginResult = await postLogin({
        username: username,
        password: sha1(password),
      })

      if (loginResult.status === 200) {
        setCurrentUser(loginResult.data)
        if (process.env.REACT_APP_ENV === "production") {
          posthog?.identify(loginResult.data.id_user, {
            email: loginResult.data?.mail,
          })
        }
        setIsLoggedIn(true)
        return true
      } else {
        setCurrentUser(null)
        return false
      }
    } catch (error) {
      setIsLoggedIn(false)
      return false
    }
  }

  const register = async ({
    username,
    password,
    mail,
  }: {
    username: string
    password: string
    mail: string
  }) => {
    try {
      const registerResult = await postRegister({
        username: username,
        password: sha1(password),
        mail: mail,
      })

      if (registerResult.status === 200) {
        setCurrentUser(registerResult.data)
        if (process.env.REACT_APP_ENV === "production") {
          posthog?.identify(registerResult.data.id_user, {
            email: registerResult.data?.mail,
          })
        }
        setIsLoggedIn(true)
        return true
      } else {
        setCurrentUser(null)
        return false
      }
    } catch (error) {
      setIsLoggedIn(false)
      return false
    }
  }

  const logout = async () => {
    try {
      const loginResult = await postLogout()
      setCurrentUser(null)
      if (loginResult.status === 200) setIsLoggedIn(false)
    } catch (error) {
      console.log(error)
    }
  }

  const [userAccountTier, setUserAccountTier] = useState<number>(0)
  const [currentBalance, setCurrentBalance] = useState<number>(0)
  const [editorKeyCount, setEditorKeyCount] = useState<number>(0)
  const [projectCount, setProjectsCount] = useState<number>(0)

  const [alreadyGotFreeCredits, setAlreadyGotFreeCredits] =
    useState<boolean>(true)

  const setUserSubscription = async () => {
    const result = await getUserSubscription()
    setAlreadyGotFreeCredits(result.data.already_got_free_credits)
    setUserAccountTier(result.data.account_tier)
    setCurrentBalance(result.data.balance)
    setLangUsed(result.data.lang_used)
    setEditorKeyCount(result.data.editor_key_count)
    setProjectsCount(result.data.projects_count)

    const formattedLang = result.data?.list_lang_used?.map((lang: string) => {
      let result = languages.find((arrayLang) => lang === arrayLang[2])
      if (result) {
        return result
      } else {
        return ["?", "?", lang]
      }
    })
    setListLangUsed(formattedLang)
  }

  useEffect(() => {
    if (currentUser && !userAccountTier) {
      setUserSubscription()
    }
  }, [currentUser, userAccountTier]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentUser && userAccountTier) {
      setUserAccountTier(0)
      setCurrentBalance(0)
      setLangUsed(0)
      setEditorKeyCount(0)
      setListLangUsed([])
      setAlreadyGotFreeCredits(true)
    }
  }, [currentUser, userAccountTier])

  const [langUsed, setLangUsed] = useState<number>(0)
  const [listLangUsed, setListLangUsed] = useState<[string, string, string][]>(
    [],
  )

  const [traducerInvitations, setTraducerInvitations] = useState<any[]>()
  const [showTraducerInvitationModal, setShowTraducerInvitationModal] =
    useState<boolean>(false)
  const [currentInvitation, setCurrentInvitation] = useState<any>()

  const checkTraducerInvitations = async () => {
    const result = await getTraducerInvitations()
    setTraducerInvitations(result.data.rows)

    const pendingInvitations = result.data.rows.filter(
      (invitation: any) => invitation.status === "PENDING",
    )
    if (pendingInvitations.length > 0) {
      setCurrentInvitation(pendingInvitations[0])
      setShowTraducerInvitationModal(true)
    }
  }

  useEffect(() => {
    if (currentUser && traducerInvitations === undefined) {
      checkTraducerInvitations()
    }
  }, [currentUser, traducerInvitations])

  const joinProject = async (project_id: any) => {
    await postJoinProject({ projectId: project_id })
    await checkTraducerInvitations()
    setShowTraducerInvitationModal(false)
  }

  return (
    <AuthContext.Provider
      value={{
        loadingFinished: loadingFinished,
        isLoggedIn: isLoggedIn,
        login: login,
        logout: logout,
        register: register,
        currentUser: currentUser,

        setUserAccountTier,
        setUserSubscription,
        setCurrentBalance,
        setEditorKeyCount,
        setProjectsCount,
        editorKeyCount,
        projectCount,
        langUsed,
        listLangUsed,

        userAccountTier,
        currentBalance,
        alreadyGotFreeCredits,
      }}
    >
      {children}
      <Modal
        isShown={showTraducerInvitationModal}
        closeModal={() => {
          setShowTraducerInvitationModal(false)
        }}
      >
        <h1 className="modal-title">Invitation à rejoindre un projet</h1>

        <p style={{ marginBottom: "1rem" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium
          exercitationem ea illum numquam culpa aliquam reprehenderit, dolorem
          fugit modi quidem magnam velit quas quis nostrum distinctio, impedit
          possimus praesentium. Veniam.
        </p>

        <div className="flex-row" style={{ marginBottom: "1rem" }}>
          <button
            className="button is-bordered flex-1"
            style={{ marginRight: "0.5rem" }}
          >
            Refuser
          </button>
          <button
            className="button is-primary-gradient flex-1"
            style={{ marginLeft: "0.5rem" }}
            onClick={() => {
              joinProject(currentInvitation.project_id)
            }}
          >
            Accepter
          </button>
        </div>
      </Modal>
    </AuthContext.Provider>
  )
}
