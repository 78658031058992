import { useTranslation } from "react-i18next"

const CheckResultBadge = () => {
  const { t } = useTranslation()

  return (
    <div className="background-badge" style={{ marginLeft: "0.5rem" }}>
      <div
        className="warn-bordered-badge flex-row flex-1 flex-center"
        style={{
          height: "40px",
          padding: "0 0.5rem",
          paddingLeft: "0.15rem",
          fontSize: "0.8rem",
          minWidth: "100px",
        }}
      >
        <span className="msymbol material-symbols-outlined">
          keyboard_arrow_left
        </span>
        <p className="font-bold">{t("landingPage.check_result_here")}</p>
      </div>
    </div>
  )
}

export default CheckResultBadge
