import { CSSProperties } from "react"

export const PaymentMethodImage = ({
  name,
  style,
}: {
  name: string
  style?: CSSProperties
}) => {
  switch (name) {
    case "amex":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/amex.png"
          alt="amex"
        />
      )
    case "jcb":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/jcb.png"
          alt="jcb"
        />
      )
    case "discover":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/discover.png"
          alt="discover"
        />
      )
    case "sage":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/sage.png"
          alt="sage"
        />
      )
    case "skrill":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/skrill.png"
          alt="skrill"
        />
      )
    case "paypal":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/paypal.png"
          alt="paypal"
        />
      )
    case "cirrus":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/cirrus.png"
          alt="cirrus"
        />
      )
    case "maestro":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/maestro.png"
          alt="maestro"
        />
      )
    case "mastercard":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/mastercard.png"
          alt="mastercard"
        />
      )
    case "visa":
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/visa.png"
          alt="visa"
        />
      )

    default:
      return (
        <img
          style={style ? style : {}}
          className="payment-method-logo"
          src="/assets/other.png"
          alt="other"
        />
      )
  }
}

export default PaymentMethodImage
