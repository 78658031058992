import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { getProjectLangsProgressions, getProjects } from "../services/projects"
import { AuthContext } from "./Auth"

type TranslationEditorContextType = {
  projectsIsLoaded: boolean
  projects: any[] | undefined

  refreshProjects: () => void
  setPreventDoubleSubmit: Dispatch<SetStateAction<boolean>>
  preventDoubleSubmit: boolean
  setActionRef: Dispatch<SetStateAction<string | null>>
  actionRef: string | null

  setSelectedProject: Dispatch<SetStateAction<any | null>>
  selectedProject: any
  selectedProjectFiles: any[] | undefined

  setSelectedFile: Dispatch<SetStateAction<any | null>>
  selectedFile: any | undefined

  setModalProjectIsShown: Dispatch<SetStateAction<boolean>>
  modalProjectIsShown: boolean

  setModalProjectLangIsShown: Dispatch<SetStateAction<boolean>>
  modalProjectLangIsShown: boolean

  setSelectedLangs: Dispatch<SetStateAction<any[] | undefined>>
  selectedLangs: any[] | undefined
  selectedOutputProjectLangs: any[] | undefined
  selectedInputProjectLangs: any[] | undefined
  projectLangProgressions: any[] | undefined

  incrementProjectLangProgressions: ({
    lang,
    status,
    fileId,
    isNew,
  }: {
    lang: string
    status: string
    fileId: number
    isNew?: boolean
  }) => void

  addSelectedLangs: (lang: string) => void
  removeSelectedLangs: (lang: string) => void
  toggleSelectedLangs: (lang: string) => void
}

export const TranslationEditorContext =
  React.createContext<TranslationEditorContextType>({
    projectsIsLoaded: false,
    projects: undefined,
    refreshProjects: () => {},

    setPreventDoubleSubmit: () => {},
    preventDoubleSubmit: false,
    setActionRef: () => {},
    actionRef: null,

    setSelectedProject: () => {},
    selectedProject: undefined,
    selectedProjectFiles: undefined,

    setSelectedFile: () => {},
    selectedFile: undefined,

    setModalProjectIsShown: () => {},
    modalProjectIsShown: false,

    setModalProjectLangIsShown: () => {},
    modalProjectLangIsShown: false,

    setSelectedLangs: () => {},
    selectedLangs: undefined,
    selectedOutputProjectLangs: undefined,
    selectedInputProjectLangs: undefined,
    projectLangProgressions: undefined,

    incrementProjectLangProgressions: () => {},

    addSelectedLangs: (lang: string) => {},
    removeSelectedLangs: (lang: string) => {},
    toggleSelectedLangs: (lang: string) => {},
  })

type Props = {
  children: React.ReactNode
}

export function TranslationEditorProvider({ children }: Props) {
  const { loadingFinished, currentUser } = useContext(AuthContext)
  const [selectedProjectFiles, setSelectedProjectFiles] = useState<
    any[] | undefined
  >(undefined)
  const [selectedProject, setSelectedProject] = useState<any>()
  const [selectedFile, setSelectedFile] = useState<any>()
  const [selectedLangs, setSelectedLangs] = useState<any>(undefined)

  const [selectedInputProjectLangs, setSelectedInputProjectLangs] =
    useState<any[]>()
  const [selectedOutputProjectLangs, setSelectedOutputProjectLangs] =
    useState<any[]>()

  const [projectsIsLoaded, setProjectsIsLoaded] = useState<boolean>(false)
  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState<boolean>(false)
  const [actionRef, setActionRef] = useState<string | null>(null)

  const [projects, setProjects] = useState<any[]>()

  const [modalProjectIsShown, setModalProjectIsShown] = useState(false)
  const [modalProjectLangIsShown, setModalProjectLangIsShown] = useState(false)

  const reloadProjects = async () => {
    return await getProjects()
  }

  const refreshProjects = () => {
    reloadProjects()
      .then((result: any) => {
        setProjectsIsLoaded(true)
        setProjects(result.data.rows)
        setPreventDoubleSubmit(false)

        if (selectedProject) {
          setSelectedProject(
            result.data.rows.find(
              (i: any) => i.id_project === selectedProject.id_project,
            ),
          )
        }
      })
      .catch((err) => {
        setProjectsIsLoaded(true)
        setProjects(undefined)
        setSelectedProjectFiles(undefined)
        setSelectedOutputProjectLangs(undefined)
        setSelectedInputProjectLangs(undefined)
        setPreventDoubleSubmit(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (loadingFinished && currentUser && projects === undefined) {
      reloadProjects()
        .then((result: any) => {
          setProjectsIsLoaded(true)
          setProjects(result.data.rows)
          setPreventDoubleSubmit(false)
        })
        .catch((err) => {
          setProjectsIsLoaded(true)
          setProjects(undefined)
          setSelectedProjectFiles(undefined)
          setSelectedOutputProjectLangs(undefined)
          setSelectedInputProjectLangs(undefined)
          setPreventDoubleSubmit(false)
          console.log(err)
        })
    }
  }, [loadingFinished, projects, currentUser])

  useEffect(() => {
    if (!currentUser) {
      setProjectsIsLoaded(false)
      setProjects(undefined)
      setSelectedProjectFiles(undefined)
      setSelectedOutputProjectLangs(undefined)
      setSelectedInputProjectLangs(undefined)
    }
  }, [currentUser])

  const reloadProjectLangsProgressions = async () => {
    return await getProjectLangsProgressions({
      projectId: selectedProject.id_project,
    })
  }

  const [projectLangProgressions, setProjectLangProgressions] = useState<any>(
    [],
  )

  const bindProjectSubItem = () => {
    setSelectedFile(undefined)
    setSelectedLangs(undefined)
    setProjectLangProgressions(undefined)

    if (selectedProject?.id_project) {
      reloadProjectLangsProgressions().then((res: any) => {
        setProjectLangProgressions(res.data)
      })
    }

    if (selectedProject?.langs) {
      setSelectedOutputProjectLangs(
        selectedProject.langs.filter((i: any) => !i.is_source_lang),
      )
      setSelectedInputProjectLangs(
        selectedProject.langs.filter((i: any) => i.is_source_lang),
      )
    } else {
      setSelectedOutputProjectLangs(undefined)
      setSelectedInputProjectLangs(undefined)
    }

    if (selectedProject?.files) {
      setSelectedProjectFiles(selectedProject.files)
    } else {
      setSelectedProjectFiles(undefined)
    }
  }

  useEffect(() => {
    bindProjectSubItem()
  }, [selectedProject])

  useEffect(() => {
    if (loadingFinished && currentUser && selectedLangs && selectedProject) {
      const savedLang = {
        project_id: selectedProject.id_project,
        selectedLangs,
      }

      const localLangs = localStorage.getItem("selectedLangs")
      if (localLangs && JSON.parse(localLangs)?.length > 0) {
        const langArray = JSON.parse(localLangs)
        if (
          langArray &&
          langArray.find(
            (i: any) => i.project_id === selectedProject.id_project,
          )
        ) {
          let result = [
            ...langArray.filter(
              (i: any) => i.project_id !== selectedProject.id_project,
            ),
            savedLang,
          ]
          localStorage.setItem("selectedLangs", JSON.stringify(result))
        } else {
          langArray.push(savedLang)
          console.log(langArray)
          localStorage.setItem("selectedLangs", JSON.stringify(langArray))
        }
      } else {
        console.log([savedLang])
        localStorage.setItem("selectedLangs", JSON.stringify([savedLang]))
      }
    }

    if (loadingFinished && selectedLangs && !currentUser) {
      localStorage.removeItem("selectedLangs")
    }
  }, [selectedLangs, currentUser, loadingFinished, selectedProject])

  useEffect(() => {
    const savedLang = localStorage.getItem("selectedLangs")
    if (savedLang && !selectedLangs && selectedProject) {
      const projectLangs = JSON.parse(savedLang).find(
        (i: any) => i.project_id === selectedProject.id_project,
      )
      console.log(projectLangs)
      if (projectLangs) {
        setSelectedLangs(projectLangs.selectedLangs)
      }
    }
  }, [selectedLangs, selectedOutputProjectLangs, selectedProject])

  const addSelectedLangs = (lang: string) => {
    const langCopy = selectedLangs ? [...selectedLangs] : []
    const existingLang = langCopy.find((i) => i.lang === lang)

    if (!existingLang) {
      const selectedLang = selectedProject.langs.find(
        (i: any) => i.lang === lang,
      )
      setSelectedLangs([...langCopy, selectedLang])
    }
  }

  const removeSelectedLangs = (lang: string) => {
    const langCopy = selectedLangs ? [...selectedLangs] : []
    const existingLang = langCopy.find((i) => i.lang === lang)

    if (existingLang) {
      setSelectedLangs(langCopy.filter((i) => i.lang !== lang))
    }
  }

  const toggleSelectedLangs = (lang: string) => {
    const langCopy = selectedLangs ? [...selectedLangs] : []
    const existingLang = langCopy.find((i) => i.lang === lang)

    if (existingLang) {
      removeSelectedLangs(lang)
    } else {
      addSelectedLangs(lang)
    }
  }

  const incrementProjectLangProgressions = ({
    lang,
    status,
    fileId,
    isNew = false,
  }: {
    lang: string
    status: string
    fileId: number
    isNew?: boolean
  }) => {
    const result = projectLangProgressions.filter((i: any) => i.lang !== lang)
    let changed_item = projectLangProgressions.find((i: any) => i.lang === lang)
    if (!changed_item) {
      changed_item = {
        lang: lang,
        valid: 0,
        ai_generated: 0,
        source_modified: 0,
        files: selectedProjectFiles?.map((i) => {
          return {
            file_id: i.id_file,
            valid: 0,
            ai_generated: 0,
            source_modified: 0,
          }
        }),
      }
    }
    if (status === "valid") {
      changed_item.valid += 1
      if (!isNew) changed_item.ai_generated = changed_item.ai_generated - 1
    }
    if (status === "ai_generated") {
      changed_item.ai_generated += 1

      if (!isNew) changed_item.valid = changed_item.valid - 1
    }
    const result_file = changed_item.files.filter(
      (i: any) => i.file_id !== fileId,
    )
    let changed_file_item = changed_item.files.find(
      (i: any) => i.file_id === fileId,
    )
    if (status === "valid") {
      changed_file_item.valid += 1
      if (!isNew)
        changed_file_item.ai_generated = changed_file_item.ai_generated - 1
    }
    if (status === "ai_generated") {
      changed_file_item.ai_generated += 1
      if (!isNew) changed_file_item.valid = changed_file_item.valid - 1
    }
    changed_item.files = [changed_file_item, ...result_file]

    setProjectLangProgressions([changed_item, ...result])
  }

  return (
    <TranslationEditorContext.Provider
      value={{
        projectsIsLoaded,
        projects,
        refreshProjects,
        preventDoubleSubmit,
        setPreventDoubleSubmit,
        setActionRef,
        actionRef,

        setSelectedProject,
        selectedProject,
        selectedProjectFiles,

        setSelectedFile,
        selectedFile,

        setModalProjectIsShown,
        modalProjectIsShown,

        setModalProjectLangIsShown,
        modalProjectLangIsShown,

        setSelectedLangs,
        selectedLangs,
        selectedOutputProjectLangs,
        selectedInputProjectLangs,
        projectLangProgressions,

        incrementProjectLangProgressions,

        addSelectedLangs,
        removeSelectedLangs,
        toggleSelectedLangs,
      }}
    >
      {children}
    </TranslationEditorContext.Provider>
  )
}
