import { CSSProperties, useContext, useRef } from "react"
import Dropdown from "./Dropdown"
import { TranslationEditorContext } from "../../context/TranslationEditor"
import { useTranslation } from "react-i18next"

type Props = {
  className?: string
  customMenuStyle?: CSSProperties
  iconStyle?: CSSProperties
  rightMenu?: boolean
}

export const DropdownProjectLangs = ({
  className,
  customMenuStyle,
  iconStyle,
  rightMenu,
}: Props) => {
  const { t } = useTranslation()

  const {
    selectedLangs,
    selectedProject,
    selectedInputProjectLangs,
    selectedOutputProjectLangs,
    toggleSelectedLangs,
  } = useContext(TranslationEditorContext)

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  return (
    <Dropdown
      disabled={!selectedProject}
      customMenuStyle={customMenuStyle}
      iconStyle={iconStyle}
      className={className}
      title={
        (selectedLangs?.length || 0) > 0 ? (
          <div className="flex-row">
            <b style={{ marginRight: "0.8rem" }} className="lang-badge">
              {selectedLangs?.length}
            </b>
            <div>{t("translationEditor.langs_lower")}</div>
          </div>
        ) : (
          t("commons.select")
        )
      }
      rightMenu={rightMenu}
    >
      {selectedOutputProjectLangs?.map((item) => (
        <div
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => {
            if (
              !(
                selectedLangs &&
                selectedLangs?.length >= 5 &&
                !selectedLangs?.map((i) => i.lang).includes(item.lang)
              )
            ) {
              toggleSelectedLangs(item.lang)
            }
          }}
          className="dropdown-item"
        >
          <div
            className={`dropdown-link flex-row ${
              selectedLangs?.map((i) => i.lang).includes(item.lang)
                ? "selected"
                : ""
            } ${
              selectedLangs &&
              selectedLangs?.length >= 5 &&
              !selectedLangs?.map((i) => i.lang).includes(item.lang)
                ? "disabled"
                : ""
            }`}
          >
            <div
              className={getFlagClass(item.lang)}
              style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
            ></div>
            <span
              className="text-ellipsis flex-1 "
              style={{ marginLeft: "0.5rem" }}
            >
              {item.lang}
            </span>
          </div>
        </div>
      ))}
    </Dropdown>
  )
}

export default DropdownProjectLangs
