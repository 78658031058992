import { useTranslation } from "react-i18next"
import { Link, NavLink } from "react-router-dom"
import TextLink from "../components/TextLink"

type Props = {
  scrollToTop?: () => void
  scrollToFeatures?: () => void
  scrollToPricing?: () => void
  hideLinks?: boolean
}

export const TopBar = ({
  scrollToTop,
  scrollToFeatures,
  scrollToPricing,
  hideLinks = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="top-bar">
      <NavLink
        to="/"
        className="navbar-logo"
        style={{ width: "unset" }}
        onClick={() => {
          if (scrollToTop) scrollToTop()
        }}
      >
        <ul className={"navbar-nav "}>
          <span className="link-text flex-row flex-center">
            <img
              src="/logo512.png"
              alt=""
              style={{ width: 40, height: 40, marginRight: "0.75rem" }}
            />
              <h1 className="text-center">
                Champo
                <span className="primary-gradient-red-text font-bold">AI</span>
              </h1>
            {/* <div className="soon-div" style={{ fontSize: 16, marginRight: 0 }}>
              beta
            </div> */}
          </span>
        </ul>
      </NavLink>

      {!hideLinks && (
        <div className="nav-container">
          <div>
            <TextLink
              className="hide-tablet"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                if (scrollToFeatures) scrollToFeatures()
              }}
            >
              {t("landingPage.features")}
            </TextLink>
            <TextLink
              className="hide-tablet"
              onClick={() => {
                if (scrollToPricing) scrollToPricing()
              }}
            >
              {t("landingPage.pricing")}
            </TextLink>
            <TextLink
              className="hide-tablet"
              to="https://doc.champo.ai/getting-started"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("landingPage.documentation")}
            </TextLink>
            {/* <TextLink
              className="hide-tablet"
              to="https://github.com/champo-ai/champo-ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </TextLink> */}
            {/* <TextLink
              className="hide-tablet"
              to="https://www.npmjs.com/package/champo-ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              NPM
            </TextLink> */}

<Link
              className="button is-bordered"
              to="/auth"
              style={{ marginLeft: "1rem" }}
            >
              {t("landingPage.sign_in")}
            </Link>
            <Link
              className="button is-primary-gradient hide-phone"
              to="/register"
              style={{ marginLeft: "0.75rem" }}
            >
              {t("landingPage.try_for_free")}
            </Link>

          </div>
        </div>
      )}
    </div>
  )
}
