import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import SetupForm from "./SetupForm"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "")

type Props = {
  refreshResult: () => void
  isLoaded: () => void
}

const ModalSetupIntent = ({ refreshResult, isLoaded }: Props) => {
  return (
    <div style={{ minHeight: 365, maxHeight: 800 }}>
      <Elements
        stripe={stripePromise}
        options={{
          mode: "setup",
          currency: "eur",
          appearance: {
            theme: "flat",
            variables: {
              fontFamily: ' "Gill Sans", sans-serif',
              fontLineHeight: "1.5",
              borderRadius: "5px",
              colorBackground: "#F6F8FA",
              colorPrimaryText: "#262626",
              colorPrimary: "#6d28d9",
              colorIconTabHover: document.documentElement.className.includes(
                "theme--dark",
              )
                ? "#fff"
                : "#101016",
              colorIconTabSelected: "#2e1065",
              colorTextPlaceholder: document.documentElement.className.includes(
                "theme--dark",
              )
                ? "#2f2f2f"
                : "#cccccc",
            },
            rules: {
              ".Block": {
                backgroundColor: "var(--colorBackground)",
                boxShadow: "none",
                padding: "12px",
              },
              ".Input": {
                padding: "12px",
                color: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "#eee"
                  : "#101016",
                backgroundColor: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "rgb(10, 15, 20)"
                  : "rgb(240, 245, 250)",
                border: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "1px solid rgba(255, 255, 255, 0.05)"
                  : "1px solid rgba(0, 0, 0, 0.15)",
              },
              ".Input:disabled, .Input--invalid:disabled": {
                color: "lightgray",
              },
              ".Input:focus": {
                boxShadow: "0 0 0 1px #2e1065",
                border: "1px solid #2e1065",
              },
              ".Tab": {
                padding: "10px 12px 8px 12px",
                border: "none",
                backgroundColor: document.documentElement.className.includes("theme--dark")
                ? "rgb(10, 15, 20)"
                : "rgb(240, 245, 250)",
              },
              ".Tab:hover": {
                border: "none",
                color: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "#fff"
                  : "#101016",

                boxShadow:
                  "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(109, 40, 217, 0.04)",
              },
              ".Tab:hover .Icon": {
                fill: document.documentElement.className.includes("theme--dark")
                  ? "#fff"
                  : "#101016",
              },
              ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
                border: "none",
                backgroundColor: "rgba(109, 40, 217, 0.1333333333)",
                color: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "#fff"
                  : "#101016",
                boxShadow: "none",
              },
              ".TabLabel": {
                fontWeight: "bold",
              },
              ".Label": {
                fontWeight: "600",
                color: document.documentElement.className.includes(
                  "theme--dark",
                )
                  ? "rgba(255, 255, 255, 0.8)"
                  : "rgba(0, 0, 0, 0.54)",
              },
            },
          },
        }}
      >
        <SetupForm reloadCredits={refreshResult} />
      </Elements>
    </div>
  )
}

export default ModalSetupIntent
