import { CSSProperties, useContext } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../context/Auth"
import AmountLabeled from "./AmountLabeled"

export const AmountBar = ({
  itemPerLine,
  style,
}: {
  itemPerLine: number
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const {
    currentBalance,
    projectCount,
    langUsed,
    editorKeyCount,
    userAccountTier,
  } = useContext(AuthContext)

  return (
    <div
      className={"full-width amount-bar item-per-line-" + itemPerLine}
      style={{
        margin: 0,
        width: "100%",
        marginBottom: "1.5rem",
        ...style,
      }}
    >
      <AmountLabeled
        icon="token"
        iconColor="#eab308"
        label={t("credits.balance")}
        amount={currentBalance}
      />

      <AmountLabeled
        label={t("credits.used_editor_keys")}
        amount={editorKeyCount}
        icon="abc"
        iconColor="#e11d48"
        amountSlashed={
          userAccountTier === 4
            ? (200000).toLocaleString()
            : userAccountTier === 3
              ? (50000).toLocaleString()
              : userAccountTier === 2
                ? (25000).toLocaleString()
                : userAccountTier === 1
                  ? (5000).toLocaleString()
                  : userAccountTier === 0
                    ? (1000).toLocaleString()
                    : (1000).toLocaleString()
        }
      />

      <AmountLabeled
        className="hide-phone"
        label={t("translationEditor.projects")}
        amount={projectCount}
        icon="folder"
        iconColor="#0ea5e9"
        amountSlashed={
          userAccountTier === 4
            ? "100"
            : userAccountTier === 3
              ? "10"
              : userAccountTier === 2
                ? "5"
                : userAccountTier === 1
                  ? "3"
                  : userAccountTier === 0
                    ? "1"
                    : "1"
        }
      />

      <AmountLabeled
        className="hide-phone"
        label={t("usage.languages")}
        amount={langUsed}
        icon="translate"
        iconColor="#84cc16"
      />
    </div>
  )
}

export default AmountBar
