import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getUserSubscription = () => {
  return axios.get(`${BASE_URL}/subscription`, {
    withCredentials: true,
  })
}

export const cancelSubscription = (feedback: string) => {
  return axios.delete(`${BASE_URL}/cancel_subscription/${feedback}`, {
    withCredentials: true,
  })
}

export const reactivateSubscription = () => {
  return axios.get(`${BASE_URL}/reactivate_subscription`, {
    withCredentials: true,
  })
}

export const cancelDowngradeSubscription = () => {
  return axios.delete(`${BASE_URL}/cancel_downgrade_subscription`, {
    withCredentials: true,
  })
}

export const upgradeSubscription = (newTier: number) => {
  return axios.put(
    `${BASE_URL}/upgrade_subscription`,
    { newTier },
    {
      withCredentials: true,
    },
  )
}

export const downgradeSubscription = (newTier: number) => {
  return axios.put(
    `${BASE_URL}/downgrade_subscription`,
    { newTier },
    {
      withCredentials: true,
    },
  )
}
