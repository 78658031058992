import { Fragment, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { Link } from "react-router-dom"
import { AuthContext } from "../../context/Auth"
import { UIContext } from "../../context/UI"
import DropdownAccount from "../components/DropdownAccount"
import DropdownLanguage from "../components/DropdownLanguage"

export const Navbar = () => {
  const { t } = useTranslation()
  const { setAccountDropdownOpen, toggleDarkMode, isDark } =
    useContext(UIContext)

  const { logout, currentUser } = useContext(AuthContext)

  const postLogout = async () => {
    logout()
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Fragment>
      <div className={"navbar " + (isOpen ? "is-open" : "is-closed")}>
        <ul
          className="navbar-nav navbar-logo flex-row full-width"
          style={{ padding: "0 1.5rem", justifyContent: "flex-start" }}
        >
          <span className="link-text flex-row" style={{alignItems:"center"}}>
            <img
              src="/logo512.png"
              alt=""
              style={{ width: 36, height: 36, marginRight: "0.8rem" }}
            />
            <h1 className="text-center">
              Champo
              <span className="primary-gradient-red-text font-bold">AI</span>
            </h1>
          </span>
          {/* <div
            className="soon-div"
            style={{ fontSize: 16, marginTop: 7.5, marginRight: 0 }}
          >
            beta
          </div> */}
        </ul>
        <ul className="navbar-nav navbar-home">
          <hr />

          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/get-started"
              onClick={() => setIsOpen(false)}
            >
              <span className="msymbol material-symbols-outlined">flag</span>
              <span className="link-text">{t("getStarted.title")}</span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/editor"
              onClick={() => setIsOpen(false)}
            >
              <span className="msymbol material-symbols-outlined">
                file_copy
              </span>
              <span className="link-text">{t("translationEditor.title")}</span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/translate"
              onClick={() => setIsOpen(false)}
            >
              <span className="msymbol material-symbols-outlined">
                translate
              </span>
              <span className="link-text">{t("translate.title")}</span>
            </NavLink>
          </li>

          <hr />

          <li className="nav-item">
            <NavLink
              className={"nav-link"}
              to="/usage"
              onClick={() => setIsOpen(false)}
            >
              <span className="msymbol material-symbols-outlined">
                browse_activity
              </span>
              <span className="link-text">{t("usage.title")}</span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className={"nav-link"}
              to="/api-keys"
              onClick={() => setIsOpen(false)}
            >
              <span className="msymbol material-symbols-outlined">lock</span>
              <span className="link-text">{t("apiKeys.title")}</span>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className={"nav-link"}
              to="/billing"
              onClick={() => setIsOpen(false)}
            >
              <span className="msymbol material-symbols-outlined">
                payments
              </span>
              <span className="link-text">{t("credits.title")}</span>
            </NavLink>
          </li>
        </ul>

        <ul className="navbar-nav navbar-logout" style={{marginTop:"2rem"}}>
          <li className="nav-item">
            <Link
              className={"nav-link"}
              to="https://doc.champo.ai/getting-started"
              onClick={() => setIsOpen(false)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="msymbol material-symbols-outlined">book_2</span>
              <span className="link-text">{t("commons.documentation")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={"nav-link"}
              to="https://github.com/champo-ai/champo-ai"
              onClick={() => setIsOpen(false)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="msymbol material-symbols-outlined">code</span>
              <span className="link-text">{t("commons.github")}</span>
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav navbar-logout">
          <div
            className="nav-dropdown-container flex-row full-width"
            style={{ marginBottom: 0 }}
          >
            <button
              className="button is-bordered"
              style={{
                margin: 0,
                marginRight: "0.5rem",
                padding: "0.5rem 0.8rem",
              }}
              onClick={() => toggleDarkMode()}
            >
              <span className="msymbol material-symbols-outlined">
                {!isDark ? "dark_mode" : "light_mode"}
              </span>
            </button>
            <DropdownLanguage className="flex-1" />
          </div>
        </ul>
        <ul className="navbar-nav navbar-logout">
          <div className="nav-dropdown-container">
            <DropdownAccount
              className="full-width"
              title={currentUser.username}
            >
              <NavLink
                onMouseDown={(e) => e.preventDefault()}
                key={"profile"}
                onClick={() => {
                  setAccountDropdownOpen(null)
                  setIsOpen(false)
                }}
                to="/profile"
                className="nav-item"
              >
                <div
                  className="nav-link"
                  style={{
                    margin: "0 1rem 0.25rem 0.5rem",
                    width: "calc(100% - 1rem)",
                  }}
                >
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{ marginLeft: "-0.5rem" }}
                  >
                    account_circle
                  </span>
                  <span className="link-text">{t("auth.profile")}</span>
                </div>
              </NavLink>

              <div
                onMouseDown={(e) => e.preventDefault()}
                key={"logout"}
                onClick={() => {
                  setAccountDropdownOpen(null)
                  postLogout()
                  setIsOpen(false)
                }}
                className="dropdown-item"
              >
                <div className="dropdown-link logout">
                  <span
                    className="msymbol material-symbols-outlined danger"
                    style={{ marginRight: "1.15rem" }}
                  >
                    power_settings_new
                  </span>
                  <span className="link-text">{t("auth.logout")}</span>
                </div>
              </div>
            </DropdownAccount>
          </div>
        </ul>
      </div>

      <div className="bottombar flex-row">
        <div
          className="nav-item flex-column flex-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="msymbol material-symbols-outlined">menu</span>
          <span className="link-text">{t("commons.menu")}</span>
        </div>

        <NavLink
          className="nav-item flex-column flex-center"
          to="/usage"
          onClick={() => setIsOpen(false)}
        >
          <span className="msymbol material-symbols-outlined">
            browse_activity
          </span>
          <span className="link-text">{t("usage.title")}</span>
        </NavLink>

        <NavLink
          className="nav-item flex-column flex-center"
          to="/api-keys"
          onClick={() => setIsOpen(false)}
        >
          <span className="msymbol material-symbols-outlined">lock</span>
          <span className="link-text">{t("apiKeys.title")}</span>
        </NavLink>

        <NavLink
          className="nav-item flex-column flex-center"
          to="/billing"
          onClick={() => setIsOpen(false)}
        >
          <span className="msymbol material-symbols-outlined">payments</span>
          <span className="link-text">{t("credits.title")}</span>
        </NavLink>
      </div>

      {isOpen && (
        <div
          className="overlay-navbar hide-gt-phone"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </Fragment>
  )
}

export default Navbar
