const icuFormatMessage = `import { createIntl } from "@formatjs/intl"

const intl = createIntl({
  locale: "en",
  messages: {
    dessert: \`I would like to eat {what, select,
      cake {{count, plural,
        one {a cake}
        other {{count} cakes}
      }}
      muffin {{count, plural,
        one {a muffin}
        other {{count} muffins}
      }}
      other {something}
    }.\`,
  },
})
`

export default icuFormatMessage
