import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const postTranslate = ({
  inputData,
  inputLang,
  outputLang,
  modelUsed,
  parser,
}: {
  inputData: string
  inputLang: string
  outputLang: string
  modelUsed: string
  parser: string
}) => {
  return axios.post(
    `${BASE_URL}/translate`,
    {
      inputData,
      inputLang,
      outputLang,
      filename: "from web",
      modelUsed,
      parser,
    },
    {
      withCredentials: true,
    },
  )
}

export const postTranslateChunk = ({
  inputData,
  inputLang,
  outputLang,
  filename,
  chunkIndex,
  totalChunks,
}: {
  inputData: string
  inputLang: string
  outputLang: string
  filename: string
  chunkIndex: number
  totalChunks: number
}) => {
  return axios.post(
    `${BASE_URL}/translate_chunk`,
    {
      inputData,
      inputLang,
      outputLang,
      filename,
      chunkIndex,
      totalChunks,
    },
    { withCredentials: true },
  )
}
