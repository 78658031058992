import React, { useContext, useRef } from "react"
import { UIContext } from "../../context/UI"

type Props = {
  title: string
  className?: string
  selected?: boolean
  rightMenu?: boolean
  hideIcon?: boolean
  children: React.ReactNode
}

export const DropdownAccount = ({
  children,
  title,
  className,
  selected,
  rightMenu,
  hideIcon,
}: Props) => {
  const { accountDropdownOpen, setAccountDropdownOpen } = useContext(UIContext)
  const currentId = useRef("id" + Math.random().toString(16).slice(2))
  return (
    <div
      className={
        "dropdown " +
        (accountDropdownOpen === currentId.current ? "is-active " : "") +
        className
      }
    >
      <div className="dropdown-trigger full-width">
        <button
          className="button dropdown-button dropdown-account-button full-width"
          aria-controls="dropdown-menu"
          onClick={() => {
            if (accountDropdownOpen) {
              setAccountDropdownOpen(null)
            } else {
              setAccountDropdownOpen(currentId.current)
            }
          }}
          onBlur={() => {
            setAccountDropdownOpen(null)
          }}
        >
          <div className="dropdown-account-circle">{title[0]}</div>
          <span
            className={"text-ellipsis flex-1 " + (selected ? "font-bold" : "")}
          >
            {title}
          </span>

          <span className="icon is-small" style={{ marginRight: "-0.25rem" }}>
            {accountDropdownOpen === currentId.current ? (
              <span className="msymbol material-symbols-outlined">
                keyboard_arrow_down
              </span>
            ) : (
              <span className="msymbol material-symbols-outlined">
                keyboard_arrow_up
              </span>
            )}
          </span>
        </button>
      </div>

      <div
        className="dropdown-menu dropdown-account-menu"
        id="dropdown-menu"
        role="menu"
        style={rightMenu ? { top: -105 } : { top: -105 }}
      >
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  )
}

export default DropdownAccount
