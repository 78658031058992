import { useContext, useEffect } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { TranslationEditorContext } from "../../../context/TranslationEditor"

export const Project = () => {
  const navigate = useNavigate()
  const {
    projects,
    projectsIsLoaded,
    selectedProject,
    setSelectedProject,
    setSelectedLangs,
  } = useContext(TranslationEditorContext)

  let { project_id } = useParams()
  useEffect(() => {
    if (
      projectsIsLoaded &&
      projects &&
      (!selectedProject ||
        (selectedProject && selectedProject.id_project !== project_id)) &&
      project_id
    ) {
      const paramsProject = projects.find(
        (i) => i.id_project === Number(project_id),
      )
      if (paramsProject) {
        setSelectedLangs(undefined)
        setSelectedProject(paramsProject)
      } else {
        navigate("/editor/projects")
      }
    }
  }, [
    projectsIsLoaded,
    projects,
    selectedProject,
    project_id,
    setSelectedProject,
    setSelectedLangs,
    navigate,
  ])

  return <Outlet />
}
