import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Link } from "react-router-dom"
import { sendPasswordToken } from "../../services/auth"
import posthog from "posthog-js"
import ReactGA from "react-ga4"

export const ForgotPassword = () => {
  const { t } = useTranslation()

  const [mail, setMail] = useState("")
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  useEffect(() => {
    document.title = t("auth.forgot_password")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")

      ReactGA.send({
        hitType: "pageview",
        page: "/forgot-password",
        title: "Forgot Password",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const postSendToken = async () => {
    setIsError(false)
    setIsSuccess(false)

    if (mail.length < 5) {
      setIsError(true)
    } else {
      try {
        await sendPasswordToken({ mail })
        setIsError(false)
        setMail("")
        setIsSuccess(true)
      } catch (error) {
        setIsError(true)
      }
    }
  }

  const handleMail = (event: any) => {
    setMail(event.target.value)
  }

  return (
    <div className="basic-container auth-container">
      <div className="login-title">
        <div className="logo-text">
          <h1 className="font-bold">{t("auth.forgot_password")}</h1>
        </div>
      </div>
      <div className="flex-column white-box login-box">
        {!isSuccess && (
          <form
            onSubmit={(event) => {
              event.preventDefault()
              postSendToken()
            }}
          >
            <div className="field">
              <label className="label text">{t("auth.mail")}</label>

              <div className="control has-icons-left">
                <input
                  className={`full-width input ${isError ? "is-danger" : ""}`}
                  type="text"
                  placeholder={t("auth.mail")}
                  value={mail}
                  onChange={handleMail}
                />
                <span className="icon is-small is-left">
                  <span className="msymbol material-symbols-outlined">
                    email
                  </span>
                </span>
              </div>
            </div>

            <button
              className="button is-primary-gradient login-button"
              type="submit"
              disabled={mail.length < 5}
            >
              {t("commons.confirm")}
            </button>

            {isError && (
              <p className="help is-danger">{t("auth.register_error")}</p>
            )}
          </form>
        )}
        {isSuccess && (
          <>
            <h2 style={{ fontSize: "1.25rem" }}>
              {t("auth.success_forgot_password")}
            </h2>
          </>
        )}
      </div>

      <div className="flex-row flex-center text-center">
        <Link
          className="auth-link"
          to="/auth"
          style={{ marginRight: "0.5rem" }}
        >
          {t("auth.login")}
        </Link>
        <Link
          className="auth-link"
          to="/register"
          style={{ marginLeft: "0.5rem" }}
        >
          {t("auth.register")}
        </Link>
      </div>
    </div>
  )
}

export default ForgotPassword
