import React, { Dispatch, useState } from "react"

type TranslationContextType = {
  languages: string[][]
  //   usedLang: string | null
  //   setDropdownOpen: Dispatch<string | null>
}

export const TranslationContext = React.createContext<TranslationContextType>({
  languages: [],
  //   usedLang: null,
  //   setDropdownOpen: () => {},
})

type Props = {
  children: React.ReactNode
}

export function TranslationProvider({ children }: Props) {
  //   const [dropdownOpen, setDropdownOpen] = useState<string | null>(null)
  //   const [accountDropdownOpen, setAccountDropdownOpen] = useState<string | null>(
  //     null,
  //   )
  const languages = [
    ["ar", "🇦🇪", "ar-AE"],
    ["af", "🇿🇦", "af-ZA"],
    ["bg", "🇧🇬", "bg-BG"],
    ["cs", "🇨🇿", "cs-CZ"],
    ["da", "🇩🇰", "da-DK"],
    ["de", "🇩🇪", "de-DE"],
    ["el", "🇬🇷", "el-GR"],
    ["en", "🇺🇸", "en-US"],
    ["es", "🇪🇸", "es-ES"],
    ["et", "🇪🇪", "et-EE"],
    ["fi", "🇫🇮", "fi-FI"],
    ["fr", "🇫🇷", "fr-FR"],
    ["he", "🇮🇱", "he-IL"],
    ["hr", "🇭🇷", "hr-HR"],
    ["hr", "🇭🇺", "hu-HU"],
    ["hy", "🇦🇲", "hy-AM"],
    ["id", "🇮🇩", "id-ID"],
    ["is", "🇮🇸", "is-IS"],
    ["it", "🇮🇹", "it-IT"],
    ["ja", "🇯🇵", "ja-JP"],
    ["ko", "🇰🇷", "ko-KR"],
    ["lb", "🇱🇺", "lb-LU"],
    ["nb", "🇳🇴", "nb-NO"],
    ["nl", "🇳🇱", "nl-NL"],
    ["br", "🇧🇷", "pt-BR"],
    ["pt", "🇵🇹", "pt-PT"],
    ["ro", "🇷🇴", "ro-RO"],
    ["ru", "🇷🇺", "ru-RU"],
    ["sk", "🇸🇰", "sk-SK"],
    ["sl", "🇸🇮", "sl-SI"],
    ["sq", "🇦🇱", "sq-AL"],
    ["sv", "🇸🇪", "sv-SE"],
    ["th", "🇹🇭", "th-TH"],
    ["tr", "🇹🇷", "tr-TR"],
    ["uk", "🇺🇦", "uk-UA"],
    ["vi", "🇻🇳", "vi-VN"],
    ["zh", "🇨🇳", "zh-CN"],
  ]

  return (
    <TranslationContext.Provider
      value={{
        languages,
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}
