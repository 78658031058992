import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getCredits = () => {
  return axios.get(`${BASE_URL}/credits`, {
    withCredentials: true,
  })
}

export const postGetFreeCredits = () => {
  return axios.post(
    `${BASE_URL}/get_free_credits`,
    {},
    {
      withCredentials: true,
    }
  )
}

export const createPaymentIntent = ({ amount }: { amount: number }) => {
  return axios.post(
    `${BASE_URL}/create-payment-intent`,
    { amount },
    {
      withCredentials: true,
    }
  )
}

export const updatePaymentIntent = ({
  pi_id,
  amount,
}: {
  pi_id: any
  amount: number
}) => {
  return axios.post(
    `${BASE_URL}/update-payment-intent`,
    {
      pi_id,
      amount,
    },
    {
      withCredentials: true,
    }
  )
}

export const confirmPaymentIntent = ({ pi_id }: { pi_id: any }) => {
  return axios.post(
    `${BASE_URL}/confirm-payment-intent`,
    { pi_id },
    {
      withCredentials: true,
    }
  )
}

export const createSubscription = ({ priceId }: { priceId: string }) => {
  return axios.post(
    `${BASE_URL}/create-subscription`,
    { priceId },
    {
      withCredentials: true,
    }
  )
}

export const confirmSubscription = ({ pi_id }: { pi_id: any }) => {
  return axios.post(
    `${BASE_URL}/confirm-subscription`,
    { pi_id },
    {
      withCredentials: true,
    }
  )
}

export const createSetupIntent = () => {
  return axios.post(
    `${BASE_URL}/create-setup-intent`,
    {},
    {
      withCredentials: true,
    }
  )
}

export const confirmSetupIntent = ({ si_id }: { si_id: any }) => {
  return axios.post(
    `${BASE_URL}/confirm-setup-intent`,
    { si_id },
    {
      withCredentials: true,
    }
  )
}
