import { javascript } from "@codemirror/lang-javascript"
import ReactCodeMirror from "@uiw/react-codemirror"
import { flattie } from "flattie"
import { getEncoding } from "js-tiktoken"
import { useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { TranslationEditorContext } from "../../../context/TranslationEditor"
import { getDownloadFileByLang, postImportFile } from "../../../services/files"
import Dropdown from "../../components/Dropdown"
import Modal from "../../components/Modal"
import ModalLimitReached from "../../components/ModalLimitReached"
import DropdownProjectLangs from "../../components/DropdownProjectLangs"

const encoder = getEncoding("o200k_base")

export const ImportFile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const { project_id, file_id, lang } = useParams()

  const {
    refreshProjects,
    projectsIsLoaded,
    selectedProject,
    selectedInputProjectLangs,
    selectedOutputProjectLangs,
    selectedProjectFiles,
    selectedFile,
    setSelectedFile,
    selectedLangs,
    setSelectedLangs,
    setPreventDoubleSubmit,
    preventDoubleSubmit,
  } = useContext(TranslationEditorContext)

  const [currentSection, setCurrentSection] = useState<string>()

  const [inputCode, setInputCode] = useState("")

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isSucceed, setIsSucceed] = useState<boolean>(false)
  const [tokenLength, setTokenLength] = useState<number>(0)
  const [wordCount, setWordCount] = useState<number>(0)
  const [fileKeyCount, setFileKeyCount] = useState<number | undefined>(
    undefined,
  )

  const [erroredKeys, setErroredKeys] = useState<string[]>([])

  const [modalLimitIsShown, setModalLimitIsShown] = useState<boolean>(false)

  const [filename, setFilename] = useState("")

  useEffect(() => {
    if (projectsIsLoaded) {
      if (
        project_id &&
        pathname === `/editor/project/${project_id}/file/import`
      ) {
        setCurrentSection("select_file_type")

        if (selectedProjectFiles?.length === 0) {
          navigate(`/editor/project/${project_id}/file/import-new-file`)
        }
      }
      if (
        project_id &&
        pathname === `/editor/project/${project_id}/file/import-new-file`
      ) {
        setCurrentSection("import_new_file")
        if (selectedInputProjectLangs && selectedInputProjectLangs[0]) {
          setSelectedLangs([selectedInputProjectLangs[0]])
        }
      }

      if (
        project_id &&
        file_id &&
        pathname === `/editor/project/${project_id}/file/${file_id}/sync`
      ) {
        setCurrentSection("sync_source_file")
        if (selectedInputProjectLangs && selectedInputProjectLangs[0]) {
          setSelectedLangs([selectedInputProjectLangs[0]])
        }
      }

      if (
        project_id &&
        file_id &&
        lang &&
        pathname ===
          `/editor/project/${project_id}/file/${file_id}/lang/${lang}/sync`
      ) {
        setCurrentSection("import_existing_translation")
        if (selectedOutputProjectLangs && lang !== "null") {
          setSelectedLangs([
            selectedOutputProjectLangs.find((l) => l.lang === lang),
          ])
        }
      }
    }
  }, [
    projectsIsLoaded,
    selectedProjectFiles,
    selectedInputProjectLangs,
    selectedOutputProjectLangs,
    project_id,
    pathname,
    file_id,
    lang,
    navigate,
    setSelectedLangs,
  ])

  useEffect(() => {
    if (selectedProjectFiles && file_id && !isLoaded) {
      if (file_id !== "null") {
        const fileFromParams = selectedProjectFiles.find(
          (f) => f.id_file === Number(file_id),
        )
        if (fileFromParams && fileFromParams.id_file) {
          setSelectedFile(fileFromParams)
          setFilename(fileFromParams.name)
          // if (
          //   selectedInputProjectLangs &&
          //   selectedInputProjectLangs[0] &&
          //   !lang
          // ) {
          //   getFileData(selectedInputProjectLangs[0].lang)
          //   setIsLoaded(true)
          // }
          // if (lang) {
          //   getFileData(lang)
          //   setIsLoaded(true)
          // }
        }
      }
    }
  }, [selectedProjectFiles, file_id])

  const handleFilename = (event: any) => {
    setFilename(event.target.value)
  }

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  const getFileData = async (lang: string) => {
    if (file_id && lang) {
      const result = await getDownloadFileByLang({
        fileId: Number(file_id),
        lang: lang,
      })
      const json = JSON.stringify(result.data, null, 2)

      onChange(json)
    }
  }

  const onChange = useCallback((value: any) => {
    setIsSucceed(false)
    setPreventDoubleSubmit(false)
    setErroredKeys([])
    // const token = encode(value)
    const token = encoder.encode(value)

    setTokenLength(token?.length || 0)
    try {
      const inputValue = flattie(JSON.parse(value))
      setFileKeyCount(Object.entries(inputValue).length)
    } catch (error) {
      console.log(error)
      setFileKeyCount(undefined)
    }
    setInputCode(value)
  }, [])

  const importFile = async (lang: string) => {
    setIsSucceed(false)

    let inputValue = inputCode
    if (
      selectedInputProjectLangs &&
      lang &&
      filename &&
      selectedInputProjectLangs &&
      selectedInputProjectLangs[0]
    ) {
      try {
        inputValue = flattie(JSON.parse(inputCode))
        setFileKeyCount(Object.entries(inputValue).length)

        const newkeys = Object.entries(inputValue).map(([key, value]) => {
          return {
            name: key,
            lang: lang,
            value: value,
          }
        })

        let chunks = []
        let indexChunk = 0
        for (let i = 0; i < newkeys.length; i += 50) {
          chunks.push(newkeys.slice(indexChunk * 50, (indexChunk + 1) * 50))
          indexChunk += 1
        }

        for (let chunk of chunks) {
          await postImportFile({
            lang: lang, // temp
            projectName: selectedProject.name,
            is_source_lang: lang === selectedInputProjectLangs[0].lang,
            filename: filename,
            newkeys: chunk,
          })
        }

        setIsSucceed(true)
        refreshProjects()
        setInputCode("")
      } catch (error: any) {
        if (error?.response?.data?.message === "String limit is reached") {
          setModalLimitIsShown(true)
        }

        if (
          error?.response?.data?.message ===
          "Some keys are not set in source lang"
        ) {
          setErroredKeys(
            error?.response?.data?.errored_keys.map((key: any) => key.name),
          )
        }
        setIsSucceed(false)
      }
    }
  }

  return (
    <>
      <div
        className="basic-container editor-container"
        style={{
          maxWidth: "100%",
          overflow: "auto",
          flex: 1,
        }}
      >
        <div className="flex-row full-width">
          <Link
            className="button is-bordered"
            style={{ paddingLeft: 8, marginBottom: "1rem" }}
            to={`/editor/project/${selectedProject?.id_project}/files`}
          >
            <span
              className="msymbol material-symbols-outlined"
              style={{ marginRight: 8 }}
            >
              keyboard_arrow_left
            </span>
            {t("translationEditor.back_to_files")}
          </Link>
        </div>

        {currentSection === "select_file_type" && (
          <>
            <div className="flex-1 flex-column flex-center full-width">
              <h2
                className="sub-title"
                style={{
                  margin: 0,
                  marginBottom: "1.5rem",
                  textAlign: "center",
                }}
              >
                {t("translationEditor.select_file_type")}
              </h2>

              <div
                style={{
                  marginBottom: "5rem",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, 300px)",
                  width: "100%",
                  gap: "2rem",
                  justifyContent: "center",
                }}
              >
                <div
                  onClick={() => {
                    navigate(
                      `/editor/project/${selectedProject.id_project}/file/import-new-file`,
                    )
                  }}
                  className="add-grid-item bordered-div flex-column flex-center"
                >
                  <div className="plus-image">
                    <span className="msymbol material-symbols-outlined">
                      add
                    </span>
                  </div>
                  <h3 style={{ fontSize: 24, fontWeight: 700 }}>
                    {t("translationEditor.import_new_source_file")}
                  </h3>
                </div>
                <div
                  onClick={() => {
                    navigate(
                      `/editor/project/${selectedProject.id_project}/file/null/sync`,
                    )
                  }}
                  className="add-grid-item bordered-div flex-column flex-center"
                >
                  <div className="plus-image">
                    <span className="msymbol material-symbols-outlined">
                      sync
                    </span>
                  </div>
                  <h3 style={{ fontSize: 24, fontWeight: 700 }}>
                    {t("translationEditor.sync_existing_source_file")}
                  </h3>
                </div>
                {selectedOutputProjectLangs &&
                  selectedOutputProjectLangs.length > 0 && (
                    <div
                      onClick={() => {
                        navigate(
                          `/editor/project/${selectedProject.id_project}/file/null/lang/null/sync`,
                        )
                      }}
                      className="add-grid-item bordered-div flex-column flex-center"
                    >
                      <div className="plus-image">
                        <span className="msymbol material-symbols-outlined">
                          translate
                        </span>
                      </div>
                      <h3 style={{ fontSize: 24, fontWeight: 700 }}>
                        {t("translationEditor.import_existing_translation")}
                      </h3>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}

        {currentSection === "import_new_file" && (
          <>
            <h2
              className="sub-title"
              style={{
                margin: 0,
                marginBottom: "1.5rem",
              }}
            >
              {t("translationEditor.import_source_file")}
            </h2>

            <div className="full-width flex-row">
              <div
                className="control flex-1"
                style={{
                  marginBottom: "1rem",
                  maxWidth: "calc(50% - 0.5rem)",
                  marginRight: "0.5rem",
                }}
              >
                <input
                  className="full-width input"
                  type="text"
                  placeholder={t("usage.filename")}
                  value={filename}
                  onChange={handleFilename}
                />
              </div>

              <div
                className="flex-row flex-1"
                style={{
                  marginBottom: "1rem",
                  maxWidth: "calc(50% - 0.5rem)",
                  marginRight: "0.5rem",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {selectedInputProjectLangs && selectedInputProjectLangs[0] && (
                  <>
                    <p
                      className="text"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: "bold",
                        fontSize: 18,
                        lineHeight: "20px",
                        margin: 0,
                      }}
                    >
                      {selectedInputProjectLangs[0]?.lang}
                    </p>
                    <span
                      className={getFlagClass(
                        selectedInputProjectLangs[0]?.lang,
                      )}
                      style={{
                        marginLeft: "0.75rem",
                        fontSize: 25,
                        lineHeight: "25px",
                      }}
                    ></span>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {currentSection === "sync_source_file" && (
          <>
            <h2
              className="sub-title"
              style={{
                margin: 0,
                marginBottom: "1.5rem",
              }}
            >
              {t("translationEditor.sync_existing_source_file")}
            </h2>

            <div className="full-width flex-row">
              <div
                className="control flex-1"
                style={{
                  marginBottom: "1rem",
                  maxWidth: "calc(50% - 0.5rem)",
                  marginRight: "0.5rem",
                }}
              >
                <Dropdown
                  style={{ width: "100%" }}
                  buttonClassName="button is-bordered full-width"
                  title={selectedFile?.name ?? t("commons.select")}
                >
                  {selectedProjectFiles?.map((file) => (
                    <button
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (file.id_file !== Number(file_id)) {
                          setIsLoaded(false)
                          navigate(
                            `/editor/project/${selectedProject.id_project}/file/${file.id_file}/sync`,
                          )
                        }
                      }}
                      className="dropdown-item dropdown-option "
                    >
                      {file.name}
                    </button>
                  ))}
                </Dropdown>
              </div>

              <div
                className="flex-row flex-1"
                style={{
                  marginBottom: "1rem",
                  maxWidth: "calc(50% - 0.5rem)",
                  marginRight: "0.5rem",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {selectedInputProjectLangs && selectedInputProjectLangs[0] && (
                  <>
                    <p
                      className="text"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: "bold",
                        fontSize: 18,
                        lineHeight: "20px",
                        margin: 0,
                      }}
                    >
                      {selectedInputProjectLangs[0]?.lang}
                    </p>
                    <span
                      className={getFlagClass(
                        selectedInputProjectLangs[0]?.lang,
                      )}
                      style={{
                        marginLeft: "0.75rem",
                        fontSize: 25,
                        lineHeight: "25px",
                      }}
                    ></span>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {currentSection === "import_existing_translation" && (
          <>
            <h2
              className="sub-title"
              style={{
                margin: 0,
                marginBottom: "1.5rem",
              }}
            >
              {t("translationEditor.import_existing_translation")}
            </h2>

            <div className="full-width flex-row">
              <div
                className="control flex-1"
                style={{
                  marginBottom: "1rem",
                  maxWidth: "calc(50% - 0.5rem)",
                  marginRight: "0.5rem",
                }}
              >
                <Dropdown
                  style={{ width: "100%" }}
                  buttonClassName="button is-bordered full-width"
                  title={selectedFile?.name ?? t("commons.select")}
                >
                  {selectedProjectFiles?.map((file) => (
                    <button
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (file.id_file !== Number(file_id)) {
                          setIsLoaded(false)
                          navigate(
                            `/editor/project/${selectedProject.id_project}/file/${file.id_file}/lang/${lang}/sync`,
                          )
                        }
                      }}
                      className="dropdown-item dropdown-option "
                    >
                      {file.name}
                    </button>
                  ))}
                </Dropdown>
              </div>

              <div
                className="flex-row flex-1"
                style={{
                  marginBottom: "1rem",
                  maxWidth: "calc(50% - 0.5rem)",
                  marginLeft: "0.5rem",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <DropdownProjectLangs
                  customMenuStyle={{
                    maxHeight: "400px",
                    overflow: "auto",
                    right: 0,
                    left: "unset",
                  }}
                  rightMenu={true}
                />
              </div>
            </div>
          </>
        )}

        {currentSection !== "select_file_type" && (
          <>
            <ReactCodeMirror
              className="codemirror-container"
              height="100%"
              style={{
                flex: 1,
                width: "100%",
                marginBottom: "1rem",
                fontSize: "0.8rem",
              }}
              placeholder={t("translate.input_code_placeholder")}
              value={inputCode}
              theme="dark"
              extensions={[javascript({ jsx: true })]}
              onChange={onChange}
            />

            <div className="full-width flex-row">
              <div className="flex-1 flex-column">
                <p style={{ lineHeight: "20px", fontSize: 16 }}>
                  <b>{fileKeyCount || 0}</b> {t("translationEditor.keys_lower")}
                </p>

                {tokenLength > 0 && !fileKeyCount && (
                  <p
                    style={{ lineHeight: "20px", fontSize: 16 }}
                    className="danger-text"
                  >
                    Not parsed as JSON
                  </p>
                )}

                {erroredKeys.length > 0 && (
                  <p
                    style={{ lineHeight: "20px", fontSize: 16 }}
                    className="danger-text"
                  >
                    Some keys are not defined in source file:{" "}
                    {erroredKeys.map((k) => (
                      <>
                        <b>{k}</b>,{" "}
                      </>
                    ))}
                  </p>
                )}

                {tokenLength > 0 && isSucceed && (
                  <p
                    style={{ lineHeight: "20px", fontSize: 16 }}
                    className="success-text"
                  >
                    <b>Succeed</b>
                  </p>
                )}
              </div>

              <button
                className="button is-primary-gradient"
                style={{ marginBottom: "0.75rem" }}
                onClick={() => {
                  if (selectedInputProjectLangs && selectedInputProjectLangs[0])
                    importFile(selectedInputProjectLangs[0].lang)
                }}
                disabled={
                  preventDoubleSubmit ||
                  !selectedLangs ||
                  !selectedLangs[0] ||
                  !filename ||
                  (fileKeyCount || 0) === 0
                }
              >
                {t("translationEditor.import_keys", {
                  count: fileKeyCount || 0,
                })}
              </button>
            </div>
          </>
        )}
      </div>
      <Modal
        containerStyle={{ maxWidth: 800 }}
        isShown={modalLimitIsShown}
        closeModal={() => {
          setModalLimitIsShown(false)
        }}
      >
        <ModalLimitReached
          closeModal={() => setModalLimitIsShown(false)}
          limitType="source_keys"
        ></ModalLimitReached>
      </Modal>
    </>
  )
}

export default ImportFile
