const fluentJs = `import { FluentResource } from "@fluent/bundle"

const resource = new FluentResource(\`
dessert =
  I would like to eat 
  {$toEat ->
    [cake] {$count ->
      [one] a cake
     *[other] {$count} cakes
    }
    [muffin] {$count ->
      [one] a muffin
     *[other] {$count} muffins
    }
   *[other] something
  }.
\`)
`
export default fluentJs