import { useContext, useEffect } from "react"

import { useTranslation } from "react-i18next"
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { AuthContext } from "../../context/Auth"
import { PaymentContext } from "../../context/Payment"
import BillingHeader from "./Billing/BillingHeader"
import {
  confirmPaymentIntent,
  confirmSetupIntent,
  confirmSubscription,
} from "../../services/credits"
import Modal from "../components/Modal"
import AnimatedLoader from "../components/AnimatedLoader"

export const Billing = () => {
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const { setUserAccountTier, setCurrentBalance } = useContext(AuthContext)
  const {
    actionRef,
    paymentStatus,
    modalConfirmationPayment,
    preventDoubleSubmit,

    setPreventDoubleSubmit,
    setActionRef,
    setPaymentStatus,
    setModalConfirmationPayment,
    refreshCredits,
    refreshInvoices,
    refreshPaymentMethods,

    setSubscriptionStatus,
    setNextInvoiceDate,
    setStartInvoiceDate,
    setCredits,
    setCountCredits,

    reloadCredits,
  } = useContext(PaymentContext)

  useEffect(() => {
    reloadCredits()
      .then((result: any) => {
        setCredits(result[0].data.rows)
        setCountCredits(result[0].data.count)
        setCurrentBalance(
          result[0].data.rows.reduce(
            (acc: number, item: any) =>
              acc +
              (new Date(item.expiresAt) > new Date()
                ? Number(item.current_balance)
                : 0),
            0,
          ),
        )
        setUserAccountTier(result[1].data.account_tier)
        setSubscriptionStatus(result[1].data.status)
        setNextInvoiceDate(result[1].data.next_invoice_date)
        setStartInvoiceDate(result[1].data.start_invoice_date)
        if (pathname === "/billing") {
          if (
            result[0].data.rows.filter(
              (i: any) =>
                Number(i.total_credit) === 5000 && Number(i.payment_amount) === 0,
            )?.length === 0
          ) {
            navigate("/billing/free-credits")
          } else if (
            result[1].data.account_tier >= 1 &&
            result[1].data.status === "active"
          ) {
            navigate("/billing/buy-credits")
          } else {
            navigate("/billing/offers")
          }
        }
      })
      .catch((err: any) => {
        setCredits(null)
        setCountCredits(0)
        setUserAccountTier(0)
        setCurrentBalance(0)
        setSubscriptionStatus(null)
        setNextInvoiceDate(null)
        setStartInvoiceDate(null)
        console.log(err)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const confirmIntent = async () => {
      let paymentType = localStorage.getItem("paymentType")
      const paymentIntentId = searchParams.get("payment_intent")
      const redirectStatus = searchParams.get("redirect_status")
      if (
        paymentIntentId &&
        redirectStatus === "succeeded" &&
        paymentType === "subscription"
      ) {
        await confirmSubscription({ pi_id: paymentIntentId })
        localStorage.removeItem("paymentType")
        navigate("/billing/premium-account")
        await refreshCredits()
        await refreshInvoices()
        await refreshPaymentMethods()
        setPaymentStatus("succeeded")
      }
      if (
        paymentIntentId &&
        redirectStatus === "succeeded" &&
        paymentType === "buy_credit"
      ) {
        await confirmPaymentIntent({ pi_id: paymentIntentId })
        localStorage.removeItem("paymentType")
        navigate("/billing/buy-credits")
        await refreshCredits()
        await refreshInvoices()
        await refreshPaymentMethods()
        setPaymentStatus("succeeded")
      }

      const setUpIntentId = localStorage.getItem("setupIntentId")
      if (setUpIntentId && paymentType === "add_payment_method") {
        await confirmSetupIntent({ si_id: setUpIntentId })
        localStorage.removeItem("paymentType")
        localStorage.removeItem("setupIntentId")
        navigate("/billing/invoices")
        await refreshCredits()
        await refreshInvoices()
        await refreshPaymentMethods()
        setPaymentStatus("succeeded")
      }

      if (paymentIntentId && redirectStatus === "failed") {
        localStorage.removeItem("paymentType")
        navigate("/billing/invoices")
        setPaymentStatus("failed")
        setPreventDoubleSubmit(false)
      }
    }

    let paymentType = localStorage.getItem("paymentType")
    const redirectStatus = searchParams.get("redirect_status")
    const setUpIntentId = localStorage.getItem("setupIntentId")
    const paymentIntentId = searchParams.get("payment_intent")

    if (paymentType && (paymentIntentId || setUpIntentId) && redirectStatus) {
      setModalConfirmationPayment(true)
      setPreventDoubleSubmit(true)
      setActionRef("confirm_payment")

      confirmIntent().then(() => {
        reloadCredits()
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="champo-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined">
          payments
        </span>

        <div className="flex-column">
          <h1 className="champo-title">{t("credits.title")}</h1>
        </div>
      </div>

      <div className="basic-container credits">
        <BillingHeader />

        <Outlet />
      </div>

      <Modal
        isShown={modalConfirmationPayment}
        closeModal={() => {
          setModalConfirmationPayment(false)
        }}
      >
        <h1 className="modal-title">{t("credits.payment_confirmation")}</h1>

        {preventDoubleSubmit && <AnimatedLoader></AnimatedLoader>}
        {!preventDoubleSubmit && paymentStatus === "succeeded" && (
          <div
            className="fade-in-top"
            style={{ minHeight: 280, textAlign: "center" }}
          >
            <span
              className="msymbol material-symbols-outlined success-text"
              style={{ fontSize: 200 }}
            >
              check_circle
            </span>
            <h2>{t("credits.thanks_for_buying")}</h2>
          </div>
        )}

        {!preventDoubleSubmit && paymentStatus === "failed" && (
          <div
            className="fade-in-top"
            style={{ minHeight: 280, textAlign: "center" }}
          >
            <span
              className="msymbol material-symbols-outlined warn-text"
              style={{ fontSize: 200, opacity: 0.26 }}
            >
              highlight_off
            </span>
            <h2>{t("credits.payment_failed")}</h2>
          </div>
        )}

        <button
          className="button is-primary-gradient full-width"
          disabled={preventDoubleSubmit}
          style={{ marginBottom: "1rem" }}
          onClick={() => setModalConfirmationPayment(false)}
        >
          {actionRef === "changeOfferTier" ? (
            <AnimatedLoader smallNoContainer={true} />
          ) : (
            <>{t("commons.continue")}</>
          )}
        </button>
      </Modal>
    </>
  )
}

export default Billing
