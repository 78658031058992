import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"
import { AuthContext } from "../../context/Auth"
import { TranslationContext } from "../../context/Translation"
import { TranslationEditorContext } from "../../context/TranslationEditor"
import { postProject, postProjectLang } from "../../services/projects"
import Dropdown from "../components/Dropdown"
import Modal from "../components/Modal"
import ModalLimitReached from "../components/ModalLimitReached"
import { Link } from "react-router-dom"
import DropdownProjectLangs from "../components/DropdownProjectLangs"

export const TranslationEditor = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { langUsed, listLangUsed, userAccountTier } = useContext(AuthContext)
  const { languages } = useContext(TranslationContext)

  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  const {
    projects,
    refreshProjects,
    preventDoubleSubmit,
    setPreventDoubleSubmit,

    setSelectedProject,
    selectedProject,
    selectedProjectFiles,

    setSelectedFile,
    selectedFile,

    modalProjectIsShown,
    setModalProjectIsShown,

    modalProjectLangIsShown,
    setModalProjectLangIsShown,

    setSelectedLangs,
  } = useContext(TranslationEditorContext)

  const [inputLang, setInputLang] = useState<string[]>()
  const [projectName, setProjectName] = useState("")

  const [newOutputLang, setNewOutputLang] = useState<string[]>()

  const handleProjectName = (event: any) => {
    setProjectName(event.target.value)
  }

  const createProject = async () => {
    if (projectName.length > 0 && inputLang) {
      try {
        await postProject({
          name: projectName,
          souceLang: inputLang[2],
        })

        refreshProjects()
        setModalProjectIsShown(false)
      } catch (error: any) {
        if (
          error?.response?.data?.message?.startsWith("Project limit reached")
        ) {
          setModalProjectIsShown(false)
          setModalProjectLimitIsShown(true)
        }
        setPreventDoubleSubmit(false)
      }
    }
  }

  const [modalLangLimitIsShown, setModalLangLimitIsShown] =
    useState<boolean>(false)
  const [modalProjectLimitIsShown, setModalProjectLimitIsShown] =
    useState<boolean>(false)

  const createProjectLang = async () => {
    if (selectedProject && newOutputLang) {
      try {
        await postProjectLang({
          projectId: selectedProject.id_project,
          lang: newOutputLang[2],
        })

        localStorage.removeItem("selectedProject")
        refreshProjects()
        setModalProjectLangIsShown(false)
      } catch (error: any) {
        if (
          error?.response?.data?.message?.startsWith(
            "Output lang limit is reached",
          )
        ) {
          setModalProjectLangIsShown(false)
          setModalLangLimitIsShown(true)
        }
        setPreventDoubleSubmit(false)
      }
    }
  }

  const [langLimit, setLangLimit] = useState<number>(2)
  useEffect(() => {
    if (userAccountTier === 4) {
      setLangLimit(10000)
    }
    if (userAccountTier === 3) {
      setLangLimit(10)
    }
    if (userAccountTier === 2) {
      setLangLimit(5)
    }
    if (userAccountTier === 1) {
      setLangLimit(2)
    }
    if (userAccountTier === 0) {
      setLangLimit(1)
    }
  }, [userAccountTier])

  useEffect(() => {
    if (pathname === "/editor") {
      navigate("/editor/projects")
    }
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      setSelectedLangs(undefined)
      setSelectedProject(undefined)
    }
  }, [])

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  const recommendedLangs = useMemo(() => {
    return languages.filter((i) => i[2] === currentLanguage || i[2] === "en-US")
  }, [currentLanguage])

  const [searchText, setSearchText] = useState<string>("")

  const handleSearchText = (event: any) => {
    setSearchText(event.target.value)
  }

  const handleSearch = (event: any) => {
    event.preventDefault()

    navigate(
      `/editor/project/${selectedProject.id_project}/search/${searchText}`,
    )
  }

  return (
    <>
      <div className="champo-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined hide-phone">
          file_copy
        </span>

        <div className="flex-column flex-1">
          <h1 className="champo-title  hide-phone">
            {t("translationEditor.title")}
          </h1>

          {pathname.match(
            /^\/editor\/project\/(\d+)\/file\/(\d+)\/page\/(\d+)/,
          ) && (
            <Link
              className="button is-bordered fx-hide-gt-phone flex-row"
              style={{
                paddingLeft: 8,
                marginRight: "1rem",
                alignItems: "center",
                justifyContent: "center",
              }}
              to={`/editor/project/${selectedProject?.id_project}/files`}
              onClick={() => {
                setSelectedFile(undefined)
              }}
            >
              <span
                className="msymbol material-symbols-outlined"
                style={{ marginRight: 8 }}
              >
                keyboard_arrow_left
              </span>
              {t("commons.back")}
            </Link>
          )}
        </div>

        {selectedProject && (
          <form
            onSubmit={(e) => {
              handleSearch(e)
            }}
          >
            <div className="control flex-row" style={{}}>
              <input
                className="full-width input"
                type="text"
                placeholder={t("commons.search")}
                value={searchText}
                onChange={handleSearchText}
                style={{ borderRadius: "6px 0 0 6px", borderRight: "none" }}
              />
              <button
                type="submit"
                className="button is-bordered"
                style={{ borderRadius: "0 6px 6px 0", width: "40px" }}
              >
                <span className="msymbol material-symbols-outlined">
                  search
                </span>
              </button>
            </div>
          </form>
        )}
      </div>

      <div className="full-width-container TranslationEditor">
        <div className="file-list">
          <div style={{ margin: "0 1rem 1rem" }}>
            {Number(projects?.length || 0) > 0 ? (
              <Dropdown
                className="full-width "
                buttonClassName={"dropdown-account-button"}
                buttonStyle={{ height: 52, paddingLeft: "0.5rem" }}
                title={
                  selectedProject ? (
                    <div className="flex-row flex-center">
                      {/* <div> {selectedProject && selectedProject?.name[0]}</div> */}
                      <div className="dropdown-project-circle">
                        {selectedProject?.name[0]}
                      </div>

                      <div className="text-ellipsis flex-1 ">
                        {selectedProject?.name}
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginLeft: "0.25rem" }}>
                      {t("commons.select")}
                    </div>
                  )
                }
                rightMenu={true}
                iconStyle={{ marginRight: 0 }}
              >
                {projects?.map((item) => (
                  <div
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      navigate(`/editor/project/${item.id_project}/files`)
                    }}
                    className="dropdown-item"
                  >
                    <div
                      className={`dropdown-link flex-row ${
                        selectedProject &&
                        item.id_project === selectedProject.id_project
                          ? "selected"
                          : ""
                      }`}
                    >
                      {/* <div
                    className="dropdown-circle"
                    style={{ marginLeft: "0.3rem" }}
                  >
                    {item.name[0]}
                  </div> */}
                      <div
                        className="dropdown-project-circle"
                        style={{ marginRight: "0rem" }}
                      >
                        {item.name[0]}
                      </div>

                      <span
                        className="text-ellipsis flex-1 "
                        style={{ marginLeft: "1rem" }}
                      >
                        {item.name}
                      </span>
                    </div>
                  </div>
                ))}
              </Dropdown>
            ) : (
              <button
                className="full-width button is-primary-gradient"
                onClick={() => {
                  setModalProjectIsShown(true)
                }}
              >
                {t("translationEditor.create_a_project")}
              </button>
            )}
          </div>

          <h2
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 18,
              fontWeight: "bold",
              margin: 0,
              opacity: 0.5,
              marginLeft: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            {t("translationEditor.selected_lang")}
          </h2>
          <div style={{ margin: "0 1rem 1rem" }}>
            <DropdownProjectLangs
              className="full-width"
              customMenuStyle={{
                maxHeight: "400px",
                overflow: "auto",
              }}
              rightMenu={true}
              iconStyle={{ marginRight: 0 }}
            />
          </div>
          <h2
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 18,
              fontWeight: "bold",
              margin: 0,
              opacity: 0.5,
              marginLeft: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            {t("translationEditor.i18n_files")}
          </h2>
          {!selectedProject && (
            <p
              className="text"
              style={{
                fontFamily: "Poppins, sans-serif",
                textAlign: "center",
                opacity: 0.5,
                fontSize: 16,
                margin: "1rem",
                fontStyle: "italic",
              }}
            >
              {t("translationEditor.i18n_files_no_project")}
            </p>
          )}

          {selectedProject && (
            <NavLink
              to={`/editor/project/${selectedProject.id_project}/file/import`}
              style={{ margin: "0rem 1rem 1rem" }}
              className={
                "import-file-button button " +
                ((selectedProjectFiles?.length || 0) > 0
                  ? "is-bordered"
                  : "is-primary-gradient")
              }
            >
              {t("translationEditor.import_file")}
            </NavLink>
          )}

          {selectedProjectFiles?.map((item) => (
            <div
              className={
                "file-item " +
                (selectedFile && item.id_file === selectedFile.id_file
                  ? "selected"
                  : "")
              }
              onClick={() => {
                setSelectedFile(item)
                navigate(
                  `/editor/project/${selectedProject.id_project}/file/${item.id_file}/page/1`,
                )
              }}
            >
              <span className="msymbol material-symbols-outlined">
                insert_drive_file
              </span>
              <div className="file-infos flex-column">
                <span className="filename">{item.name}</span>
                <span className="key-counter">
                  {item?.fileKeyCount?.toLocaleString()}{" "}
                  {t("translationEditor.keys_lower")}
                </span>
              </div>
            </div>
          ))}
        </div>

        <Outlet />
      </div>

      <Modal
        isShown={modalProjectIsShown}
        closeModal={() => setModalProjectIsShown(false)}
        overflow="normal"
        containerStyle={{ marginTop: "100px" }}
        wrapperStyle={{
          justifyContent: "flex-start",
        }}
      >
        <h1 className="modal-title">
          {t("translationEditor.create_a_project")}
        </h1>

        <div className="field" style={{ marginTop: "1rem" }}>
          <label className="label text">
            {t("translationEditor.project_name")}
          </label>
          <div className="control">
            <input
              className="full-width input"
              type="text"
              placeholder={t("translationEditor.project_name")}
              value={projectName}
              onChange={handleProjectName}
            />
          </div>
        </div>

        <div className="field" style={{ marginTop: "1rem" }}>
          <label className="label text">{t("translate.inputLang")}</label>
          <Dropdown
            className="full-width"
            customMenuStyle={{ maxHeight: 300, overflow: "auto" }}
            title={
              inputLang ? (
                <div className="flex-row">
                  <div>
                    {" "}
                    <span className={getFlagClass(inputLang[2])}></span>
                  </div>
                  <div style={{ marginLeft: "1rem" }}>{inputLang[2]}</div>
                </div>
              ) : (
                t("translate.inputLang")
              )
            }
            rightMenu={true}
          >
            {recommendedLangs?.map((item) => (
              <div
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  if (item[2]) {
                    setInputLang(item)
                  }
                }}
                className="dropdown-item"
              >
                <div
                  className={`dropdown-link flex-row ${
                    inputLang && item[2] === inputLang[2] ? "selected" : ""
                  }`}
                >
                  <div
                    className="dropdown-circle"
                    style={{ marginLeft: "0.3rem" }}
                  >
                    <span className={getFlagClass(item[2])}></span>
                  </div>
                  <span
                    className="text-ellipsis flex-1 "
                    style={{ marginLeft: "1.3rem" }}
                  >
                    {item[2]}
                  </span>
                </div>
              </div>
            ))}
            <hr style={{ margin: "0.5rem 1rem" }} />
            {languages
              .filter(
                (i) =>
                  !recommendedLangs
                    ?.map((j) => (j[2] ? j[2] : null))
                    ?.includes(i[2]),
              )
              ?.map((item) => (
                <div
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setInputLang(item)
                  }}
                  className="dropdown-item"
                >
                  <div
                    className={`dropdown-link flex-row ${
                      inputLang && item[2] === inputLang[2] ? "selected" : ""
                    } ${langUsed >= langLimit ? "disabled" : ""}`}
                  >
                    <div
                      className="dropdown-circle"
                      style={{ marginLeft: "0.3rem" }}
                    >
                      <span className={getFlagClass(item[2])}></span>
                    </div>
                    <span
                      className="text-ellipsis flex-1 "
                      style={{ marginLeft: "1.3rem" }}
                    >
                      {item[2]}
                    </span>
                  </div>
                </div>
              ))}
          </Dropdown>
        </div>

        <button
          className="full-width button is-primary-gradient"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          onClick={() => {
            setPreventDoubleSubmit(true)
            createProject()
          }}
          disabled={
            preventDoubleSubmit ||
            !projectName ||
            projectName.length < 3 ||
            !inputLang
          }
        >
          {t("translationEditor.create_a_project")}
        </button>
      </Modal>

      <Modal
        isShown={modalProjectLangIsShown}
        closeModal={() => setModalProjectLangIsShown(false)}
        overflow="normal"
        containerStyle={{ marginTop: "100px" }}
        wrapperStyle={{
          justifyContent: "flex-start",
        }}
      >
        <h1 className="modal-title">{t("translationEditor.add_lang")}</h1>

        <div className="field" style={{ marginTop: "1rem" }}>
          <label className="label text">{t("translate.outputLang")}</label>
          <Dropdown
            className="full-width"
            customMenuStyle={{ maxHeight: 300, overflow: "auto" }}
            title={
              newOutputLang ? (
                <div className="flex-row">
                  <div>
                    {" "}
                    <span className={getFlagClass(newOutputLang[2])}></span>
                  </div>
                  <div style={{ marginLeft: "1rem" }}>{newOutputLang[2]}</div>
                </div>
              ) : (
                t("translate.outputLang")
              )
            }
            rightMenu={true}
          >
            {listLangUsed?.length === 1 && (
              <p style={{ margin: "0 0.5rem 0.5rem", opacity: "0.5" }}>
                {t("usage.used_language")}
              </p>
            )}
            {listLangUsed?.length > 1 && (
              <p style={{ margin: "0 0.5rem 0.25rem", opacity: "0.5" }}>
                {t("usage.used_languages")}
              </p>
            )}
            {listLangUsed?.map((item) => (
              <div
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  if (item[2]) {
                    setNewOutputLang(item)
                  }
                }}
                className="dropdown-item"
              >
                <div
                  className={`dropdown-link flex-row ${
                    inputLang && item[2] === inputLang[2] ? "selected" : ""
                  }`}
                >
                  <div
                    className="dropdown-circle"
                    style={{ marginLeft: "0.3rem" }}
                  >
                    <span className={getFlagClass(item[2])}></span>
                  </div>
                  <span
                    className="text-ellipsis flex-1 "
                    style={{ marginLeft: "1.3rem" }}
                  >
                    {item[2]}
                  </span>
                </div>
              </div>
            ))}
            <hr style={{ margin: "0.5rem 1rem" }} />
            {languages
              .filter(
                (i) =>
                  !listLangUsed
                    ?.map((j) => (j[2] ? j[2] : null))
                    ?.includes(i[2]),
              )
              ?.map((item) => (
                <div
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setNewOutputLang(item)
                  }}
                  className="dropdown-item"
                >
                  <div
                    className={`dropdown-link flex-row ${
                      inputLang && item[2] === inputLang[2] ? "selected" : ""
                    } ${langUsed >= langLimit ? "disabled" : ""}`}
                  >
                    <div
                      className="dropdown-circle"
                      style={{ marginLeft: "0.3rem" }}
                    >
                      <span className={getFlagClass(item[2])}></span>
                    </div>
                    <span
                      className="text-ellipsis flex-1 "
                      style={{ marginLeft: "1.3rem" }}
                    >
                      {item[2]}
                    </span>
                  </div>
                </div>
              ))}
          </Dropdown>
        </div>
        <button
          className="full-width button is-primary-gradient"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          onClick={() => {
            setPreventDoubleSubmit(true)
            createProjectLang()
          }}
          disabled={preventDoubleSubmit || !newOutputLang}
        >
          {t("translationEditor.add_lang")}
        </button>
      </Modal>

      <Modal
        containerStyle={{ maxWidth: 800 }}
        isShown={modalLangLimitIsShown}
        closeModal={() => {
          setModalLangLimitIsShown(false)
        }}
      >
        <ModalLimitReached
          closeModal={() => setModalLangLimitIsShown(false)}
          limitType="lang"
        ></ModalLimitReached>
      </Modal>

      <Modal
        containerStyle={{ maxWidth: 800 }}
        isShown={modalProjectLimitIsShown}
        closeModal={() => {
          setModalProjectLimitIsShown(false)
        }}
      >
        <ModalLimitReached
          closeModal={() => setModalProjectLimitIsShown(false)}
          limitType="projects"
        ></ModalLimitReached>
      </Modal>
    </>
  )
}

export default TranslationEditor
