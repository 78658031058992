import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { getCredits, postGetFreeCredits } from "../services/credits"
import { getInvoices } from "../services/invoices"
import { getPaymentMethods } from "../services/paymentMethods"
import {
  cancelDowngradeSubscription,
  downgradeSubscription,
  getUserSubscription,
  reactivateSubscription,
  upgradeSubscription,
} from "../services/subscriptions"
import { AuthContext } from "./Auth"

type PaymentContextType = {
  paymentId: string
  setPaymentId: Dispatch<SetStateAction<string>>
  resetPaymentId: () => void
  subscriptionId: string
  setSubscriptionId: Dispatch<SetStateAction<string>>
  resetSubscriptionId: () => void

  currentPage: number
  preventDoubleSubmit: boolean
  actionRef: string | null
  subscriptionStatus: string | null
  nextInvoiceDate: Date | null
  startInvoiceDate: Date | null
  credits: any | null
  countCredits: number
  invoices: any | null
  paymentMethods: any | null
  userAddress: any | null
  paymentStatus: string | null
  modalCreditsIsShown: boolean
  paymentAmount: number
  modalSubscriptionIsShown: boolean
  priceIdSelected: string | null
  tierSelected: number | null
  isStripePaymentLoaded: boolean
  modalSetupIsShown: boolean
  modalChangeSub: boolean
  newTierRequested: number | null
  modalConfirmationPayment: boolean

  setCurrentPage: Dispatch<SetStateAction<number>>
  setPreventDoubleSubmit: Dispatch<SetStateAction<boolean>>
  setActionRef: Dispatch<SetStateAction<string | null>>
  setSubscriptionStatus: Dispatch<SetStateAction<string | null>>
  setNextInvoiceDate: Dispatch<SetStateAction<Date | null>>
  setStartInvoiceDate: Dispatch<SetStateAction<Date | null>>
  setCredits: Dispatch<SetStateAction<any | null>>
  setCountCredits: Dispatch<SetStateAction<number>>
  setInvoices: Dispatch<SetStateAction<any | null>>
  setPaymentMethods: Dispatch<SetStateAction<any | null>>
  setUserAddress: Dispatch<SetStateAction<any | null>>
  setPaymentStatus: Dispatch<SetStateAction<string | null>>
  setModalCreditsIsShown: Dispatch<SetStateAction<boolean>>
  setPaymentAmount: Dispatch<SetStateAction<number>>
  setModalSubscriptionIsShown: Dispatch<SetStateAction<boolean>>
  setPriceIdSelected: Dispatch<SetStateAction<string | null>>
  setTierSelected: Dispatch<SetStateAction<number | null>>
  setIsStripePaymentLoaded: Dispatch<SetStateAction<boolean>>
  setModalSetupIsShown: Dispatch<SetStateAction<boolean>>
  setModalChangeSub: Dispatch<SetStateAction<boolean>>
  setNewTierRequested: Dispatch<SetStateAction<number | null>>
  setModalConfirmationPayment: Dispatch<SetStateAction<boolean>>

  reloadCredits: () => any
  reloadInvoices: () => any
  reloadPaymentMethods: () => any
  refreshCredits: () => Promise<any>
  refreshInvoices: () => Promise<any>
  refreshPaymentMethods: () => Promise<any>
  getFreeCredits: () => Promise<any>
  reactivateSub: () => void
  changeSub: (tier_number: number) => Promise<void>
  cancelDowngradeSub: () => void

  creditsToGive: number | null
  newPaymentAmount: number | null
}

export const PaymentContext = React.createContext<PaymentContextType>({
  paymentId: "",
  setPaymentId: () => {},
  resetPaymentId: () => {},
  subscriptionId: "",
  setSubscriptionId: () => {},
  resetSubscriptionId: () => {},

  currentPage: 0,
  preventDoubleSubmit: false,
  actionRef: null,
  subscriptionStatus: null,
  nextInvoiceDate: null,
  startInvoiceDate: null,
  credits: null,
  countCredits: 0,
  invoices: null,
  paymentMethods: null,
  userAddress: null,
  paymentStatus: null,
  modalCreditsIsShown: false,
  paymentAmount: 15,
  modalSubscriptionIsShown: false,
  priceIdSelected: null,
  tierSelected: null,
  isStripePaymentLoaded: false,
  modalSetupIsShown: false,
  modalChangeSub: false,
  newTierRequested: null,
  modalConfirmationPayment: false,

  setCurrentPage: () => {},
  setPreventDoubleSubmit: () => {},
  setActionRef: () => {},
  setSubscriptionStatus: () => {},
  setNextInvoiceDate: () => {},
  setStartInvoiceDate: () => {},
  setCredits: () => {},
  setCountCredits: () => {},
  setInvoices: () => {},
  setPaymentMethods: () => {},
  setUserAddress: () => {},
  setPaymentStatus: () => {},
  setModalCreditsIsShown: () => {},
  setPaymentAmount: () => {},
  setModalSubscriptionIsShown: () => {},
  setPriceIdSelected: () => {},
  setTierSelected: () => {},
  setIsStripePaymentLoaded: () => {},
  setModalSetupIsShown: () => {},
  setModalChangeSub: () => {},
  setNewTierRequested: () => {},
  setModalConfirmationPayment: () => {},

  reloadCredits: () => {},
  reloadInvoices: () => {},
  reloadPaymentMethods: () => {},
  refreshCredits: async () => {},
  refreshInvoices: async () => {},
  refreshPaymentMethods: async () => {},
  getFreeCredits: async () => {},
  reactivateSub: () => {},
  changeSub: async () => {},
  cancelDowngradeSub: () => {},

  creditsToGive: null,
  newPaymentAmount: null,
})

type Props = {
  children: React.ReactNode
}

export function PaymentProvider({ children }: Props) {
  const { userAccountTier, setUserAccountTier, setCurrentBalance } =
    useContext(AuthContext)

  const [paymentId, setPaymentId] = useState("")
  const [subscriptionId, setSubscriptionId] = useState("")

  const [currentPage, setCurrentPage] = useState<number>(1)

  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState<boolean>(false)
  const [actionRef, setActionRef] = useState<string | null>(null)

  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(
    null,
  )
  const [nextInvoiceDate, setNextInvoiceDate] = useState<Date | null>(null)
  const [startInvoiceDate, setStartInvoiceDate] = useState<Date | null>(null)

  const [credits, setCredits] = useState<any | null>(null)
  const [countCredits, setCountCredits] = useState<number>(0)

  const [invoices, setInvoices] = useState<any | null>(null)

  const [paymentMethods, setPaymentMethods] = useState<any | null>(null)
  const [userAddress, setUserAddress] = useState<any | null>(null)

  const [paymentStatus, setPaymentStatus] = useState<string | null>(null)

  const [modalCreditsIsShown, setModalCreditsIsShown] = useState<boolean>(false)
  const [paymentAmount, setPaymentAmount] = useState<number>(15)
  const [modalSubscriptionIsShown, setModalSubscriptionIsShown] =
    useState<boolean>(false)

  const [priceIdSelected, setPriceIdSelected] = useState<string | null>(null)
  const [tierSelected, setTierSelected] = useState<number | null>(null)
  const [isStripePaymentLoaded, setIsStripePaymentLoaded] =
    useState<boolean>(false)

  const [modalSetupIsShown, setModalSetupIsShown] = useState<boolean>(false)
  const [modalChangeSub, setModalChangeSub] = useState<boolean>(false)
  const [newTierRequested, setNewTierRequested] = useState<number | null>(null)
  const [modalConfirmationPayment, setModalConfirmationPayment] =
    useState<boolean>(false)

  const { loadingFinished, currentUser } = useContext(AuthContext)

  useEffect(() => {
    if (loadingFinished && currentUser && paymentId.length > 0) {
      localStorage.setItem("paymentId", paymentId)
    }
    if (loadingFinished && paymentId && !currentUser) {
      localStorage.removeItem("paymentId")
      setPaymentId("")
    }
  }, [loadingFinished, paymentId, currentUser])

  useEffect(() => {
    if (loadingFinished && currentUser && subscriptionId.length > 0) {
      localStorage.setItem("subscriptionId", subscriptionId)
    }
    if (loadingFinished && subscriptionId && !currentUser) {
      localStorage.removeItem("subscriptionId")
      setSubscriptionId("")
    }
  }, [loadingFinished, subscriptionId, currentUser])

  useEffect(() => {
    const payID = localStorage.getItem("paymentId")

    if (payID) {
      setPaymentId(payID)
    }
  }, [])

  useEffect(() => {
    const subId = localStorage.getItem("subscriptionId")

    if (subId) {
      setSubscriptionId(subId)
    }
  }, [])

  const resetPaymentId = () => {
    localStorage.removeItem("paymentId")
    setPaymentId("")
  }

  const resetSubscriptionId = () => {
    localStorage.removeItem("subscriptionId")
    setSubscriptionId("")
  }

  const reloadCredits = async () => {
    return await Promise.all([getCredits(), getUserSubscription()])
  }

  const reloadInvoices = async () => {
    return await Promise.all([getInvoices()])
  }

  const reloadPaymentMethods = async () => {
    return await Promise.all([getPaymentMethods()])
  }

  const refreshCredits = async () => {
    const result = await reloadCredits()
    setCredits(result[0].data.rows)
    setCountCredits(result[0].data.count)
    setCurrentBalance(
      result[0].data.rows.reduce(
        (acc: number, item: any) =>
          acc +
          (new Date(item.expiresAt) > new Date()
            ? Number(item.current_balance)
            : 0),
        0,
      ),
    )
    setUserAccountTier(result[1].data.account_tier)
    setSubscriptionStatus(result[1].data.status)
    setNextInvoiceDate(result[1].data.next_invoice_date)
    setStartInvoiceDate(result[1].data.start_invoice_date)
    setModalCreditsIsShown(false)
    setModalSubscriptionIsShown(false)
    setModalSetupIsShown(false)
    setIsStripePaymentLoaded(false)
    resetPaymentId()
    resetSubscriptionId()
    setPreventDoubleSubmit(false)
    setActionRef(null)
  }

  const refreshInvoices = async () => {
    const result = await reloadInvoices()
    setInvoices(result[0].data.rows)
  }

  const refreshPaymentMethods = async () => {
    const result = await reloadPaymentMethods()
    setPaymentMethods(result[0].data.rows)
    setUserAddress(result[0].data.billing_address)
    setModalSetupIsShown(false)
  }

  const getFreeCredits = async () => {
    try {
      const result = await postGetFreeCredits()
      setCredits(result.data.rows)
      setCountCredits(result.data.count)
      await refreshCredits()
    } catch (error) {
      await refreshCredits()
    }
  }

  const reactivateSub = async () => {
    try {
      await reactivateSubscription()
      refreshCredits()
    } catch (error) {
      refreshCredits()
    }
  }

  const changeSub = async (tier_number: number) => {
    try {
      if (userAccountTier > tier_number) {
        await downgradeSubscription(tier_number)
        await refreshCredits()
        await refreshInvoices()
        setModalChangeSub(false)
      } else if (userAccountTier < tier_number) {
        await upgradeSubscription(tier_number)
        await refreshCredits()
        setModalChangeSub(false)
      }
    } catch (error) {
      refreshCredits()
    }
  }

  const cancelDowngradeSub = async () => {
    try {
      await cancelDowngradeSubscription()
      refreshCredits()
    } catch (error) {
      refreshCredits()
    }
  }

  const creditsToGive = useMemo(() => {
    if (nextInvoiceDate && startInvoiceDate) {
      let base_credit = 0
      switch (userAccountTier) {
        case 1:
          base_credit = 50000
          break
        case 2:
          base_credit = 150000
          break
        case 3:
          base_credit = 300000
          break
        case 4:
          base_credit = 1000000
          break
      }

      let new_credit = 0
      switch (newTierRequested) {
        case 1:
          new_credit = 50000
          break
        case 2:
          new_credit = 150000
          break
        case 3:
          new_credit = 300000
          break
        case 4:
          new_credit = 1000000
          break
      }

      const current_start = new Date(startInvoiceDate).getTime()
      const current_end = new Date(nextInvoiceDate).getTime()
      const now_ms = new Date().getTime()

      const total_time = current_end - current_start
      const remaing_time = current_end - now_ms

      const prorata_ratio = remaing_time / total_time
      const credit_to_give = prorata_ratio * (new_credit - base_credit)
      return Number(credit_to_give.toFixed())
    } else {
      return 0
    }
  }, [newTierRequested, userAccountTier, nextInvoiceDate, startInvoiceDate])

  const newPaymentAmount = useMemo(() => {
    if (nextInvoiceDate && startInvoiceDate) {
      let base_price = 0
      switch (userAccountTier) {
        case 1:
          base_price = 19
          break
        case 2:
          base_price = 39
          break
        case 3:
          base_price = 69
          break
        case 4:
          base_price = 139
          break
      }

      let new_price = 0
      switch (newTierRequested) {
        case 1:
          new_price = 19
          break
        case 2:
          new_price = 39
          break
        case 3:
          new_price = 69
          break
        case 4:
          new_price = 139
          break
      }

      const current_start = new Date(startInvoiceDate).getTime()
      const current_end = new Date(nextInvoiceDate).getTime()
      const now_ms = new Date().getTime()

      const total_time = current_end - current_start
      const remaing_time = current_end - now_ms
      const prorata_ratio = remaing_time / total_time

      const credit_to_give = prorata_ratio * (new_price - base_price)
      return credit_to_give
    } else {
      return 0
    }
  }, [newTierRequested, userAccountTier, nextInvoiceDate, startInvoiceDate])

  useEffect(() => {
    if (!invoices && userAccountTier > 0) {
      reloadInvoices()
        .then((result: any) => {
          setInvoices(result[0].data.rows)
        })
        .catch((err: any) => {
          setInvoices(null)
        })
    }
  }, [invoices, userAccountTier])

  useEffect(() => {
    if (!paymentMethods && userAccountTier > 0) {
      reloadPaymentMethods()
        .then((result: any) => {
          setPaymentMethods(result[0].data.rows)
          setUserAddress(result[0].data.billing_address)
        })
        .catch((err: any) => {
          setPaymentMethods(null)
          setUserAddress(null)
        })
    }
  }, [paymentMethods, userAccountTier])

  useEffect(() => {
    if (!currentUser) {
      setPaymentMethods(null)
      setUserAddress(null)
      setInvoices(null)
    }
  }, [currentUser])

  return (
    <PaymentContext.Provider
      value={{
        paymentId: paymentId,
        setPaymentId: setPaymentId,
        resetPaymentId: resetPaymentId,
        subscriptionId: subscriptionId,
        setSubscriptionId: setSubscriptionId,
        resetSubscriptionId: resetSubscriptionId,

        currentPage,
        preventDoubleSubmit,
        actionRef,
        subscriptionStatus,
        nextInvoiceDate,
        startInvoiceDate,
        credits,
        countCredits,
        invoices,
        paymentMethods,
        userAddress,
        paymentStatus,
        modalCreditsIsShown,
        paymentAmount,
        modalSubscriptionIsShown,
        priceIdSelected,
        tierSelected,
        isStripePaymentLoaded,
        modalSetupIsShown,
        modalChangeSub,
        newTierRequested,
        modalConfirmationPayment,

        setCurrentPage,
        setPreventDoubleSubmit,
        setActionRef,
        setSubscriptionStatus,
        setNextInvoiceDate,
        setStartInvoiceDate,
        setCredits,
        setCountCredits,
        setInvoices,
        setPaymentMethods,
        setUserAddress,
        setPaymentStatus,
        setModalCreditsIsShown,
        setPaymentAmount,
        setModalSubscriptionIsShown,
        setPriceIdSelected,
        setTierSelected,
        setIsStripePaymentLoaded,
        setModalSetupIsShown,
        setModalChangeSub,
        setNewTierRequested,
        setModalConfirmationPayment,

        reloadCredits,
        reloadInvoices,
        reloadPaymentMethods,
        refreshCredits,
        refreshInvoices,
        refreshPaymentMethods,
        getFreeCredits,
        reactivateSub,
        changeSub,
        cancelDowngradeSub,

        creditsToGive,
        newPaymentAmount,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}
