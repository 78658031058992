import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import ReactGA from "react-ga4"

import { AuthProvider } from "./context/Auth"
import { ToastContainer } from "react-toastify"
import { UIProvider } from "./context/UI"
import { PaymentProvider } from "./context/Payment"

import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { TranslationProvider } from "./context/Translation"
import { TranslationEditorProvider } from "./context/TranslationEditor"

if (process.env.REACT_APP_ENV === "production") {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
  })

  ReactGA.initialize(process.env.REACT_APP_GA_ID)
}

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <PostHogProvider client={posthog}>
    <UIProvider>
      <TranslationProvider>
        <AuthProvider>
          <TranslationEditorProvider>
            <PaymentProvider>
              <App />

              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </PaymentProvider>
          </TranslationEditorProvider>
        </AuthProvider>
      </TranslationProvider>
    </UIProvider>
  </PostHogProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
