import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { NavLink } from "react-router-dom"
import { AuthContext } from "../../../context/Auth"
import { PaymentContext } from "../../../context/Payment"
import AmountLabeled from "../../components/AmountLabeled"
import AmountBar from "../../components/AmountBar"

export const BillingHeader = () => {
  const { t } = useTranslation()
  const { currentBalance, userAccountTier } = useContext(AuthContext)
  const { credits } = useContext(PaymentContext)

  return (
    <>
      <AmountBar itemPerLine={4} />

      <div className="flex-row full-width">
        {credits?.filter(
          (i: any) =>
            Number(i.total_credit) === 5000 && Number(i.payment_amount) === 0,
        )?.length === 0 && (
          <NavLink
            to="/billing/free-credits"
            className="part-title warn-text"
            style={{
              marginTop: "1rem",
              marginBottom: "2.5rem",
              marginRight: "2rem",
            }}
          >
            {t("credits.get_free_credits")}
          </NavLink>
        )}
        <NavLink
          to={
            userAccountTier > 0 ? "/billing/premium-account" : "/billing/offers"
          }
          className="part-title"
          style={{
            marginTop: "1rem",
            marginBottom: "2.5rem",
            marginRight: "2rem",
          }}
        >
          {t("credits.premium_account")}
        </NavLink>

        <NavLink
          to="/billing/buy-credits"
          className="part-title"
          style={{
            marginTop: "1rem",
            marginBottom: "2.5rem",
            marginRight: "2rem",
          }}
        >
          {t("credits.buy_credits")}
        </NavLink>

        {credits?.filter(
          (i: any) =>
            !(
              Number(i.total_credit) === 5000 && Number(i.payment_amount) === 0
            ),
        )?.length > 0 && (
          <NavLink
            to="/billing/invoices"
            className="part-title"
            style={{
              marginTop: "1rem",
              marginBottom: "2.5rem",
              marginRight: "2rem",
            }}
          >
            {t("credits.invoices")}
          </NavLink>
        )}
      </div>
    </>
  )
}

export default BillingHeader
