import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import AnimatedLoader from "../components/AnimatedLoader"
import Paginator from "../components/Paginator"

import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js"
import moment from "moment"
import posthog from "posthog-js"
import { Bar } from "react-chartjs-2"
import { NavLink } from "react-router-dom"
import { AuthContext } from "../../context/Auth"
import { getUsagePage } from "../../services/usage"
import { UIContext } from "../../context/UI"
import ReactGA from "react-ga4"
import AmountLabeled from "../components/AmountLabeled"

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
)

export const Usage = () => {
  const { t } = useTranslation()

  const { langUsed, userAccountTier } = useContext(AuthContext)
  const { isDark } = useContext(UIContext)

  const [usage, setUsage] = useState<any>(null)
  const [barChartData, setBarChartData] = useState<any>(null)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const [totalEvents, setTotalEvents] = useState<number>(0)
  const [usageLast30Days, setUsageLast30Days] = useState<number>(0)

  useEffect(() => {
    document.title = t("usage.title")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/usage",
        title: "Usage",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const changePage = (page_number: number) => {
    setCurrentPage(page_number)
  }

  useEffect(() => {
    const reloadUsage = async () => {
      return await getUsagePage({
        currentPage,
      })
    }

    reloadUsage()
      .then((result: any) => {
        setUsage(result.data.rows)

        // FAKE DATA
        setTotalEvents(result.data.count)

        let dataChart = []
        let totalUsage = 0

        for (let i = 30; i >= 0; i--) {
          let to_push = {
            date: new Date(
              moment().subtract(i, "days").toString(),
            ).toLocaleDateString(),
            total_request: 0,
          }

          let itemUsage = 0
          result.data.resultLast30Days.map((item: any) => {
            if (
              new Date(item.date).toLocaleDateString() ===
              new Date(
                moment().subtract(i, "days").toString(),
              ).toLocaleDateString()
            ) {
              to_push.total_request = Number(item.request_count)
              itemUsage += Number(item.request_count)
            }
            return item
          })

          totalUsage += itemUsage
          dataChart.push(to_push)
        }

        setUsageLast30Days(totalUsage)

        setBarChartData({
          labels: dataChart.map((i: any) => i.date),
          datasets: [
            {
              label: t("usage.cost"),
              data: dataChart.map((i: any) =>
                Number(i.total_request).toFixed(),
              ),
              backgroundColor: ["rgba(124, 58, 237, 0.15)"],
              borderColor: ["rgba(124, 58, 237, 0.8)"],
              borderWidth: 1,
              borderRadius: 5,
            },
          ],
        })
      })
      .catch((err) => {
        setBarChartData(null)
        setUsage(null)
        setTotalEvents(0)
        console.log(err)
      })
  }, [currentPage, t])

  return (
    <>
      <div className="champo-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined">
          browse_activity
        </span>

        <div className="flex-column">
          <h1 className="champo-title">{t("usage.title")}</h1>
        </div>
      </div>

      <div className="basic-container">
        <div className="infos-chart">
          <div className="infos-container">
            <h2
              className="sub-title"
              style={{
                margin: 0,
                marginBottom: "1rem",
              }}
            >
              {t("usage.accountActivity")}
            </h2>

            <AmountLabeled
              icon="token"
              iconColor="#eab308"
              label={t("credits.token_usage")}
              amount={usageLast30Days}
            />

            <div className="flex-row" style={{ marginTop: "0.5rem" }}>
              <div
                className="flex-column flex-1"
                style={{ marginRight: "0.25rem" }}
              >
                <AmountLabeled
                  icon="bolt"
                  iconColor="#f97316"
                  label={t("credits.requests")}
                  amount={totalEvents}
                />
              </div>

              <div
                className="flex-column flex-1"
                style={{ marginLeft: "0.25rem" }}
              >
                <AmountLabeled
                  label={t("usage.languages")}
                  icon="translate"
                  iconColor="#84cc16"
                  amount={langUsed}
                />
              </div>
            </div>

            {/* <div style={{ marginBottom: "1.1rem", width: "100%" }}>
              <div className="flex-row">
                <NavLink
                  to="/billing"
                  className="button is-primary-gradient"
                  style={{ marginLeft: 0 }}
                >
                  {t("credits.getCredits")}
                </NavLink>
              </div>
            </div> */}
          </div>
          <div className="usage-chart-container">
            {barChartData && (
              <div className="usage-chart-wrapper">
                <Bar
                  style={{}}
                  data={barChartData}
                  options={{
                    resizeDelay: 0,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        mode: "x",
                        intersect: false,
                        callbacks: {
                          label: function (tooltipItem) {
                            return tooltipItem.formattedValue + " tokens"
                          },
                        },
                      },
                    },
                    hover: {
                      mode: "x",
                      intersect: false,
                    },
                    scales: {
                      y: {
                        ticks: {
                          display: false,
                        },
                        grid: {
                          display: false,
                        },
                        border: {
                          display: false,
                        },
                      },
                      x: {
                        ticks: {
                          display: false,
                        },
                        grid: {
                          display: false,
                        },
                        border: {
                          color: isDark
                            ? "rgba(255,255,255,0.075)"
                            : "rgba(0,0,0,0.075)",
                          width: 1,
                        },
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {usage !== null && usage?.length > 0 && (
          <hr
            style={{
              margin: 0,
              marginTop: "1rem",
              marginBottom: "2rem",
              width: "100%",
            }}
          />
        )}

        {!usage && (
          <div className="champo-page-container">
            <AnimatedLoader />
          </div>
        )}

        {usage !== null && usage?.length > 0 && (
          <div className="champo-page-container">
            <div className="flex-column table-list">
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  minWidth: "100%",
                }}
              >
                <div className="flex-row full-width table-item header-item">
                  <div
                    style={{
                      width: "8rem",
                      minWidth: "8rem",
                    }}
                  >
                    {t("commons.status")}
                  </div>
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                    }}
                  >
                    {t("commons.date")}
                  </div>
                  <div
                    style={{
                      width: "10rem",
                      minWidth: "10rem",
                    }}
                  >
                    {t("usage.in")} - {t("usage.out")}
                  </div>

                  <div className="flex-1" style={{ minWidth: "10rem" }}>
                    {t("usage.filename")}
                  </div>

                  <div
                    style={{
                      width: "6rem",
                      minWidth: "6rem",
                      textAlign: "right",
                    }}
                  >
                    {t("usage.cost")}
                  </div>
                </div>

                {usage.map((item: any, index: number) => (
                  <div
                    key={item.id_request + "_list_100"}
                    className={
                      "table-item flex-row " +
                      (index % 2 === 0 ? "even" : "odd")
                    }
                  >
                    <div
                      style={{
                        width: "8rem",
                        minWidth: "8rem",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "6px",
                          fontSize: "14px",
                          minWidth: "6.75rem",
                          fontWeight: "bold",
                        }}
                        className={
                          "flex-row flex-center " +
                          (item.status === "SUCCEED"
                            ? "success-badge"
                            : item.status === "STARTED"
                              ? "warn-badge"
                              : item.status === "ERRORED"
                                ? "danger-badge"
                                : "")
                        }
                      >
                        {item.status}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "12rem",
                        minWidth: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b> {new Date(item.updatedAt).toLocaleString()}</b>
                      </p>
                    </div>
                    <div
                      style={{
                        width: "10rem",
                        minWidth: "10rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b>{item.input_lang}</b> - <i>{item.output_lang}</i>
                      </p>
                    </div>

                    <div className="flex-column flex-1">
                      <div className="flex-row full-width">
                        <div className="flex-1" style={{ minWidth: "10rem" }}>
                          {item.filename}
                        </div>

                        <div
                          style={{
                            width: "6rem",
                            minWidth: "6rem",
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          {Number(item.champo_cost).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <Paginator
              currentPage={currentPage}
              totalItem={totalEvents}
              itemPerPage={50}
              changeCurrentPage={changePage}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Usage
