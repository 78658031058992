import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { TranslationEditorContext } from "../../../context/TranslationEditor"
import {
  getSearchKeys,
  postMissingFileKey,
  updateFileKey,
} from "../../../services/files"

export const SearchResult = () => {
  const { t } = useTranslation()
  let { search_text } = useParams()
  const { setSelectedFile } = useContext(TranslationEditorContext)
  const navigate = useNavigate()

  const {
    selectedFile,
    selectedLangs,
    selectedProject,
    selectedProjectFiles,
    setPreventDoubleSubmit,
    incrementProjectLangProgressions,
  } = useContext(TranslationEditorContext)

  const [keys, setKeys] = useState<any[]>([])
  const [totalKeyResult, setTotalKeyResult] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // const changePage = (page_number: number) => {
  //   if (selectedProject)
  //     navigate(
  //       `/editor/project/${selectedProject.id_project}/file/${selectedFile?.id_file}/page/${page_number}`,
  //     )
  // }

  useEffect(() => {
    const reloadFileKeys = async () => {
      if (selectedProject && search_text) {
        const resultKeys = await getSearchKeys({
          projectId: Number(selectedProject.id_project),
          search: search_text,
        })
        setKeys(resultKeys?.data?.result || [])
        setTotalKeyResult(resultKeys?.data?.count || 0)
        setIsLoading(false)
      }
    }

    setIsLoading(true)
    reloadFileKeys()
  }, [selectedProjectFiles, selectedProject, search_text]) // eslint-disable-line react-hooks/exhaustive-deps

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  const updateKey = async (translated_key: any, status: string) => {
    await updateFileKey({
      translatedKeyId: translated_key.id_translated_key,
      value: translated_key.value,
      status: status,
    })
    setPreventDoubleSubmit(false)
  }

  const postMissingKey = async (
    key: any,
    value: string,
    status: string,
    lang: string,
  ) => {
    const result = await postMissingFileKey({
      lang: lang,
      keyId: key.id_key,
      value: value,
      status: status,
    })
    setPreventDoubleSubmit(false)
    return result.data
  }

  const handleValidateKey = (translated_key: any, lang: string) => {
    setPreventDoubleSubmit(true)
    updateKey(translated_key, "valid")

    incrementProjectLangProgressions({
      lang: lang,
      status: "valid",
      fileId: selectedFile?.id_file,
    })
  }

  const handleUpdateKey = async (
    key: any,
    translated_key: any,
    lang: string,
  ) => {
    setPreventDoubleSubmit(true)
    if (key && !translated_key.id_translated_key) {
      const newKey = await postMissingKey(
        key,
        translated_key.value,
        "valid",
        lang,
      )
      key.translated_keys.target = newKey

      incrementProjectLangProgressions({
        lang: lang,
        status: "valid",
        fileId: selectedFile?.id_file,
        isNew: true,
      })
    } else {
      updateKey(translated_key, translated_key.status)
    }
  }

  const setHeight = (htmlId: string) => {
    let item = document.getElementById(htmlId)
    if (item) {
      item.style.height = item.scrollHeight + 2 + "px"
    }
  }

  useEffect(() => {
    keys?.map((key: any, index: number) => {
      setHeight("fieldId" + index)
      return key
    })
  }, [keys])

  return (
    <>
      <div
        className="basic-container editor-container"
        style={{
          maxWidth: "100%",
          padding: "0 1.5rem 1rem",
          overflow: "auto",
        }}
      >
        {!isLoading && keys && (
          <>
            {totalKeyResult} résultats
            {keys?.map((key: any, index: number) => (
              <div className="full-width key-item flex-column">
                <div className="flex-row full-width">
                  <div
                    className="key-name flex-1 flex-row flex-center text-ellipsis"
                    style={{
                      marginBottom: "0.5rem",
                      fontWeight: "600",
                      height: 24,
                    }}
                  >
                    <div
                      className="flex-column flex-center hide-phone"
                      style={{ width: 28, height: 24, marginRight: "0.75rem" }}
                    >
                      <span
                        className={"msymbol material-symbols-outlined "}
                        style={{
                          fontSize: 22,
                          opacity: 0.35,
                        }}
                      >
                        vpn_key
                      </span>
                    </div>

                    <p
                      style={{
                        fontSize: "0.85rem",
                        width: "100%",
                        marginRight: "0.5rem",
                      }}
                      className="text-ellipsis"
                    >
                      {key.translated_keys.name}
                    </p>
                  </div>

                  <div className="key-name">
                    {key?.status === "valid" && (
                      <div className="flex-row">
                        <div
                          className="success-badge flex-row flex-center"
                          style={{
                            padding: "0px 10px",
                            height: 24,
                            fontSize: 14,
                            borderRadius: 6,
                            fontWeight: "bold",
                          }}
                        >
                          {t("translationEditor.valid_key")}
                        </div>
                      </div>
                    )}

                    {key?.status === "source_modified" && (
                      <div className="flex-row">
                        <div
                          className="orange-badge flex-row flex-center"
                          style={{
                            padding: "0px 10px",
                            height: 24,
                            fontSize: 14,
                            borderRadius: 6,
                            fontWeight: "bold",
                          }}
                        >
                          {t("translationEditor.modified_source_key")}
                        </div>
                      </div>
                    )}

                    {key?.status === "ai_generated" && (
                      <div className="flex-row">
                        <div
                          className="warn-badge flex-row flex-center"
                          style={{
                            padding: "0px 10px",
                            height: 24,
                            fontSize: 14,
                            borderRadius: 6,
                            fontWeight: "bold",
                          }}
                        >
                          {t("translationEditor.ai_generated_key")}
                        </div>
                        <button
                          className="button is-bordered"
                          style={{
                            padding: "0px 10px",
                            fontSize: 14,
                            minHeight: "unset",
                            height: 24,
                            marginLeft: "0.5rem",
                          }}
                          onClick={() => {
                            if (
                              key?.status &&
                              selectedLangs &&
                              selectedLangs[0]
                            ) {
                              key.status = "valid"
                              handleValidateKey(key, selectedLangs[0].lang)
                            }
                          }}
                        >
                          {t("commons.validate")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex-column full-width">
                  <div className="flex-row" style={{ marginBottom: "0.5rem" }}>
                    <div
                      className="flex-column flex-center"
                      style={{ height: 40 }}
                    >
                      <span
                        className={getFlagClass(key.source_key.lang)}
                        style={{
                          marginRight: "0.75rem",
                          fontSize: 20,
                          opacity: 0.25,
                        }}
                      ></span>
                    </div>

                    <div
                      className="flex-row bordered-div half-opacity flex-1"
                      style={{
                        lineHeight: "1.35rem",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {key?.source_key?.value}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div
                      className="flex-column flex-center"
                      style={{ height: 40 }}
                    >
                      <span
                        className={getFlagClass(key.lang)}
                        style={{
                          marginRight: "0.75rem",
                          fontSize: 20,
                        }}
                      ></span>
                    </div>
                    <div
                      className="flex-row bordered-div flex-1"
                      style={{
                        // marginLeft: "0.5rem",
                        fontSize: 16,
                        lineHeight: "1.35rem",
                        minHeight: "1.35rem",
                      }}
                      onBlur={(e) => {
                        if (key?.status && selectedLangs && selectedLangs[0]) {
                          let updatedKey = key || {}
                          updatedKey.value = e.target.innerText
                          handleUpdateKey(
                            key,
                            updatedKey,
                            selectedLangs[0].lang,
                          )
                        }
                      }}
                      contentEditable="true"
                      suppressContentEditableWarning={true}
                    >
                      {key.value}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* <Paginator
              currentPage={Number(page || 1)}
              totalItem={totalKeyResult}
              itemPerPage={50}
              changeCurrentPage={changePage}
            /> */}
          </>
        )}
      </div>
    </>
  )
}

export default SearchResult
