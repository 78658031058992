import React, { CSSProperties, useMemo } from "react"
import { Trans, useTranslation } from "react-i18next"

type Props = {
  premiumTier: number
  style?: CSSProperties
  noPrice?: boolean
  children: React.ReactNode
}

export const PremiumItem = ({
  premiumTier,
  style,
  noPrice = false,
  children,
}: Props) => {
  const { t } = useTranslation()

  const itemColor: string = useMemo(() => {
    switch (premiumTier) {
      // green : return "#a335ee"
      case 1:
        return "#A335EE"
      case 2:
        return "#A335EE"
      case 3:
        return "#A335EE"
      case 4:
        return "#f39c12"
      default:
        return "#cccccc"
    }
  }, [premiumTier])

  const itemPrice: string = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return "19"
      case 2:
        return "39"
      case 3:
        return "69"
      case 4:
        return "139"
      default:
        return "0"
    }
  }, [premiumTier])

  const realItemPrice: string = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return "24"
      case 2:
        return "49"
      case 3:
        return "89"
      case 4:
        return "199"
      default:
        return "0"
    }
  }, [premiumTier])

  const sourceKeyAmount: number = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return 5000
      case 2:
        return 25000
      case 3:
        return 50000
      case 4:
        return 200000
      default:
        return 1000
    }
  }, [premiumTier])

  const translatedTokensAmount: number = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return 50000
      case 2:
        return 150000
      case 3:
        return 300000
      case 4:
        return 1000000
      default:
        return 5000
    }
  }, [premiumTier])

  const langAmount: number = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return 2
      case 2:
        return 5
      case 3:
        return 10
      // case 4:
      //   return "unlimited"
      default:
        return 1
    }
  }, [premiumTier])

  const mensualCredit: number = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return 50000
      case 2:
        return 150000
      case 3:
        return 300000
      case 4:
        return 1000000
      default:
        return 0
    }
  }, [premiumTier])

  const projectLimit: number = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return 2
      case 2:
        return 3
      case 3:
        return 5
      case 4:
        return 50
      default:
        return 1
    }
  }, [premiumTier])

  const traducerLimit: number = useMemo(() => {
    switch (premiumTier) {
      case 1:
        return 0
      case 2:
        return 0
      case 3:
        return 1
      case 4:
        return 5
      default:
        return 0
    }
  }, [premiumTier])

  return (
    <div
      className={
        "white-box no-padding premium-item flex-column flex-1 premium-item-tier-" +
        premiumTier
      }
      style={
        premiumTier > 2
          ? { ...style, borderColor: itemColor + "44" }
          : { ...style }
      }
    >
      <div
        className="premium-item-header full-width flex-column "
        style={
          premiumTier > 2
            ? { background: itemColor + "22" }
            : { background: "rgba(128,128,128, 0.1)" }
        }
      >
        {noPrice && (
          <h3
            className="price-title flex-row half-opacity"
            style={{ alignItems: "center", fontSize: 14 }}
          >
            {t("credits.account_type")}
          </h3>
        )}
        <h3
          className="tier-title flex-row "
          style={{ color: itemColor, lineHeight: "27px", fontSize: "20px" }}
        >
          {premiumTier === 0 && t("credits.free")}
          {premiumTier === 1 && "Starter"}
          {premiumTier === 2 && "Pro"}
          {premiumTier === 3 && "Teams"}
          {premiumTier === 4 && "Business"}
        </h3>
        {!noPrice && (
          <>
            {premiumTier > 0 ? (
              <h3
                className="price-title flex-row"
                style={{ alignItems: "center" }}
              >
                <span
                  style={{
                    fontSize: 22,
                    marginRight: "0.5rem",
                    marginTop: "0.1rem",
                    marginBottom: "-0.1rem",
                    opacity: 0.5,
                    textDecoration: "line-through",
                  }}
                >
                  &nbsp;{realItemPrice}€&nbsp;
                </span>
                {itemPrice}€
                <span
                  style={{
                    fontSize: 18,
                    marginRight: "0.5rem",
                    marginTop: "0.2rem",
                    marginBottom: "-0.2rem",
                  }}
                >
                  {" "}
                  &nbsp;/&nbsp;{t("credits.month")}
                </span>
              </h3>
            ) : (
              <h3 className="price-title flex-row">{t("credits.free")}</h3>
            )}
          </>
        )}
      </div>

      <hr
        style={
          premiumTier > 2
            ? { margin: 0, background: itemColor + "44" }
            : { margin: 0 }
        }
      />

      {premiumTier > 0 && (
        <ul>
          {/* <hr style={{ margin: "0.75rem 0", opacity: 0.5 }} /> */}
          <li style={{ fontWeight: 700 }}>
            <Trans>
              {t("credits.mensual_credit", {
                amount: Number(translatedTokensAmount).toLocaleString(),
              })}
            </Trans>
          </li>
          <li
            className="no-color"
            style={{
              fontWeight: "normal",
              fontStyle: "italic",
              opacity: 0.5,
              fontSize: 12,
            }}
          >
            <Trans>
              {t("credits.mensual_credit_words", {
                amount:
                  "~" +
                  Number((translatedTokensAmount * 3) / 4).toLocaleString(),
              })}
            </Trans>
          </li>
        </ul>
      )}

      {premiumTier >= 1 && <hr style={{ margin: 0 }} />}

      <ul>
        <div
          className="flex-row"
          style={{
            margin: 0,
            marginBottom: "0.5rem",
            alignItems: "center",
            opacity: 0.5,
          }}
        >
          <div
            className="flex-column flex-center"
            style={{
              width: "24px",
              height: "24px",
              background: itemColor + "22",
              marginRight: "0.5rem",
              borderRadius: "50%",
            }}
          >
            <span
              className="msymbol material-symbols-outlined"
              style={{ fontSize: 16, color: itemColor }}
            >
              file_copy
            </span>
          </div>
          <div
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              fontSize: "0.85rem",
            }}
          >
            {t("translationEditor.title")}
          </div>
        </div>

        {/* <hr style={{ margin: "0.75rem 0", opacity: 0.5 }} /> */}

        {/* {premiumTier >= 4 ? (
        ) : (
          <li style={{ fontWeight: 700 }}>
            <Trans>
              {t("credits.output_lang_limit", { amount: langAmount })}
            </Trans>
          </li>
        )} */}

        <li>
          <Trans>
            {t("credits.source_keys", {
              amount: sourceKeyAmount.toLocaleString(),
            })}
          </Trans>
        </li>

        <li>
          <Trans>
            {t("credits.project_limit", {
              count: projectLimit,
            })}
          </Trans>
        </li>
        {premiumTier >= 3 && (
          <li>
            <Trans>
              {t("credits.traducer_accounts", {
                count: traducerLimit,
              })}
            </Trans>
          </li>
        )}
        {/* 
        <li style={{ color: itemColor, fontWeight: 700 }}>
          {t("credits.unlimited_output_lang")}
        </li> */}

        {/* <li>{t("credits.api_keys", { amount: apiKeyAmount })}</li> */}
      </ul>

      {/* {premiumTier >= 3 && <hr style={{ margin: 0 }} />}
      <ul className="flex-1">
        {premiumTier >= 3 && <li>{t("credits.api_key_management")}</li>}
        {premiumTier >= 4 && <li>{t("credits.traducer_lang_management")}</li>}
      </ul> */}

      <div className="flex-1"></div>

      {children}
    </div>
  )
}

export default PremiumItem
