import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

import AnimatedLoader from "../../components/AnimatedLoader"

import { Link } from "react-router-dom"
import { AuthContext } from "../../../context/Auth"
import { PaymentContext } from "../../../context/Payment"

import PaymentMethodImage from "../../components/PaymentMethodImage"
import { PremiumItem } from "../../components/PremiumItem"
import posthog from "posthog-js"
import ReactGA from "react-ga4"

export const PremiumAccount = () => {
  const { t } = useTranslation()
  const { userAccountTier } = useContext(AuthContext)
  const {
    preventDoubleSubmit,
    actionRef,
    subscriptionStatus,
    nextInvoiceDate,

    paymentMethods,
    userAddress,

    setPreventDoubleSubmit,
    setActionRef,

    reactivateSub,
    cancelDowngradeSub,
  } = useContext(PaymentContext)

  useEffect(() => {
    document.title = t("credits.premium_account")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/billing/premium-account",
        title: "Premium Account",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleReactivateSubscription = () => {
    setPreventDoubleSubmit(true)
    reactivateSub()
  }

  const handleCancelDowngradeSubscription = () => {
    setPreventDoubleSubmit(true)
    cancelDowngradeSub()
  }

  return (
    <>
      <div className="full-width premium-infos">
        <div className="subscription-infos">
          <p className="infos-credits" style={{ marginBottom: "0.25rem" }}>
            <b className="infos-creits-label">{t("credits.account_type")}</b>
          </p>
          <p className="balance-text">
            <span className="balance-amount">Tier {userAccountTier}</span>
          </p>

          <p className="infos-credits" style={{ marginBottom: "0.5rem" }}>
            <b className="infos-creits-label">
              {t("credits.subscription_status")}
            </b>
          </p>
          
          <p className="balance-text" style={{ marginBottom: "1.5rem" }}>
            <span
              className={
                subscriptionStatus === "active" ? "success-badge" : "warn-badge"
              }
              style={{
                margin: 0,
                fontSize: 22,
                padding: "2px 8px",
              }}
            >
              {subscriptionStatus}
            </span>
          </p>

          <p className="infos-credits" style={{ marginBottom: 0 }}>
            {subscriptionStatus === "active" && (
              <b className="infos-creits-label">{t("credits.renewed_at")}</b>
            )}
            {subscriptionStatus === "not_renewed" && (
              <b className="infos-creits-label">{t("credits.expired_at")}</b>
            )}
            {subscriptionStatus === "pending_downgrade" && (
              <b className="infos-creits-label">{t("credits.updated_at")}</b>
            )}
          </p>
          <p className="balance-text">
            <span className="balance-amount" style={{ fontSize: 28 }}>
              {new Date(nextInvoiceDate || "").toLocaleDateString()}
            </span>
          </p>

          <p className="infos-credits" style={{ marginBottom: 0 }}>
            <b className="infos-creits-label">{t("credits.payment_method")}</b>
          </p>
          {paymentMethods
            ?.filter((item: any) => item.is_default)
            ?.map((item: any) => (
              <div
                className="flex-row"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  margin: "0.5rem 0 0",
                  alignItems: "center",
                }}
              >
                <PaymentMethodImage
                  name={item.brand ? item.brand : item.type}
                  style={{ height: "40px", marginRight: "0.75rem" }}
                />
                <div className="flex-column flex-1">
                  <div
                    style={{
                      minWidth: 150,
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      fontSize: 14,
                      lineHeight: "20px",
                    }}
                  >
                    {!item.brand && item.type}
                    {item.last4 && " •••• "}
                    {item.last4}
                  </div>
                  <div
                    className="expires-at-infos"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginRight: 0,
                      marginLeft: 0,

                      opacity: 0.5,
                      justifyContent: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    {item.exp_month && t("credits.expires_at") + " "}
                    {item?.exp_month && item.exp_month < 10 && "0"}
                    {item.exp_month}
                    {item.exp_month && "/"}
                    {item.exp_year}
                  </div>
                </div>
              </div>
            ))}

          <Link
            to="/billing/invoices"
            style={{
              marginTop: "1rem",
              marginBottom: "1.5rem",
            }}
            className="button is-bordered"
          >
            {t("credits.change_payment_method")}
          </Link>

          <p className="infos-credits" style={{ marginBottom: "0.5rem" }}>
            <b className="infos-creits-label">{t("credits.billing_address")}</b>
          </p>

          <div
            className="flex-1"
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 18,
            }}
          >
            <p>{userAddress?.name}</p>
            <p>{userAddress?.line1}</p>
            {userAddress?.line2 && <p>{userAddress?.line2}</p>}
            <p>
              {userAddress?.postal_code} {userAddress?.city}
            </p>
            <p>
              {userAddress?.country} {userAddress?.state}
            </p>
          </div>

          <p
            className="infos-credits"
            style={{ marginTop: "1.5rem", marginBottom: 0 }}
          >
            <b className="infos-creits-label">
              {t("credits.change_subscription")}
            </b>
          </p>

          <div className="flex-column" style={{ maxWidth: 300 }}>
            {subscriptionStatus === "active" && (
              <>
                <Link
                  style={{ margin: "0.75rem 0", marginBottom: 0 }}
                  className="button is-primary-gradient"
                  to="/billing/offers"
                  // disabled={preventDoubleSubmit}
                >
                  {t("credits.change_offer")}
                </Link>

                <Link
                  to="/billing/resiliate-subscription"
                  style={{ margin: "0.75rem 0" }}
                  className="button is-bordered"
                >
                  {actionRef === "resiliateSubscription" ? (
                    <AnimatedLoader smallNoContainer={true} />
                  ) : (
                    t("credits.resiliate_sub")
                  )}
                </Link>
              </>
            )}
            {subscriptionStatus === "not_renewed" && (
              <button
                style={{ margin: "0.75rem 0" }}
                className="button is-primary-gradient"
                disabled={preventDoubleSubmit}
                onClick={() => {
                  setActionRef("reactivateSubscription")
                  handleReactivateSubscription()
                }}
              >
                {actionRef === "reactivateSubscription" ? (
                  <AnimatedLoader smallNoContainer={true} />
                ) : (
                  t("credits.resactivate_sub")
                )}
              </button>
            )}
            {subscriptionStatus === "pending_downgrade" && (
              <button
                style={{ margin: "0.75rem 0" }}
                className="button is-primary-gradient"
                disabled={preventDoubleSubmit}
                onClick={() => {
                  setActionRef("cancelChangeOffer")
                  handleCancelDowngradeSubscription()
                }}
              >
                {actionRef === "cancelChangeOffer" ? (
                  <AnimatedLoader smallNoContainer={true} />
                ) : (
                  t("credits.cancel_change_offer")
                )}
              </button>
            )}
          </div>
        </div>
        <div className="account-tier-infos">
          <PremiumItem
            style={{
              width: "100%",
            }}
            premiumTier={userAccountTier}
          >
            {subscriptionStatus === "active" && (
              <Link
                style={{ margin: "0.75rem" }}
                className="button is-primary-gradient"
                to="/billing/offers"
                // disabled={preventDoubleSubmit}
              >
                {t("credits.change_offer")}
              </Link>
            )}
            {subscriptionStatus === "not_renewed" && (
              <button
                style={{ margin: "0.75rem" }}
                className="button is-primary-gradient"
                disabled={preventDoubleSubmit}
                onClick={() => {
                  setActionRef("reactivateSubscription")
                  handleReactivateSubscription()
                }}
              >
                {actionRef === "reactivateSubscription" ? (
                  <AnimatedLoader smallNoContainer={true} />
                ) : (
                  t("credits.resactivate_sub")
                )}
              </button>
            )}
            {subscriptionStatus === "pending_downgrade" && (
              <button
                style={{ margin: "0.75rem" }}
                className="button is-primary-gradient"
                disabled={preventDoubleSubmit}
                onClick={() => {
                  setActionRef("cancelChangeOffer")
                  handleCancelDowngradeSubscription()
                }}
              >
                {actionRef === "cancelChangeOffer" ? (
                  <AnimatedLoader smallNoContainer={true} />
                ) : (
                  t("credits.cancel_change_offer")
                )}
              </button>
            )}
          </PremiumItem>
        </div>
      </div>
    </>
  )
}

export default PremiumAccount
