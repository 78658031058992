import posthog from "posthog-js"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { cancelSubscription } from "../../../services/subscriptions"
import AnimatedLoader from "../../components/AnimatedLoader"
import { PaymentContext } from "../../../context/Payment"
import ReactGA from "react-ga4"

export const ResiliateSubscription = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { refreshCredits } = useContext(PaymentContext)
  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState(false)
  const [actionRef, setActionRef] = useState<string>()
  const [cancelReason, setCancelReason] = useState<string>()

  useEffect(() => {
    document.title = t("credits.resiliate_sub")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/billing/resiliate-sub",
        title: "Resiliate Sub",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const cancelSub = async () => {
    if (cancelReason) {
      try {
        await cancelSubscription(cancelReason)
        await refreshCredits()
        navigate("/billing/premium-account")
      } catch (error) {
        navigate("/billing/premium-account")
      }
    }
  }

  const handleCancelSubscription = () => {
    if (cancelReason) {
      setPreventDoubleSubmit(true)
      cancelSub()
    }
  }

  const cancelReasons = [
    "unused",
    "low_quality",
    "too_expensive",
    "missing_features",
    "too_complex",
    "switched_service",
    "customer_service",
    "other",
  ]
  return (
    <>
      <div className="flex-column ResiliateSubscription full-width">
        <h2
          className="sub-title"
          style={{
            margin: 0,
            marginBottom: "1rem",
          }}
        >
          {t("credits.resiliate_sub")}
        </h2>

        <ul className="full-width">
          {cancelReasons.map((reason) => (
            <li
              onClick={() => {
                setCancelReason(reason)
              }}
            >
              <div
                className={
                  "indicator " + (cancelReason === reason ? "active" : "")
                }
              >
                {cancelReason === reason && (
                  <span className="msymbol material-symbols-outlined">
                    check
                  </span>
                )}
              </div>
              {t("credits.cancel_reason." + reason)}
            </li>
          ))}
        </ul>

        <div className="full-width">
          <Link
            style={{ margin: "1rem 0", marginRight: "0.5rem" }}
            className="button is-primary-gradient"
            to="/billing/premium-account"
          >
            <span
              className="msymbol material-symbols-outlined"
              style={{ marginLeft: "-0.5rem", marginRight: "0.5rem" }}
            >
              keyboard_arrow_left
            </span>
            {t("commons.back")}
          </Link>
          <button
            style={{ margin: "1rem 0", marginLeft: "0.5rem" }}
            className="button is-bordered"
            disabled={preventDoubleSubmit || !cancelReason}
            onClick={() => {
              setActionRef("resiliateSubscription")
              handleCancelSubscription()
            }}
          >
            {actionRef === "resiliateSubscription" ? (
              <AnimatedLoader smallNoContainer={true} />
            ) : (
              t("credits.resiliate_sub")
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default ResiliateSubscription
