import { flattie } from "flattie"
import { getEncoding } from "js-tiktoken"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useParams } from "react-router-dom"
import { TranslationEditorContext } from "../../../context/TranslationEditor"
import {
  deleteFile,
  getDownloadFileByLang,
  getMissingKeysByFileLang,
  postImportFile,
} from "../../../services/files"
import { deleteProjectLang } from "../../../services/projects"
import { postTranslateChunk } from "../../../services/translate"
import AnimatedLoader from "../../components/AnimatedLoader"
import Dropdown from "../../components/Dropdown"
import Modal from "../../components/Modal"
import ModalConfirmation from "../../components/ModalConfirmation"
import ModalLimitReached from "../../components/ModalLimitReached"
import { AuthContext } from "../../../context/Auth"
import {
  getProjectTraducers,
  postTraducterInvitation,
} from "../../../services/traducers"

const encoder = getEncoding("o200k_base")

export const ProjectFiles = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let { project_id } = useParams()

  const { userAccountTier } = useContext(AuthContext)

  const {
    refreshProjects,
    projects,
    projectsIsLoaded,
    selectedProject,
    selectedProjectFiles,
    selectedLangs,
    setSelectedProject,
    setSelectedFile,
    setModalProjectLangIsShown,
    preventDoubleSubmit,
    setPreventDoubleSubmit,
    selectedOutputProjectLangs,
    selectedInputProjectLangs,

    toggleSelectedLangs,
    projectLangProgressions,

    setSelectedLangs,
  } = useContext(TranslationEditorContext)

  useEffect(() => {
    if (projectsIsLoaded && projects && !selectedProject && project_id) {
      setSelectedLangs(undefined)
      setSelectedProject(
        projects.find((i) => i.id_project === Number(project_id)),
      )
    }
  }, [
    projectsIsLoaded,
    projects,
    selectedProject,
    project_id,
    setSelectedProject,
    setSelectedLangs,
  ])

  const [selectedPart, setSelectedPart] = useState<string>("lang")

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  const langCount = useCallback(
    (lang: string) => {
      const projectLang = projectLangProgressions?.find((i) => i.lang === lang)
      if (projectLang) {
        return projectLang
      } else {
        return { valid: 0, ai_generated: 0, source_modified: 0 }
      }
    },
    [projectLangProgressions],
  )

  const langFileCount = useCallback(
    (lang: string, file_id: number) => {
      const projectLang = projectLangProgressions?.find((i) => i.lang === lang)
      if (projectLang) {
        const currentFile = projectLang.files.find(
          (file: any) => file.file_id === file_id,
        )
        if (currentFile) {
          return currentFile
        } else {
          return { valid: 0, ai_generated: 0, source_modified: 0 }
        }
      } else {
        return { valid: 0, ai_generated: 0, source_modified: 0 }
      }
    },
    [projectLangProgressions],
  )

  const selectedLangCount: () => {
    valid: number
    ai_generated: number
    source_modified: number
  } = useCallback(() => {
    return selectedLangs?.reduce(
      (acc, lang) => {
        const projectLang = projectLangProgressions?.find(
          (i) => i.lang === lang.lang,
        )
        if (projectLang) {
          return {
            valid: acc.valid + projectLang.valid,
            ai_generated: acc.ai_generated + projectLang.ai_generated,
            source_modified: acc.source_modified + projectLang.source_modified,
          }
        } else {
          return {
            valid: acc.valid + 0,
            ai_generated: acc.ai_generated + 0,
            source_modified: acc.source_modified + 0,
          }
        }
      },
      {
        valid: 0,
        ai_generated: 0,
        source_modified: 0,
      },
    )
  }, [projectLangProgressions, selectedLangs])

  const selectedLangFileCount = useCallback(
    (file_id: number) => {
      return selectedLangs?.reduce(
        (acc, lang) => {
          const projectLang = projectLangProgressions?.find(
            (i) => i.lang === lang.lang,
          )
          if (projectLang) {
            const currentFile = projectLang.files.find(
              (file: any) => file.file_id === file_id,
            )
            if (currentFile) {
              return {
                valid: acc.valid + currentFile.valid,
                ai_generated: acc.ai_generated + currentFile.ai_generated,
                source_modified:
                  acc.source_modified + currentFile.source_modified,
              }
            } else {
              return {
                valid: acc.valid + 0,
                ai_generated: acc.ai_generated + 0,
                source_modified: acc.source_modified + 0,
              }
            }
          } else {
            return {
              valid: acc.valid + 0,
              ai_generated: acc.ai_generated + 0,
              source_modified: acc.source_modified + 0,
            }
          }
        },
        {
          valid: 0,
          ai_generated: 0,
          source_modified: 0,
        },
      )
    },
    [projectLangProgressions, selectedLangs],
  )

  const totalMissingKeys = useMemo(() => {
    if (
      selectedProject &&
      selectedOutputProjectLangs &&
      projectLangProgressions
    ) {
      return selectedOutputProjectLangs.reduce(
        (acc: number, lang: any) =>
          acc +
          Number(
            Number(selectedProject?.keyCount || 0) -
              (langCount(lang?.lang)?.ai_generated +
                langCount(lang?.lang)?.source_modified +
                langCount(lang?.lang)?.valid),
          ),
        0,
      )
    } else {
      return 0
    }
  }, [
    selectedOutputProjectLangs,
    selectedProject,
    projectLangProgressions,
    langCount,
  ])

  const totalModifiedKeys = useMemo(() => {
    if (selectedOutputProjectLangs && projectLangProgressions) {
      return selectedOutputProjectLangs.reduce(
        (acc: number, lang: any) =>
          acc + Number(langCount(lang?.lang)?.source_modified),
        0,
      )
    } else {
      return 0
    }
  }, [selectedOutputProjectLangs, projectLangProgressions, langCount])

  useEffect(() => {
    setSelectedFile(undefined)
  }, [])

  const downloadFile = async (file: any, lang: string) => {
    // const { myData } = this.state
    const result = await getDownloadFileByLang({
      fileId: file.id_file,
      lang: lang,
    })

    // create file in browser
    const fileName = file.name
    const json = JSON.stringify(result.data, null, 2)
    const blob = new Blob([json], { type: "application/json" })
    const href = URL.createObjectURL(blob)

    // create "a" HTLM element with href to file
    const link = document.createElement("a")
    link.href = href
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  const handleDeleteFile = async (file_id: number) => {
    await deleteFile({
      fileId: file_id,
    })
    refreshProjects()
    setPreventDoubleSubmit(false)
  }

  const handleDeleteLang = async (project_lang_id: number) => {
    await deleteProjectLang({
      projectLangId: project_lang_id,
    })
    refreshProjects()
    setPreventDoubleSubmit(false)
  }

  const [modalFileTranslationIsShown, setModalFileTranslationIsShown] =
    useState<boolean>(false)
  const [modalAllTranslationIsShown, setModalAllTranslationIsShown] =
    useState<boolean>(false)
  const [modalTokenLimitIsShown, setModalTokenLimitIsShown] =
    useState<boolean>(false)
  const [currentChunk, setCurrentChunk] = useState<any>()
  const [currentTranslatedFile, setCurrentTranslatedFile] = useState<any>()
  const [currentTranslatedLangs, setCurrentTranslatedLangs] = useState<any[]>()
  const [fileCost, setFileCost] = useState<number>()
  const [totalCost, setTotalCost] = useState<number>(0)

  const [isInterruptedState, setIsInterruptedState] = useState<boolean>()
  const isInterrupted = useRef<boolean>(false)

  const translateFileChunk = async (
    inputData: string,
    fileName: string,
    chunkIndex: number,
    totalChunks: number,
    outputLang: string,
    inputLang: string,
  ) => {
    try {
      const result = await postTranslateChunk({
        inputData,
        inputLang: inputLang,
        outputLang: outputLang,
        filename: fileName,
        chunkIndex: chunkIndex,
        totalChunks: totalChunks,
      })

      return { data: result.data.output, cost: result.data.cost }
    } catch (error: any) {
      if (error?.response?.data === "Not enough credits") {
        setIsInterruptedState(true)
        setModalFileTranslationIsShown(false)
        setModalAllTranslationIsShown(false)
        setPreventDoubleSubmit(false)
        setModalTokenLimitIsShown(true)
      } else {
        console.error(error?.response?.data)
      }
    }
  }

  const translateFileChunks = async (
    lang: any,
    fileName: string,
    fileChunks: any[],
  ) => {
    if (selectedInputProjectLangs && selectedInputProjectLangs[0]) {
      let chunkIndex = 0
      let fileCost = 0
      let chunkResults: Object[] = []

      for (let chunk of fileChunks) {
        chunkIndex++
        setCurrentChunk({
          filename: fileName,
          totalChunks: fileChunks.length,
          index: chunkIndex,
        })
        let result = await translateFileChunk(
          JSON.stringify(chunk, null, 2),
          fileName,
          chunkIndex,
          fileChunks.length,
          lang.lang,
          selectedInputProjectLangs[0]?.lang,
        )

        try {
          fileCost += result?.cost
          chunkResults.push(JSON.parse(result?.data))
        } catch (error) {
          // Retry only once before thowing error (Not the best but working)
          let result = await translateFileChunk(
            JSON.stringify(chunk, null, 2),
            fileName,
            chunkIndex,
            fileChunks.length,
            lang.lang,
            selectedInputProjectLangs[0]?.lang,
          )
          fileCost += Number(result?.cost || 0)
          chunkResults.push(JSON.parse(result?.data))
        }
      }

      return { chunkResults, fileCost }
    }
  }

  const createFileChunks = (inputData: string, fileName: string) => {
    let chunks = []
    if (fileName.split(".").pop() === "json") {
      let jsonData
      try {
        jsonData = flattie(JSON.parse(inputData))
      } catch (error) {
        throw new Error(
          `Can't parse ${fileName} as JSON, please remove isJson from champo.config.json (this will split file by lines, instead of keys)`,
        )
      }
      let chunk: any = {}
      let lastKeyAdded: string | null = ""

      for (const [key, value] of Object.entries(jsonData)) {
        const chunkTokens = encoder.encode(
          JSON.stringify(chunk, null, 2),
        ).length

        if (chunkTokens > 2000) {
          let value
          if (lastKeyAdded) {
            value = chunk[lastKeyAdded]
            delete chunk[lastKeyAdded]
          }

          chunks.push(chunk)
          chunk = {}

          if (lastKeyAdded !== null) {
            chunk[lastKeyAdded] = value
          }
          lastKeyAdded = null
        } else if (chunkTokens > 1000) {
          chunks.push(chunk)
          chunk = {}
          chunk[key] = value
          lastKeyAdded = null
        } else {
          lastKeyAdded = key
          chunk[key] = value
        }
      }

      chunks.push(chunk)
    }

    return chunks
  }

  const generateMissingFileKeys = async ({
    lang,
    file,
    noRefresh,
    costIncrement,
  }: {
    lang: any
    file: any
    noRefresh?: boolean
    costIncrement?: number
  }) => {
    setCurrentChunk({})
    setCurrentTranslatedFile(file)
    if (!noRefresh) {
      setModalFileTranslationIsShown(true)
      setPreventDoubleSubmit(true)
    }

    if (selectedInputProjectLangs && selectedInputProjectLangs[0]) {
      const result = await getMissingKeysByFileLang({
        fileId: file.id_file,
        lang: lang.lang,
      })
      const fileData = JSON.stringify(result.data, null, 2)

      const chunks = createFileChunks(fileData, file.name)

      const translatedResult = await translateFileChunks(
        lang,
        file.name,
        chunks,
      )

      if (translatedResult?.chunkResults) {
        setFileCost(translatedResult?.fileCost)

        let translatedData: Object[] = []
        for (let chunk of translatedResult?.chunkResults) {
          translatedData = { ...translatedData, ...chunk }
        }

        const newkeys = Object.entries(translatedData).map(([key, value]) => {
          return {
            name: key,
            lang: lang.lang,
            value: value,
          }
        })

        let tranlatedChunks = []
        let indexChunk = 0
        for (let i = 0; i < newkeys.length; i += 50) {
          tranlatedChunks.push(
            newkeys.slice(indexChunk * 50, (indexChunk + 1) * 50),
          )
          indexChunk += 1
        }

        for (let chunk of tranlatedChunks) {
          await postImportFile({
            lang: lang.lang, // temp
            projectName: selectedProject.name,
            is_source_lang: false,
            filename: file.name,
            newkeys: chunk,
          })
        }

        setCurrentChunk(undefined)

        if (!noRefresh) {
          refreshProjects()
          setPreventDoubleSubmit(false)
        }
        return translatedResult?.fileCost
      } else {
        return 0
      }
    }
    return 0
  }

  const generateAllMissingKey = async () => {
    try {
      setPreventDoubleSubmit(true)
      let currentCost = 0
      setTotalCost(0)
      setModalAllTranslationIsShown(true)
      setCurrentTranslatedLangs([])
      if (selectedOutputProjectLangs && projectLangProgressions) {
        let tranlatedLangs = []
        for (let lang of selectedOutputProjectLangs.filter(
          (l) =>
            Number(
              Number(selectedProject?.keyCount || 0) -
                (langCount(l?.lang)?.ai_generated + langCount(l?.lang)?.valid),
            ) > 0,
        )) {
          tranlatedLangs.push({ status: "pending", lang: lang.lang })
        }

        for (let lang of selectedOutputProjectLangs.filter(
          (l) =>
            Number(
              Number(selectedProject?.keyCount || 0) -
                (langCount(l?.lang)?.ai_generated + langCount(l?.lang)?.valid),
            ) > 0,
        )) {
          if (isInterrupted.current === false) {
            let updatedLangs = (currentTranslatedLangs ?? tranlatedLangs).map(
              (i) => {
                if (i.lang === lang.lang) i.status = "started"
                return i
              },
            )
            setCurrentTranslatedLangs(updatedLangs)

            if (
              selectedProjectFiles &&
              selectedOutputProjectLangs.reduce(
                (acc: number, l: any) =>
                  acc +
                  Number(
                    Number(selectedProject?.keyCount || 0) -
                      (langCount(l?.lang)?.ai_generated +
                        langCount(l?.lang)?.valid),
                  ),
                0,
              ) > 0
            ) {
              const files: any[] = []

              selectedProjectFiles.map((file) => {
                if (
                  Number(file.fileKeyCount || 0) -
                    (langFileCount(lang?.lang, file.id_file)?.ai_generated +
                      langFileCount(lang?.lang, file.id_file)?.valid) >
                  0
                ) {
                  files.push(file)
                }
                return file
              })

              for (let file of files) {
                if (isInterrupted.current === false) {
                  const fileCost = await generateMissingFileKeys({
                    lang: lang,
                    file,
                    noRefresh: true,
                    costIncrement: currentCost,
                  })
                  currentCost += fileCost
                }
              }
            }

            let updatedDoneLangs = (
              currentTranslatedLangs ?? tranlatedLangs
            ).map((i) => {
              if (i.lang === lang.lang) i.status = "done"
              return i
            })
            setCurrentTranslatedLangs(updatedDoneLangs)
          }
        }

        setTotalCost(currentCost)

        refreshProjects()
        setPreventDoubleSubmit(false)
      }
    } catch (error) {}
  }

  const [showLangDeleteConfirmation, setShowLangDeleteConfirmation] =
    useState(false)
  const [langToDelete, setLangToDelete] = useState<any>(undefined)

  const [showFileDeleteConfirmation, setShowFileDeleteConfirmation] =
    useState(false)
  const [fileToDelete, setFileToDelete] = useState<any>(undefined)

  // Traducers management
  const [modalTraducerInvitationIsShown, setModalTraducerInvitationIsShown] =
    useState<boolean>(false)
  const [mailInvitations, setMailInvitations] = useState<string[]>([""])

  const [traducers, setTraducers] = useState<any[]>()

  useEffect(() => {
    const getTraducers = async () => {
      return await getProjectTraducers({
        projectId: selectedProject.id_project,
      })
    }

    if (traducers === undefined && selectedPart === "traducers") {
      getTraducers().then((res: any) => {
        setTraducers(res.data.rows)
      })
    }
  }, [traducers, selectedProject, selectedPart])

  useEffect(() => {
    setTraducers(undefined)
  }, [selectedProject])

  const traducerLimit = useMemo(() => {
    if (userAccountTier === 3) {
      return 1
    } else if (userAccountTier === 4) {
      return 5
    } else {
      return 0
    }
  }, [userAccountTier])

  const inviteTraducer = async () => {
    try {
      const result = await postTraducterInvitation({
        mail: mailInvitations[0],
        projectId: selectedProject.id_project,
      })
      setPreventDoubleSubmit(false)
      setModalTraducerInvitationIsShown(false)
      setTraducers(undefined)
    } catch (error: any) {
      console.error(error?.response?.data)
      setPreventDoubleSubmit(false)
    }
  }

  return (
    <>
      <div
        className="basic-container editor-container"
        style={{
          maxWidth: "100%",
          overflow: "auto",
          minHeight: "100%",
        }}
      >
        <div className="flex-row full-width">
          <Link
            className="button is-bordered"
            style={{ paddingLeft: 8, marginBottom: "1rem" }}
            onClick={() => {
              setSelectedLangs(undefined)
              setSelectedProject(undefined)
            }}
            to={`/editor/project/${selectedProject?.id_project}/projects`}
          >
            <span
              className="msymbol material-symbols-outlined"
              style={{ marginRight: 8 }}
            >
              keyboard_arrow_left
            </span>
            {t("translationEditor.back_to_projects")}
          </Link>
        </div>

        <div
          className="flex-row full-width flex-column-tablet"
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          <div className="project-infos-half infos-left flex-column flex-1">
            {selectedInputProjectLangs?.map((lang, index) => (
              <div
                className={"flex-row project-lang-item"}
                style={{ minHeight: "100%", padding: "0.5rem" }}
              >
                <div
                  className={
                    "flex-row flex-center amount-icon-container primary"
                  }
                  style={{
                    // background: iconColor + "22",
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    marginRight: "0.75rem",
                    // opacity: 0.75,
                  }}
                >
                  <span
                    className="amount-icon"
                    style={{
                      fontSize: "32px",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 500,
                    }}
                  >
                    {selectedProject.name[0]}
                  </span>
                </div>
                <div
                  className="flex-column flex-1"
                  style={{ justifyContent: "center" }}
                >
                  <p
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: "normal",
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    <b>{selectedProject.name}</b>
                  </p>

                  <div className="flex-row">
                    <p
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        margin: 0,
                        fontSize: 14,
                      }}
                    >
                      <span
                        className={getFlagClass(lang?.lang)}
                        style={{ marginRight: "0.25rem" }}
                      ></span>
                      <span style={{ marginRight: "1rem" }}> {lang?.lang}</span>
                    </p>
                    <div className="flex-1"></div>
                    <p
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        margin: 0,
                        fontSize: 14,
                      }}
                    >
                      <b>{Number(langCount(lang?.lang)?.valid || 0)}</b>{" "}
                      {t("translationEditor.keys_lower")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="project-infos-half infos-right flex-column flex-1">
            {totalMissingKeys + totalModifiedKeys > 0 && (
              <div
                className={"flex-row project-lang-item glow-responsive"}
                style={{
                  width: "unset",
                  height: "unset",
                  minHeight: "100%",
                  padding: "0.5rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  isInterrupted.current = false
                  setIsInterruptedState(false)
                  generateAllMissingKey()
                }}
              >
                <div
                  className={
                    "flex-row flex-center amount-icon-container yellow-red"
                  }
                  style={{
                    // background: iconColor + "22",
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    marginRight: "0.75rem",
                    // opacity: 0.75,
                  }}
                >
                  <span
                    className="msymbol material-symbols-outlined amount-icon"
                    style={{ fontSize: "32px" }}
                  >
                    translate
                  </span>
                </div>
                <div
                  className="flex-column"
                  style={{ justifyContent: "center" }}
                >
                  <p style={{ fontSize: 16 }}>
                    <b className="yellow-red-text">{t("translationEditor.generate_all_translations")}</b>
                  </p>
                  <p style={{ fontSize: 14 }}>
                    {totalMissingKeys > 0 && (
                      <>
                        <b className="danger-text">{totalMissingKeys}</b>{" "}
                        {t("translationEditor.missing_strings")}
                      </>
                    )}
                    {totalModifiedKeys && totalMissingKeys ? ", " : ""}
                    {totalModifiedKeys > 0 && (
                      <>
                        <b className="orange-text">{totalModifiedKeys}</b>{" "}
                        {t("translationEditor.modified_source_keys_lower")}
                      </>
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex-row full-width">
          <h2
            className={
              "part-title " + (selectedPart === "lang" ? "active" : "")
            }
            style={{
              marginTop: "1rem",
              marginBottom: "2.5rem",
              marginRight: "2rem",
            }}
            onClick={() => {
              setSelectedPart("lang")
            }}
          >
            {t("translationEditor.target_langs")}
          </h2>

          <h2
            className={
              "part-title " + (selectedPart === "files" ? "active " : "")
            }
            style={{
              marginTop: "1rem",
              marginBottom: "2.5rem",
              marginRight: "2rem",
            }}
            onClick={() => {
              setSelectedPart("files")
            }}
          >
            {t("translationEditor.i18n_files")}
          </h2>

          <h2
            className={
              "part-title " + (selectedPart === "traducers" ? "active " : "")
            }
            style={{
              marginTop: "1rem",
              marginBottom: "2.5rem",
              marginRight: "2rem",
            }}
            onClick={() => {
              setSelectedPart("traducers")
            }}
          >
            {t("translationEditor.traducers")}
          </h2>
        </div>

        {selectedPart === "lang" && (
          <>
            <div
              className="flex-row full-width project-lang-grid"
              style={{ marginBottom: "2rem" }}
            >
              <div
                className="add-grid-item flex-column flex-center bordered-div"
                style={{ minHeight: 242 }}
                onClick={() => setModalProjectLangIsShown(true)}
              >
                <div className="plus-image">
                  <span className="msymbol material-symbols-outlined">add</span>
                </div>
                <h3 style={{ fontSize: 24, fontWeight: 700 }}>
                  {t("translationEditor.add_lang")}
                </h3>
              </div>

              {selectedOutputProjectLangs
                ?.sort(
                  (a, b) => langCount(b.lang)?.valid - langCount(a.lang)?.valid,
                )
                .map((lang, index) => (
                  <div className={"flex-column flex-center project-lang-item"}>
                    <div
                      className="pie animate lang-pie"
                      style={
                        {
                          "--w": "125px",
                          "--p":
                            (Number(langCount(lang?.lang)?.valid || 0) /
                              Number(selectedProject?.keyCount || 0)) *
                            100,
                          "--b": "8px",
                          "--c": "#7c3aed",
                        } as React.CSSProperties
                      }
                    >
                      <div className="flex-column flex-center">
                        <p
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "24px",
                            lineHeight: "24px",
                            margin: 0,
                            opacity: selectedProject?.keyCount > 0 ? 1 : 0.25,
                          }}
                        >
                          {selectedProject?.keyCount > 0
                            ? Number(
                                (Number(langCount(lang?.lang)?.valid || 0) /
                                  Number(selectedProject?.keyCount || 0)) *
                                  100,
                              ).toFixed()
                            : "- "}
                          %
                        </p>
                        <p
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "normal",
                            fontSize: 16,
                            margin: 0,
                          }}
                        >
                          <span
                            className={getFlagClass(lang?.lang)}
                            style={{ marginRight: "0.5rem" }}
                          ></span>
                          {lang?.lang}
                        </p>
                      </div>
                    </div>

                    <hr
                      className="full-width flex-column"
                      style={{ margin: "0.5rem 0" }}
                    />

                    <div className="flex-row full-width">
                      <div className="flex-1">
                        {t("translationEditor.valid_keys")}
                      </div>
                      <div
                        className={
                          "font-bold " +
                          (langCount(lang?.lang)?.valid > 0
                            ? "success-text"
                            : "half-opacity")
                        }
                      >
                        {langCount(lang?.lang)?.valid}
                      </div>
                    </div>
                    <div className="flex-row full-width">
                      <div className="flex-1">
                        {t("translationEditor.ai_generated_keys")}
                      </div>
                      <div
                        className={
                          "font-bold " +
                          (langCount(lang?.lang)?.ai_generated > 0
                            ? "warn-text"
                            : "half-opacity")
                        }
                      >
                        {langCount(lang?.lang)?.ai_generated}
                      </div>
                    </div>

                    <div className="flex-row full-width">
                      <div className="flex-1">
                        {t("translationEditor.modified_source_keys")}
                      </div>
                      <div
                        className={
                          "font-bold " +
                          (langCount(lang?.lang)?.source_modified > 0
                            ? "orange-text"
                            : "half-opacity")
                        }
                      >
                        {langCount(lang?.lang)?.source_modified}
                      </div>
                    </div>

                    <div className="flex-row full-width">
                      <div className="flex-1">
                        {t("translationEditor.missing_keys")}
                      </div>
                      <div
                        className={
                          "font-bold " +
                          (Number(selectedProject?.keyCount || 0) -
                            (langCount(lang?.lang)?.source_modified +
                              langCount(lang?.lang)?.ai_generated +
                              langCount(lang?.lang)?.valid) >
                          0
                            ? "danger-text"
                            : "half-opacity")
                        }
                      >
                        {Number(selectedProject?.keyCount || 0) -
                          (langCount(lang?.lang)?.source_modified +
                            langCount(lang?.lang)?.ai_generated +
                            langCount(lang?.lang)?.valid)}
                      </div>
                    </div>

                    <div
                      className="full-width flex-row"
                      style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                    >
                      <button
                        className={"button flex-1 is-bordered"}
                        onClick={() => {
                          if (
                            !(
                              selectedLangs &&
                              selectedLangs?.length >= 5 &&
                              !selectedLangs
                                ?.map((i) => i.lang)
                                .includes(lang.lang)
                            )
                          ) {
                            toggleSelectedLangs(lang.lang)
                          }
                        }}
                        disabled={
                          selectedLangs &&
                          selectedLangs?.length >= 5 &&
                          !selectedLangs?.map((i) => i.lang).includes(lang.lang)
                        }
                        style={{
                          padding: 0,
                          overflow: "hidden",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          className={
                            "flex-row flex-center border-right-1 " +
                            (selectedLangs
                              ?.map((i) => i.lang)
                              .includes(lang.lang)
                              ? "primary-gradient-div"
                              : "")
                          }
                          style={{
                            minWidth: 40,
                            width: 40,
                            height: 40,
                            padding: 0,
                          }}
                        >
                          <span
                            className={
                              "msymbol material-symbols-outlined " +
                              (selectedLangs
                                ?.map((i) => i.lang)
                                .includes(lang.lang)
                                ? ""
                                : "half-opacity")
                            }
                          >
                            {selectedLangs
                              ?.map((i) => i.lang)
                              .includes(lang.lang)
                              ? "check_circle"
                              : "circle"}
                          </span>
                        </div>
                        <div
                          className="flex-1 flex-row flex-center white-background"
                          style={{
                            height: "100%",
                            padding: "0 1rem",
                            fontSize: 14,
                            justifyContent: "flex-start",
                          }}
                        >
                          {selectedLangs?.map((i) => i.lang).includes(lang.lang)
                            ? t("commons.selected_f")
                            : t("commons.unselect_f")}
                        </div>
                      </button>

                      <Dropdown
                        rightMenu={true}
                        buttonClassName="button is-bordered"
                        iconButton={"more_horiz"}
                        buttonStyle={{
                          padding: "0.5rem",
                          width: "40px",
                          marginLeft: "0.75rem",
                        }}
                        iconStyle={{ fontSize: 18 }}
                      >
                        <button
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={(e) => {
                            e.stopPropagation()
                            setLangToDelete(lang)
                            setShowLangDeleteConfirmation(true)
                          }}
                          className="dropdown-item dropdown-option "
                        >
                          <span className="msymbol material-symbols-outlined danger">
                            delete
                          </span>
                          {t("translationEditor.delete_lang")}
                        </button>
                      </Dropdown>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}

        {selectedPart === "files" && (
          <div
            className="flex-column full-width "
            style={{ marginBottom: "0.5rem" }}
          >
            <div
              className="add-list-item bordered-div"
              style={{
                justifyContent: "flex-start",
                marginBottom: "1rem",
                paddingLeft: "1.15rem",
              }}
              onClick={() => {
                navigate(
                  `/editor/project/${selectedProject?.id_project}/file/import`,
                )
              }}
            >
              <div className="plus-image">
                <span className="msymbol material-symbols-outlined">add</span>
              </div>
              <h3 style={{ fontWeight: 700 }}>
                {t("translationEditor.import_file")}
              </h3>
            </div>

            {selectedProjectFiles?.map((file, index) => (
              <>
                <div
                  className={"file-list-item flex-row flex-center"}
                  style={{ alignItems: "flex-start" }}
                  onClick={() => {
                    setSelectedFile(file)
                    navigate(
                      `/editor/project/${selectedProject?.id_project}/file/${file.id_file}/page/1`,
                    )
                  }}
                >
                  <div
                    className="pie animate lang-pie"
                    style={
                      {
                        margin: "0 1.5rem 0 5px",
                        opacity: 0.6,
                        "--w": "50px",
                        "--p":
                          (Number(
                            selectedLangFileCount(file.id_file)?.valid || 0,
                          ) /
                            (Number(file.fileKeyCount || 0) *
                              (selectedLangs?.length || 0))) *
                          100,
                        "--b": "4px",
                        "--c": "#7c3aed",
                      } as React.CSSProperties
                    }
                  >
                    <div className="flex-column flex-center">
                      <p
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "12px",
                          lineHeight: "12px",
                          margin: 0,
                        }}
                      >
                        {Number(
                          (Number(
                            selectedLangFileCount(file.id_file)?.valid || 0,
                          ) /
                            (Number(file.fileKeyCount || 0) *
                              (selectedLangs?.length || 0))) *
                            100,
                        ).toFixed()}
                        %
                      </p>
                    </div>
                  </div>

                  <div
                    className="flex-column"
                    style={{ justifyContent: "center" }}
                  >
                    <h3
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        lineHeight: "26px",
                      }}
                    >
                      {file.name}
                    </h3>

                    {selectedLangs?.map((selectedLang) => (
                      <div className="half-opacity">
                        <span
                          className={getFlagClass(selectedLang.lang)}
                          style={{ marginRight: "0.5rem" }}
                        ></span>
                        <b>
                          <b className="success-text">
                            {
                              langFileCount(selectedLang.lang, file.id_file)
                                ?.valid
                            }
                          </b>{" "}
                          / {file.fileKeyCount}
                        </b>{" "}
                        {t("translationEditor.keys_lower")}
                        {projectLangProgressions &&
                          langFileCount(selectedLang.lang, file.id_file)
                            ?.source_modified > 0 && (
                            <span className="">
                              {" "}
                              -{" "}
                              <b className="orange-text">
                                {
                                  langFileCount(selectedLang.lang, file.id_file)
                                    ?.source_modified
                                }
                              </b>{" "}
                              {t(
                                "translationEditor.modified_source_keys_lower",
                              )}
                            </span>
                          )}
                        {projectLangProgressions &&
                          Number(file.fileKeyCount || 0) -
                            (langFileCount(selectedLang.lang, file.id_file)
                              ?.source_modified +
                              langFileCount(selectedLang.lang, file.id_file)
                                ?.ai_generated +
                              langFileCount(selectedLang.lang, file.id_file)
                                ?.valid) >
                            0 && (
                            <span className="">
                              {" "}
                              -{" "}
                              <b className="danger-text">
                                {Number(file.fileKeyCount || 0) -
                                  (langFileCount(
                                    selectedLang.lang,
                                    file.id_file,
                                  )?.source_modified +
                                    langFileCount(
                                      selectedLang.lang,
                                      file.id_file,
                                    )?.ai_generated +
                                    langFileCount(
                                      selectedLang.lang,
                                      file.id_file,
                                    )?.valid)}
                              </b>{" "}
                              {t("translationEditor.missing_keys_lower")}
                            </span>
                          )}
                      </div>
                    ))}
                  </div>

                  <div className="flex-1"></div>

                  <div
                    className="flex-row flex-center"
                    style={{ height: "50px" }}
                  >
                    <button
                      className="button is-bordered"
                      onClick={(e) => {
                        if (selectedLangs && selectedLangs[0]) {
                          e.stopPropagation()
                          downloadFile(file, selectedLangs[0].lang)
                        }
                      }}
                      style={{ marginRight: "0.5rem", padding: "0.5rem" }}
                    >
                      <span className="msymbol material-symbols-outlined">
                        download
                      </span>
                    </button>

                    <Dropdown
                      rightMenu={true}
                      buttonClassName="button is-bordered"
                      iconButton={"more_horiz"}
                      buttonStyle={{
                        marginRight: "0.5rem",
                        padding: "0.5rem",
                      }}
                      iconStyle={{ fontSize: 18 }}
                    >
                      {selectedLangs &&
                        Number(file.fileKeyCount || 0) -
                          (selectedLangFileCount(file.id_file)?.ai_generated +
                            selectedLangFileCount(file.id_file)?.valid) >
                          0 && (
                          <button
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              e.stopPropagation()
                              generateMissingFileKeys({
                                lang: selectedLangs[0],
                                file,
                              })
                            }}
                            className="dropdown-item dropdown-option "
                          >
                            <span className="msymbol material-symbols-outlined half-opacity">
                              translate
                            </span>
                            {t("translationEditor.translate_missing_keys")}
                          </button>
                        )}

                      <button
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {}}
                        className="dropdown-item dropdown-option "
                      >
                        <span className="msymbol material-symbols-outlined half-opacity">
                          edit
                        </span>
                        {t("translationEditor.edit_file")}
                      </button>

                      <button
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          if (selectedLangs && selectedLangs[0]) {
                            e.stopPropagation()
                            downloadFile(file, selectedLangs[0].lang)
                          }
                        }}
                        className="dropdown-item dropdown-option "
                      >
                        <span className="msymbol material-symbols-outlined half-opacity">
                          download
                        </span>
                        {t("translationEditor.download_file")}
                      </button>
                      <button
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.stopPropagation()
                          setFileToDelete(file)
                          setShowFileDeleteConfirmation(true)
                        }}
                        className="dropdown-item dropdown-option "
                      >
                        <span className="msymbol material-symbols-outlined danger">
                          delete
                        </span>
                        {t("translationEditor.delete_file")}
                      </button>
                    </Dropdown>
                  </div>
                </div>
                {index < selectedProjectFiles.length - 1 && (
                  <hr style={{ margin: "0" }} />
                )}
              </>
            ))}
          </div>
        )}

        {selectedPart === "traducers" && (
          <div
            className="flex-column full-width "
            style={{ marginBottom: "0.5rem" }}
          >
            <div
              className="add-list-item bordered-div"
              style={{
                justifyContent: "flex-start",
                marginBottom: "1rem",
                paddingLeft: "1.15rem",
              }}
              onClick={() => {
                setModalTraducerInvitationIsShown(true)
              }}
            >
              <div className="plus-image">
                <span className="msymbol material-symbols-outlined">add</span>
              </div>
              <h3 style={{ fontWeight: 700 }}>
                {t("translationEditor.invite_traducers")}
              </h3>
            </div>

            {traducers?.map((traducer: any, index: number) => (
              <>
                <div className={"file-list-item flex-row flex-center"}>
                  <div
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: "50%",
                      margin: "0.5rem 1rem",
                      marginRight: "0.75rem",
                    }}
                  >
                    <div className="flex-column flex-center">
                      <div className="user-image">
                        <span
                          className="user"
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "30px",
                            lineHeight: "30px",
                            margin: 0,
                            fontWeight: 400,
                          }}
                        >
                          {traducer.invitation_mail[0].toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex-column"
                    style={{ justifyContent: "center" }}
                  >
                    <h3
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: "0.25rem",
                      }}
                    >
                      {traducer.invitation_mail}
                    </h3>

                    <div className="flex-row">
                      {traducer.status === "PENDING" && (
                        <div
                          className="warn-badge"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t("translationEditor.invitation_waiting")}
                        </div>
                      )}
                      {traducer.status === "ACTIVE" && (
                        <div
                          className="success-badge"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t("translationEditor.invitation_active")}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex-1"></div>

                  <Dropdown
                    rightMenu={true}
                    buttonClassName="button is-bordered"
                    iconButton={"more_horiz"}
                    buttonStyle={{
                      marginRight: "0.5rem",
                      padding: "0.5rem",
                    }}
                    iconStyle={{ fontSize: 18 }}
                  >
                    <button
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={(e) => {
                        e.stopPropagation()
                        // setFileToDelete(file)
                        // setShowFileDeleteConfirmation(true)
                      }}
                      className="dropdown-item dropdown-option "
                    >
                      <span className="msymbol material-symbols-outlined danger">
                        person_off
                      </span>
                      {t("translationEditor.delete_traducer")}
                    </button>
                  </Dropdown>
                </div>
                {index < traducers.length - 1 && <hr style={{ margin: "0" }} />}
              </>
            ))}
          </div>
        )}
      </div>

      <Modal
        isShown={modalTraducerInvitationIsShown}
        closeModal={() => {
          if (!preventDoubleSubmit) {
            setModalTraducerInvitationIsShown(false)
          }
        }}
      >
        <h1 className="modal-title">
          {t("translationEditor.invite_traducers")}
        </h1>
        <p style={{ marginBottom: "1rem" }}>
          {t("translationEditor.invite_traducers_infos")}
        </p>
        {mailInvitations.map((invitation: any, index: number) => (
          <div className="flex-row" style={{ marginTop: "0.75rem" }}>
            <div className="control has-icons-left flex-1">
              <input
                className={`full-width input`}
                type="email"
                placeholder={t("auth.mail")}
                value={mailInvitations[index]}
                onChange={(event: any) => {
                  let updatedInvite = Array.from(mailInvitations)
                  updatedInvite[index] = event.target.value

                  setMailInvitations(updatedInvite)
                }}
              />
              <span className="icon is-small is-left">
                <span className="msymbol material-symbols-outlined">mail</span>
              </span>
            </div>
            {mailInvitations.length > 1 && (
              <button
                className="button is-bordered"
                style={{
                  minWidth: "unset",
                  width: "40px",
                  marginLeft: "0.5rem",
                }}
                onClick={() => {
                  let updatedInvite = Array.from(mailInvitations)
                  if (mailInvitations.length > 1) {
                    updatedInvite.splice(index, 1)

                    setMailInvitations(updatedInvite)
                  }
                }}
              >
                <span className="msymbol material-symbols-outlined warn-text">
                  close
                </span>
              </button>
            )}
          </div>
        ))}
        {/* {mailInvitations.length < traducerLimit && (
          <button
            className="button is-bordered full-width flex-row"
            style={{
              marginTop: "0.75rem",
              justifyContent: "flex-start",
              paddingLeft: "0.5rem",
              opacity: 0.5,
            }}
            onClick={() => {
              let updatedInvite = Array.from(mailInvitations)
              updatedInvite.push("")

              setMailInvitations(updatedInvite)
            }}
          >
            <span className="msymbol material-symbols-outlined">add</span>
            <span style={{ marginLeft: "0.5rem" }}>
              {t("translationEditor.add_invitation")}
            </span>
          </button>
        )} */}
        <button
          disabled={preventDoubleSubmit}
          className="button is-primary-gradient full-width"
          style={{ margin: "1rem 0" }}
          onClick={() => {
            if (!preventDoubleSubmit) {
              setPreventDoubleSubmit(true)
              inviteTraducer()
            }
          }}
        >
          {t("translationEditor.send_invitation")}
        </button>
      </Modal>

      <Modal
        isShown={modalFileTranslationIsShown}
        closeModal={() => {
          if (!preventDoubleSubmit) {
            setModalFileTranslationIsShown(false)
            setCurrentChunk(undefined)
          }
        }}
      >
        <h1 className="modal-title">
          {t("translationEditor.missing_keys_translation")}
        </h1>

        {preventDoubleSubmit && (
          <div className="fade-in-top" style={{ textAlign: "center" }}>
            <AnimatedLoader
              style={{
                height: "200px",
                minHeight: "200px",
                maxHeight: "200px",
              }}
            />
            <h2 className="half-opacity font-bold">
              {t("translationEditor.running_translation")}
            </h2>
            <p style={{ marginBottom: "1rem" }}>
              {t("translationEditor.please_do_not_leave")}
            </p>

            <button
              className="button is-bordered full-width"
              style={{ marginBottom: "1rem" }}
              disabled
            >
              {currentChunk?.filename} ({currentChunk?.index} /{" "}
              {currentChunk?.totalChunks})
            </button>
          </div>
        )}
        {!preventDoubleSubmit && (
          <div className="fade-in-top" style={{ textAlign: "center" }}>
            <span
              className="msymbol material-symbols-outlined success-text"
              style={{
                fontSize: 200,
                lineHeight: "200px",
                height: "200px",
                minHeight: "200px",
                maxHeight: "200px",
              }}
            >
              check_circle
            </span>
            <h2 className="half-opacity font-bold">
              {t("translationEditor.done_translation")}
            </h2>
            <p style={{ marginBottom: "1rem" }}>
              <b>{fileCost?.toLocaleString()}</b>{" "}
              {t("translationEditor.token_used")}
            </p>

            <button
              className="button is-primary-gradient full-width"
              style={{ marginBottom: "1rem" }}
              onClick={() => {
                if (currentTranslatedFile) {
                  setSelectedFile(currentTranslatedFile)
                  setCurrentTranslatedFile(undefined)
                  setModalFileTranslationIsShown(false)
                  navigate(
                    `/editor/project/${selectedProject?.id_project}/file/${currentTranslatedFile.id_file}/page/1`,
                  )
                }
              }}
            >
              {t("translationEditor.see_translations")}
            </button>
          </div>
        )}
      </Modal>

      <Modal
        containerStyle={{
          maxHeight: "550px",
          height: "calc(100% - 4rem)",
          flexDirection: "column",
          display: "flex",
        }}
        isShown={modalAllTranslationIsShown}
        closeModal={() => {
          if (!preventDoubleSubmit) {
            setCurrentTranslatedLangs(undefined)
            setCurrentChunk(undefined)
            setModalAllTranslationIsShown(false)
          }
        }}
      >
        <h1 className="modal-title">
          {t("translationEditor.missing_keys_translation")}
        </h1>

        <div
          className="fade-in-top flex-1 flex-column"
          style={{ textAlign: "center", overflow: "auto", maxHeight: "410px" }}
        >
          {currentTranslatedLangs?.map((item) => (
            <div
              className="flex-row full-width"
              style={{ marginBottom: "0.5rem" }}
            >
              {(item.status === "pending" || item.status === "interrupted") && (
                <div
                  style={{
                    height: "48px",
                    width: "48px",
                  }}
                ></div>
              )}
              {item.status === "started" && (
                <AnimatedLoader
                  style={{
                    height: "48px",
                    minHeight: "48px",
                    maxHeight: "48px",
                    width: "48px",
                  }}
                  noContainer={true}
                />
              )}
              {item.status === "done" && (
                <span
                  className="msymbol material-symbols-outlined success-text"
                  style={{
                    fontSize: 48,
                    lineHeight: "48px",
                    height: "48px",
                    minHeight: "48px",
                    maxHeight: "48px",
                  }}
                >
                  check_circle
                </span>
              )}

              <div
                className="flex-column flex-1"
                style={{ alignItems: "flex-start", marginLeft: "1rem" }}
              >
                <h2 className="font-bold">
                  <div
                    className={getFlagClass(item.lang)}
                    style={{ marginRight: "0.5rem" }}
                  ></div>
                  {item.lang}
                </h2>
                <p className="half-opacity">
                  {currentChunk && item.status === "started" && (
                    <>
                      {currentChunk?.filename} ({currentChunk?.index} /{" "}
                      {currentChunk?.totalChunks})
                    </>
                  )}
                  {item.status === "pending" &&
                    t("translationEditor.translation_pending")}
                  {item.status === "done" &&
                    t("translationEditor.translation_done")}
                  {item.status === "interrupted" &&
                    t("translationEditor.translation_interrupted")}
                </p>
              </div>
            </div>
          ))}
        </div>
        <button
          className="button is-bordered full-width"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          disabled={
            preventDoubleSubmit &&
            (isInterrupted.current === true || isInterruptedState === true)
          }
          onClick={() => {
            if (!preventDoubleSubmit) {
              setModalAllTranslationIsShown(false)
            } else {
              setIsInterruptedState(true)
              isInterrupted.current = true

              let updatedDoneLangs = currentTranslatedLangs?.map((i) => {
                if (i.status === "pending") i.status = "interrupted"
                return i
              })
              setCurrentTranslatedLangs(updatedDoneLangs)
            }
          }}
        >
          {preventDoubleSubmit
            ? isInterrupted.current === true || isInterruptedState === true
              ? t("translationEditor.interupted")
              : t("translationEditor.interupt")
            : t("commons.back")}
        </button>
      </Modal>

      <ModalConfirmation
        isShown={showLangDeleteConfirmation}
        closeModal={() => {
          setLangToDelete(undefined)
          setShowLangDeleteConfirmation(false)
        }}
        onConfirm={() => {
          setPreventDoubleSubmit(true)
          handleDeleteLang(langToDelete.id_project_lang)
          setLangToDelete(undefined)
          setShowLangDeleteConfirmation(false)
        }}
      >
        <h1 className="modal-title">
          {t("translationEditor.confirm_delete_lang_title")}
        </h1>
      </ModalConfirmation>

      <ModalConfirmation
        isShown={showFileDeleteConfirmation}
        closeModal={() => {
          setFileToDelete(undefined)
          setShowFileDeleteConfirmation(false)
        }}
        onConfirm={() => {
          setPreventDoubleSubmit(true)
          handleDeleteFile(fileToDelete.id_file)
          setFileToDelete(undefined)
          setShowFileDeleteConfirmation(false)
        }}
      >
        <h1 className="modal-title">
          {t("translationEditor.confirm_delete_file_title")}
        </h1>
      </ModalConfirmation>

      <Modal
        containerStyle={{ maxWidth: 800 }}
        isShown={modalTokenLimitIsShown}
        closeModal={() => {
          setModalTokenLimitIsShown(false)
        }}
      >
        <ModalLimitReached
          closeModal={() => setModalTokenLimitIsShown(false)}
          limitType="tokens"
        ></ModalLimitReached>
      </Modal>
    </>
  )
}

export default ProjectFiles
