import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  confirmPaymentIntent,
  confirmSubscription,
} from "../../services/credits"
import AnimatedLoader from "./AnimatedLoader"

type Props = {
  paymentAmount?: number
  priceId?: string
  reloadCredits: () => void
}

const CheckoutForm = ({ paymentAmount, priceId, reloadCredits }: Props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [currentStep, setCurrentStep] = useState(1)
  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState(false)

  const { t } = useTranslation()

  const continueToPayment = async () => {
    if (elements) {
      const addressElement = elements.getElement("address")

      const result = await addressElement?.getValue()
      if (result?.complete) {
        setCurrentStep(2)
      }
    }
  }

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setPreventDoubleSubmit(true)
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    if (priceId) {
      localStorage.setItem("paymentType", "subscription")
    } else if (paymentAmount) {
      localStorage.setItem("paymentType", "buy_credit")
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_FRONT_URL + "/billing",
      },
      redirect: "if_required",
    })

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message)
      setPreventDoubleSubmit(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (result.paymentIntent.status === "succeeded") {
        if (priceId) {
          await confirmSubscription({ pi_id: result.paymentIntent.id })
        } else if (paymentAmount) {
          await confirmPaymentIntent({ pi_id: result.paymentIntent.id })
        }
        reloadCredits()
      }
    }
  }

  return (
    <form
      onSubmit={(e) => {
        setPreventDoubleSubmit(true)
        handleSubmit(e)
      }}
    >
      <div
        style={{
          display: currentStep !== 1 ? "none" : "block",
        }}
      >
        <h1 className="modal-title">{t("credits.billing_address")}</h1>
        <div
          style={{
            minHeight: 235,
          }}
        >
          <AddressElement
            options={{
              mode: "billing",
            }}
          />
        </div>
        {stripe && (
          <button
            className="button is-primary-gradient full-width"
            style={{
              marginTop: "1.5rem",
              height: 48,
              fontSize: 20,
              fontWeight: 800,
              marginBottom: "1rem",
            }}
            disabled={!stripe}
            type="button"
            onClick={() => continueToPayment()}
          >
            {t("credits.continue_to_payment")}
          </button>
        )}
      </div>
      <div
        style={{
          display: currentStep !== 2 ? "none" : "block",
          minHeight: 230,
        }}
      >
        <h1 className="modal-title">{t("credits.payment_method")}</h1>
        <div
          style={{
            minHeight: 235,
          }}
        >
          <PaymentElement />
        </div>
        {stripe && (
          <button
            className="button is-primary-gradient full-width"
            style={{
              marginTop: "1.5rem",
              height: 48,
              fontSize: 20,
              fontWeight: 800,
              marginBottom: "1rem",
            }}
            type="submit"
            disabled={!stripe || preventDoubleSubmit}
          >
            {preventDoubleSubmit ? (
              <AnimatedLoader noContainer={true} />
            ) : (
              <>
                {t("credits.pay")}{" "}
                {paymentAmount && <>{Number(paymentAmount || 0).toFixed(2)}€</>}
              </>
            )}
          </button>
        )}
      </div>
    </form>
  )
}

export default CheckoutForm
