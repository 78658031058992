import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useParams } from "react-router-dom"
import { TranslationEditorContext } from "../../../context/TranslationEditor"
import {
  deleteTranslatedKey,
  getDownloadFileByLang,
  getFileKeysByLang,
  postMissingFileKey,
  updateFileKey,
} from "../../../services/files"
import Dropdown from "../../components/Dropdown"
import DropdownProjectLangs from "../../components/DropdownProjectLangs"
import ModalConfirmation from "../../components/ModalConfirmation"
import Paginator from "../../components/Paginator"

export const EditFile = () => {
  const { t } = useTranslation()
  let { file_id, page } = useParams()
  const { setSelectedFile } = useContext(TranslationEditorContext)
  const navigate = useNavigate()

  const {
    selectedFile,
    selectedProject,
    selectedInputProjectLangs,
    selectedOutputProjectLangs,
    selectedProjectFiles,
    selectedLangs,
    setPreventDoubleSubmit,
    incrementProjectLangProgressions,
  } = useContext(TranslationEditorContext)

  const [keys, setKeys] = useState<any[]>([])
  const [totalKeyFile, setTotalKeyFile] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const changePage = (page_number: number) => {
    if (selectedProject)
      navigate(
        `/editor/project/${selectedProject.id_project}/file/${selectedFile?.id_file}/page/${page_number}`,
      )
  }

  useEffect(() => {
    const reloadFileKeys = async () => {
      if (selectedInputProjectLangs && selectedInputProjectLangs[0]) {
        const resultKeys = await getFileKeysByLang({
          fileId: Number(file_id),
          lang: [
            selectedInputProjectLangs[0].lang,
            ...(selectedLangs?.map((i) => i.lang) || []),
          ],
          pageNumber: Number(page || 1),
        })
        setKeys(resultKeys?.data?.rows || [])
        setTotalKeyFile(resultKeys?.data?.count || 0)
        setIsLoading(false)
      }
    }

    if (
      selectedInputProjectLangs &&
      selectedInputProjectLangs[0] &&
      selectedProjectFiles &&
      (!selectedFile || (selectedFile && selectedFile.id_file !== file_id))
    ) {
      const file = selectedProjectFiles.find(
        (i: any) => i.id_file === Number(file_id),
      )
      setSelectedFile(file)
    }

    setIsLoading(true)
    reloadFileKeys()
  }, [
    selectedInputProjectLangs,
    selectedLangs,
    selectedFile,
    selectedProjectFiles,
    file_id,
    page,
    setSelectedFile
  ])

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  const updateKey = async (translated_key: any, status: string) => {
    await updateFileKey({
      translatedKeyId: translated_key.id_translated_key,
      value: translated_key.value,
      status: status,
    })
    setPreventDoubleSubmit(false)
  }

  const postMissingKey = async (
    key: any,
    value: string,
    status: string,
    lang: string,
  ) => {
    const result = await postMissingFileKey({
      lang: lang,
      keyId: key.id_key,
      value: value,
      status: status,
    })
    setPreventDoubleSubmit(false)
    return result.data
  }

  const handleValidateKey = (translated_key: any) => {
    setPreventDoubleSubmit(true)
    updateKey(translated_key, "valid")
    translated_key.status = "valid"
    incrementProjectLangProgressions({
      lang: translated_key.lang,
      status: "valid",
      fileId: selectedFile?.id_file,
    })
  }

  const handleUpdateKey = async (key: any, translated_key: any) => {
    setPreventDoubleSubmit(true)
    if (key && !translated_key.id_translated_key) {
      const newKey = await postMissingKey(
        key,
        translated_key.value,
        "valid",
        translated_key.lang,
      )
      key.translated_keys.target.push(newKey)

      incrementProjectLangProgressions({
        lang: translated_key.lang,
        status: "valid",
        fileId: selectedFile?.id_file,
        isNew: true,
      })
    } else {
      updateKey(translated_key, translated_key.status)
    }
  }

  const handleDeleteTranslatedKey = async (key: any, translated_key: any) => {
    if (translated_key && translated_key.id_translated_key) {
      setPreventDoubleSubmit(true)
      await deleteTranslatedKey({
        translatedKeyId: translated_key.id_translated_key,
      })
      key.translated_keys.target.find(
        (t: any) => t.id_translated_key === translated_key.id_translated_key,
      ).value = ""
      key.translated_keys.target = key.translated_keys.target.filter(
        (t: any) => t.id_translated_key !== translated_key.id_translated_key,
      )
      setPreventDoubleSubmit(false)
    }
  }

  const downloadFile = async (lang: string) => {
    // const { myData } = this.state
    const result = await getDownloadFileByLang({
      fileId: selectedFile?.id_file,
      lang: lang,
    })

    // create file in browser
    const fileName = selectedFile?.name
    const json = JSON.stringify(result.data, null, 2)
    const blob = new Blob([json], { type: "application/json" })
    const href = URL.createObjectURL(blob)

    // create "a" HTLM element with href to file
    const link = document.createElement("a")
    link.href = href
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  const [showKeyDeleteConfirmation, setShowKeyDeleteConfirmation] =
    useState(false)
  const [keyToDelete, setKeyToDelete] = useState<any>(undefined)
  const [translatedKeyToDelete, setTranslatedKeyToDelete] =
    useState<any>(undefined)

  return (
    <>
      <div className="edit-file-topbar flex-row full-width">
        <Link
          className="button is-bordered hide-phone"
          style={{ paddingLeft: 8 }}
          to={`/editor/project/${selectedProject?.id_project}/files`}
          onClick={() => {
            setSelectedFile(undefined)
          }}
        >
          <span
            className="msymbol material-symbols-outlined"
            style={{ marginRight: 8 }}
          >
            keyboard_arrow_left
          </span>
          {t("commons.back")}
        </Link>

        <div className="flex-1"></div>
        <div className="flex-row flex-center">
          <h2
            style={{
              margin: 0,
              fontSize: 18,
              fontWeight: "bold",
              fontFamily: "'Poppins', sans-serif",
              marginRight: "1rem",
            }}
          >
            {selectedFile?.name}
          </h2>

          <button
            className="button is-bordered"
            onClick={() => {
              // downloadFile()
            }}
            style={{ marginRight: "0.5rem", padding: "0.5rem" }}
          >
            <span className="msymbol material-symbols-outlined">download</span>
          </button>

          <DropdownProjectLangs
            customMenuStyle={{
              maxHeight: "400px",
              overflow: "auto",
              right: 0,
              left: "unset",
            }}
            rightMenu={true}
          />
        </div>
      </div>

      <div
        className="basic-container editor-container"
        style={{
          maxWidth: "100%",
          overflow: "auto",
          marginTop: "72px",
          minHeight: "calc(100% - 72px)",
          padding: "0 1.5rem 1rem",
          position: "relative",
        }}
      >
        {!isLoading && keys && (
          <>
            {keys?.map((key: any, index: number) => (
              <div className="full-width key-item flex-column">
                <div className="flex-row full-width">
                  <div
                    className="key-name flex-1 flex-row flex-center text-ellipsis"
                    style={{
                      marginBottom: "0.5rem",
                      fontWeight: "600",
                      height: 24,
                    }}
                  >
                    <div
                      className="flex-column flex-center hide-phone"
                      style={{ width: 28, height: 24, marginRight: "0.75rem" }}
                    >
                      <span
                        className={"msymbol material-symbols-outlined "}
                        style={{
                          fontSize: 22,
                          opacity: 0.35,
                        }}
                      >
                        vpn_key
                      </span>
                    </div>

                    <p
                      style={{
                        fontSize: "0.85rem",
                        width: "100%",
                        marginRight: "0.5rem",
                      }}
                      className="text-ellipsis"
                    >
                      {key.name}
                    </p>
                  </div>

                  {key.translated_keys.target && (
                    <Dropdown
                      rightMenu={true}
                      buttonClassName="button is-bordered"
                      iconButton={"more_horiz"}
                      buttonStyle={{
                        marginLeft: "0.5rem",
                        padding: "0",
                        height: 24,
                        width: 24,
                      }}
                      iconStyle={{ fontSize: 18 }}
                    >
                      <button
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.stopPropagation()
                          setKeyToDelete(key)
                          setShowKeyDeleteConfirmation(true)
                        }}
                        className="dropdown-item dropdown-option "
                      >
                        <span className="msymbol material-symbols-outlined danger">
                          delete
                        </span>
                        {t("translationEditor.delete_translation")}
                      </button>
                    </Dropdown>
                  )}
                </div>

                <div className="flex-column full-width">
                  <div className="flex-row">
                    <div
                      className="flex-column flex-center bordered-div"
                      style={{
                        padding: 0,
                        width: 40,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                      }}
                    >
                      <span
                        className={getFlagClass("fr-FR")}
                        style={{
                          fontSize: 18,
                          opacity: 0.25,
                        }}
                      ></span>
                    </div>

                    <div
                      className="flex-row bordered-div half-opacity flex-1"
                      style={{
                        lineHeight: "1.35rem",
                        whiteSpace: "pre-wrap",
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                        borderLeft: "0px solid transparent",
                      }}
                    >
                      {key?.translated_keys?.source?.value}
                    </div>

                    <div
                      className={"flex-column flex-center blue-badge"}
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 8,
                        marginLeft: "0.5rem",
                      }}
                    >
                      <span className="msymbols material-symbols-outlined">
                        key
                      </span>
                    </div>
                  </div>

                  {selectedLangs?.map((targetLang: any) => {
                    const targetKey = key?.translated_keys?.target?.find(
                      (t: any) => t.lang === targetLang.lang,
                    )
                    return (
                      <div className="flex-row" style={{ marginTop: "0.5rem" }}>
                        <div
                          className="flex-column flex-center bordered-div"
                          style={{
                            padding: 0,
                            width: 40,
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                        >
                          <span
                            className={getFlagClass(targetLang.lang)}
                            style={{
                              fontSize: 18,
                              opacity: 0.75,
                            }}
                          ></span>
                        </div>

                        <div
                          className="bordered-div flex-1"
                          style={{
                            // marginLeft: "0.5rem",
                            fontSize: 16,
                            lineHeight: "1.35rem",
                            minHeight: "1.35rem",
                            resize: "none",
                            height: "auto",
                            display: "block",
                            borderBottomLeftRadius: 0,
                            borderTopLeftRadius: 0,
                            borderLeft: "0px solid transparent",
                          }}
                          onBlur={(e) => {
                            let updatedKey = targetKey || {}
                            updatedKey.value = e.target.innerText
                            updatedKey.lang = targetLang.lang
                            handleUpdateKey(key, updatedKey)
                          }}
                          contentEditable="true"
                          suppressContentEditableWarning={true}
                        >
                          {targetKey?.value}
                        </div>

                        <div
                          className={
                            "flex-column flex-center " +
                            (targetKey === undefined
                              ? "danger-badge "
                              : targetKey?.status === "ai_generated"
                                ? "warn-badge "
                                : targetKey?.status === "valid"
                                  ? "success-badge "
                                  : targetKey?.status === "source_modified"
                                    ? "orange-badge "
                                    : "")
                          }
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 8,
                            marginLeft: "0.5rem",
                          }}
                          onClick={(e) => {
                            // setKeyToDelete(key)
                            // setTranslatedKeyToDelete(targetKey)
                            // setShowKeyDeleteConfirmation(true)
                            handleValidateKey(targetKey)
                          }}
                        >
                          <span className="msymbols material-symbols-outlined">
                            {targetKey === undefined && "close"}
                            {targetKey?.status === "valid" && "check"}
                            {targetKey?.status === "ai_generated" && "token"}
                            {targetKey?.status === "source_modified" && "edit"}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}

            <Paginator
              currentPage={Number(page || 1)}
              totalItem={totalKeyFile}
              itemPerPage={50}
              changeCurrentPage={changePage}
            />
          </>
        )}
      </div>

      <ModalConfirmation
        isShown={showKeyDeleteConfirmation}
        closeModal={() => {
          setKeyToDelete(undefined)
          setTranslatedKeyToDelete(undefined)
          setShowKeyDeleteConfirmation(false)
        }}
        onConfirm={() => {
          handleDeleteTranslatedKey(keyToDelete, translatedKeyToDelete)
          setKeyToDelete(undefined)
          setTranslatedKeyToDelete(undefined)
          setShowKeyDeleteConfirmation(false)
        }}
      >
        <h1 className="modal-title">
          {t("translationEditor.confirm_delete_key_title")}
        </h1>
      </ModalConfirmation>
    </>
  )
}

export default EditFile
