import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import AnimatedLoader from "../components/AnimatedLoader"
import Paginator from "../components/Paginator"

import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js"
import posthog from "posthog-js"
import ReactGA from "react-ga4"
import { AuthContext } from "../../context/Auth"
import { getApiKeys, postApiKey, revokeApiKey } from "../../services/apiKeys"
import Dropdown from "../components/Dropdown"
import Modal from "../components/Modal"
import AmountLabeled from "../components/AmountLabeled"

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
)

export const ApiKeys = () => {
  const { t } = useTranslation()

  const { userAccountTier } = useContext(AuthContext)

  const [apiKeys, setApiKeys] = useState<any>(null)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const [totalEvents, setTotalEvents] = useState<number>(0)

  useEffect(() => {
    document.title = t("apiKeys.title")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/api-keys",
        title: "API keys",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const changePage = (page_number: number) => {
    setCurrentPage(page_number)
  }

  const reloadUsage = async () => {
    return await getApiKeys()
  }

  useEffect(() => {
    reloadUsage()
      .then((result: any) => {
        setApiKeys(result.data.rows)
        setTotalEvents(result.data.count)
      })
      .catch((err) => {
        setApiKeys(null)
        setTotalEvents(0)
        console.log(err)
      })
  }, [currentPage])

  const generateApiKey = async () => {
    if (keyName.length > 0) {
      try {
        const resultKey: any = await postApiKey({ name: keyName })
        setNewKey(resultKey.data.key)

        const result = await reloadUsage()
        setApiKeys(result.data.rows)
        setTotalEvents(result.data.count)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const revokeKey = async () => {
    try {
      await revokeApiKey({ id_api_key: selectedKey.id_api_key })

      const result = await reloadUsage()
      setApiKeys(result.data.rows)
      setTotalEvents(result.data.count)
      setRevokeModalIsShown(false)
    } catch (error) {
      console.log(error)
    }
  }

  const [modalIsShown, setModalIsShown] = useState<boolean>(false)
  const [newKey, setNewKey] = useState<string | null>()
  const [keyName, setKeyName] = useState("")

  const handleKeyName = (event: any) => {
    setKeyName(event.target.value)
  }

  const handleConfirmKeyName = (event: any) => {
    setConfirmKeyName(event.target.value)
  }

  const [revokeModalIsShown, setRevokeModalIsShown] = useState<boolean>(false)
  const [confirmKeyName, setConfirmKeyName] = useState("")
  const [selectedKey, setSelectedKey] = useState<any>()

  const apiKeyLimit = useMemo(() => {
    switch (userAccountTier) {
      case 1:
        return 2
      case 2:
        return 5
      case 3:
        return 500
      case 4:
        return 500
      default:
        return 1
    }
  }, [userAccountTier])

  return (
    <>
      <div className="champo-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined">
          lock
        </span>

        <div className="flex-column">
          <h1 className="champo-title">{t("apiKeys.title")}</h1>
        </div>
      </div>

      <div
        className="basic-container api-keys"
        style={{ alignItems: "flex-start" }}
      >
        <h2
          className="sub-title"
          style={{
            margin: 0,
            marginBottom: "1rem",
          }}
        >
          {t("apiKeys.manageApiKeys")}
        </h2>
        <div className="flex-column" style={{ justifyContent: "flex-start" }}>
          <AmountLabeled
            icon="key"
            iconColor="#d946ef"
            label={t("apiKeys.activeKeys")}
            amount={
              apiKeys?.filter((i: any) => i.status === "ACTIVE").length || 0
            }
            amountSlashed={apiKeyLimit.toLocaleString()}
            style={{ marginBottom: "1.5rem" }}
          />

          <div className="button-div">
            <button
              className="button is-primary-gradient"
              style={{
                height: "unset",
                minHeight: "48px",
                padding: "0.75rem 1.5rem",
                fontSize: "18px",
                whiteSpace: "normal",
              }}
              onClick={() => setModalIsShown(true)}
              disabled={
                apiKeyLimit -
                  apiKeys?.filter((i: any) => i?.status === "ACTIVE")?.length <=
                0
              }
            >
              {t("apiKeys.generateNewKey")}
            </button>
          </div>

          <p style={{ marginTop: "0.35rem" }}>
            <b className="text">
              {(apiKeyLimit -
                apiKeys?.filter((i: any) => i?.status === "ACTIVE")?.length) |
                0}
            </b>{" "}
            <span style={{ opacity: 0.5 }}>
              {apiKeyLimit -
                apiKeys?.filter((i: any) => i?.status === "ACTIVE")?.length >
              1
                ? t("apiKeys.remainingKeys")
                : t("apiKeys.remainingKey")}
            </span>
          </p>
        </div>

        <p className="infos-api-keys" style={{ marginTop: "1.5rem" }}>
          {t("apiKeys.paragraph1")}
        </p>
        <p className="infos-api-keys">{t("apiKeys.paragraph2")}</p>

        {!apiKeys && (
          <div className="champo-page-container">
            <AnimatedLoader />
          </div>
        )}

        {apiKeys !== null && apiKeys?.length > 0 && (
          <hr
            style={{
              margin: 0,
              marginTop: "1.5rem",
              marginBottom: "2rem",
              width: "100%",
            }}
          />
        )}

        {apiKeys !== null && apiKeys?.length > 0 && (
          <div className="champo-page-container">
            <div
              className="flex-column table-list flex-row"
              style={{ minWidth: "100%", overflow: "visible" }}
            >
              <div
                style={{
                  flexDirection: "column",
                  overflowX: "auto",
                  overflowY: "hidden",
                  width: "calc(100% - 10rem)",
                }}
              >
                <div
                  className="flex-row table-item header-item"
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      width: "8rem",
                      minWidth: "8rem",
                    }}
                  >
                    {t("apiKeys.status")}
                  </div>
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                    }}
                  >
                    {t("apiKeys.createdAt")}
                  </div>
                  <div
                    style={{
                      width: "12rem",
                      minWidth: "12rem",
                    }}
                  >
                    {t("apiKeys.lastUse")}
                  </div>

                  <div
                    style={{
                      width: "8rem",
                      minWidth: "8rem",
                    }}
                  >
                    {t("apiKeys.name")}
                  </div>

                  <div
                    className="flex-1"
                    style={{
                      minWidth: "8rem",
                    }}
                  >
                    {t("apiKeys.key")}
                  </div>

                  <div
                    style={{
                      width: "8rem",
                      minWidth: "8rem",
                      textAlign: "right",
                    }}
                  >
                    {t("apiKeys.usage")}
                  </div>
                </div>

                {apiKeys.map((item: any, index: number) => (
                  <div
                    key={item.id_api_key + "_list_100"}
                    className={
                      "table-item flex-row " +
                      (index % 2 === 0 ? "even" : "odd")
                    }
                    style={{ alignItems: "center", display: "inline-flex" }}
                  >
                    <div
                      style={{
                        width: "8rem",
                        minWidth: "8rem",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "6px",
                          fontSize: "14px",
                          minWidth: "6.75rem",
                          fontWeight: "bold",
                        }}
                        className={
                          "flex-row flex-center " +
                          (item.status === "ACTIVE"
                            ? "success-badge"
                            : item.status === "REVOKED"
                              ? "warn-badge"
                              : item.status === "ERRORED"
                                ? "danger-badge"
                                : "")
                        }
                      >
                        {item.status}
                      </div>
                    </div>

                    <div
                      style={{
                        width: "12rem",
                        minWidth: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b> {new Date(item.createdAt).toLocaleString()}</b>
                      </p>
                    </div>
                    <div
                      style={{
                        width: "12rem",
                        minWidth: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b> {new Date(item.updatedAt).toLocaleString()}</b>
                      </p>
                    </div>

                    <div
                      style={{
                        width: "8rem",
                        minWidth: "8rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        {item.name}
                      </p>
                    </div>

                    <div
                      className="flex-1"
                      style={{
                        minWidth: "8rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <b>{item.key}</b>
                      </p>
                    </div>

                    <div
                      style={{
                        width: "8rem",
                        minWidth: "8rem",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      {Number(item.champo_cost).toLocaleString()}
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <div className="fake-header"></div>

                {apiKeys.map((item: any, index: number) => (
                  <div
                    className={
                      "overlay-option-button " +
                      (index % 2 === 0 ? "even" : "odd")
                    }
                    style={{ top: 35 + 50 * index }}
                  >
                    <Dropdown title={t("commons.options")} rightMenu={true}>
                      <button
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          setSelectedKey(item)
                          setRevokeModalIsShown(true)
                        }}
                        disabled={item.status !== "ACTIVE"}
                        className="dropdown-item dropdown-option"
                      >
                        <span className="msymbol material-symbols-outlined danger">
                          close
                        </span>
                        {t("apiKeys.revoke")}
                      </button>
                    </Dropdown>
                  </div>
                ))}
              </div>
            </div>

            <Paginator
              currentPage={currentPage}
              totalItem={totalEvents}
              itemPerPage={50}
              changeCurrentPage={changePage}
            />
          </div>
        )}

        <Modal
          isShown={modalIsShown}
          closeModal={() => (newKey ? () => {} : setModalIsShown(false))}
        >
          <h1 className="modal-title">{t("apiKeys.generateNewKey")}</h1>

          <p className="modal-text font-bold">{t("apiKeys.keyModalText")}</p>

          <hr />

          <div className="field" style={{ marginTop: "1rem" }}>
            <label className="label text">{t("apiKeys.name_long")}</label>
            <div className="control">
              <input
                className="full-width input"
                type="text"
                placeholder={t("apiKeys.name_long")}
                value={keyName}
                onChange={handleKeyName}
              />
            </div>
          </div>

          <div className="key-block">
            <p className="half-opacity">
              <b className="half-opacity">{t("apiKeys.yourNewKey")}</b>
            </p>

            <p>
              {newKey ? (
                <code>{newKey}</code>
              ) : (
                <i className="half-opacity">{t("apiKeys.clickToGenerate")}</i>
              )}
            </p>
          </div>

          {!newKey ? (
            <button
              type="submit"
              className="button is-primary-gradient modal-button"
              onClick={generateApiKey}
              disabled={keyName.length === 0}
            >
              {t("apiKeys.generateKey")}
            </button>
          ) : (
            <button
              className="button is-primary-gradient modal-button"
              onClick={() => {
                setNewKey("")
                setModalIsShown(false)
              }}
              disabled={keyName.length === 0}
            >
              {t("apiKeys.keySavedConfirm")}
            </button>
          )}
        </Modal>

        <Modal
          isShown={revokeModalIsShown}
          closeModal={() => setRevokeModalIsShown(false)}
        >
          <h1 className="modal-title">{t("apiKeys.confirmRevokeKey")}</h1>

          <p className="modal-text font-bold">
            {t("apiKeys.confirmRevokeText")} {selectedKey?.name}
          </p>
          <div className="field" style={{ marginTop: "1rem" }}>
            <div className="control">
              <input
                className="full-width input"
                type="text"
                placeholder={t("apiKeys.name_long")}
                value={confirmKeyName}
                onChange={handleConfirmKeyName}
              />
            </div>
          </div>

          <div className="flex-row full-width">
            <button
              type="submit"
              className="button is-bordered modal-button flex-1"
              style={{ marginRight: "0.5rem" }}
              onClick={() => setRevokeModalIsShown(false)}
              disabled={confirmKeyName !== selectedKey?.name}
            >
              {t("commons.cancel")}
            </button>
            <button
              type="submit"
              className="button is-primary-gradient modal-button flex-1"
              style={{ marginLeft: "0.5rem" }}
              onClick={revokeKey}
              disabled={confirmKeyName !== selectedKey?.name}
            >
              {t("apiKeys.revokeKey")}
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ApiKeys
