import { Trans, useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import PremiumItem from "../components/PremiumItem"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/Auth"
import { postGetFreeCredits } from "../../services/credits"
import AnimatedLoader from "../components/AnimatedLoader"
import { Link } from "react-router-dom"
import posthog from "posthog-js"
import ReactGA from "react-ga4"
import AmountBar from "../components/AmountBar"

export const Dashboard = () => {
  const { t } = useTranslation()
  const {
    projectCount,
    langUsed,
    userAccountTier,
    currentBalance,
    alreadyGotFreeCredits,
    editorKeyCount,
    setUserSubscription,
  } = useContext(AuthContext)
  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState<boolean>(false)
  const [selectedPart, setSelectedPart] = useState<string>("cli")

  useEffect(() => {
    document.title = t("getStarted.title")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/get-started",
        title: "Get started",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getFreeCredits = async () => {
    try {
      await postGetFreeCredits()
      setUserSubscription()
      setPreventDoubleSubmit(false)
    } catch (error) {
      console.log(error)
      setPreventDoubleSubmit(false)
    }
  }

  const handleGetFreeCredits = () => {
    setPreventDoubleSubmit(true)
    getFreeCredits()
  }

  const guideSteps: {
    title: string
    text: string
    command_list: string[]
    buttonLink?: string
    buttonText?: string
    showOr?: boolean
  }[] = [
    {
      title: t("getStarted.step_1"),
      text: "",
      command_list: ["npm install champo-ai --save-dev"],
    },
    {
      title: t("getStarted.step_2"),
      text: "",
      command_list: ["export CHAMPO_API_KEY=[YOUR API KEY]"],
      buttonLink: "/api-keys",
      buttonText: t("apiKeys.generateNewKey"),
    },
    {
      title: t("getStarted.step_3"),
      text: "",
      command_list: [],
      buttonLink: "/editor/projects",
      buttonText: t("translationEditor.create_a_project"),
      showOr: true,
    },
    {
      title: t("getStarted.step_4"),
      text: "",
      command_list: [
        "npx champo push",
        "npx champo translate",
        "npx champo pull",
      ],
    },
  ]

  const guideEditorSteps: {
    title: string
    text: string
  }[] = [
    {
      title: "Créez un projet et selectionnez la langue source",
      text: "",
    },
    {
      title: "Ajoutez des langues cibles",
      text: "",
    },
    {
      title: "Importez des fichiers i18n",
      text: "",
    },
    {
      title: "Générez les traductions par IA",
      text: "",
    },
    {
      title: "Invitez des traducteurs pour améliorer les résultats",
      text: "",
    },
  ]

  return (
    <>
      <div className="champo-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined">
          flag
        </span>

        <div className="flex-column">
          <h1 className="champo-title">{t("getStarted.title")}</h1>
        </div>
      </div>

      <div className="basic-container Dashboard">
        <div className="dashboard-container full-width">
          <div className="flex-column full-width">
            <div
              className="pricing-content flex-column"
              style={{ maxWidth: 1200, fontSize: 16, width: "100%" }}
            >
              <AmountBar itemPerLine={2} />

              <div className="flex-row full-width">
                <h2
                  className={
                    "part-title " + (selectedPart === "cli" ? "active" : "")
                  }
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    marginRight: "2rem",
                  }}
                  onClick={() => {
                    setSelectedPart("cli")
                  }}
                >
                  ChampoAI CLI
                </h2>

                <h2
                  className={
                    "part-title " + (selectedPart === "editor" ? "active " : "")
                  }
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    marginRight: "2rem",
                  }}
                  onClick={() => {
                    setSelectedPart("editor")
                  }}
                >
                  {t("translationEditor.title")}
                </h2>

                <h2
                  className={
                    "part-title " + (selectedPart === "direct" ? "active " : "")
                  }
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    marginRight: "2rem",
                  }}
                  onClick={() => {
                    setSelectedPart("direct")
                  }}
                >
                  {t("translate.title")}
                </h2>
              </div>

              {selectedPart === "cli" && (
                <div className="flex-column flex-1">
                  {guideSteps.map((item, index) => (
                    <div className="flex-column">
                      <div
                        className="guide-text flex-row flex-1"
                        style={{ marginRight: 0 }}
                      >
                        <div
                          className="flex-column"
                          style={{
                            marginTop: "1rem",
                            alignItems: "flex-start",
                          }}
                        >
                          <div className="guide-number flex-row flex-center">
                            {index + 1}
                          </div>
                          <div
                            className={
                              "guide-stepper flex-1 " +
                              (index + 1 >= guideSteps.length
                                ? "no-border"
                                : "")
                            }
                            style={{ minHeight: "0" }}
                          ></div>
                        </div>

                        <div className="guide-text-container flex-column flex-1 ">
                          <h3 className="guide-title">
                            <Trans>{item.title}</Trans>
                          </h3>
                          <p>
                            {" "}
                            <Trans>{item.text}</Trans>
                          </p>

                          {item.command_list.length > 0 && (
                            <div
                              className="guide-image flex-1"
                              style={{ marginLeft: "1rem" }}
                            >
                              <div
                                className="bordered-div flex-column"
                                style={{ marginBottom: 0 }}
                              >
                                {item.command_list.map((command) => (
                                  <p>
                                    <code style={{ userSelect: "none" }}>
                                      $
                                    </code>
                                    <code style={{ color: "#fff" }}>
                                      {command}
                                    </code>
                                  </p>
                                ))}
                              </div>
                            </div>
                          )}

                          {/* {item.showOr && (
                            <p
                              style={{
                                marginTop: "1rem",
                                marginLeft: "1rem",
                                marginBottom: 0,
                              }}
                            >
                              {t("getStarted.or")}
                            </p>
                          )} */}
                          {item.buttonLink && item.buttonText && (
                            <NavLink
                              className="button is-primary-gradient"
                              to={item.buttonLink}
                              style={{
                                marginTop: "1rem",
                                marginLeft: "1rem",
                                marginBottom: 0,
                              }}
                            >
                              {item.buttonText}
                            </NavLink>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {selectedPart === "editor" && (
                <div className="flex-column flex-1">
                  <h2
                    className="sub-title"
                    style={{ marginBottom: "0.25em", marginTop: "0.5em" }}
                  >
                    {t("getStarted.file_editor")}
                  </h2>

                  <p style={{ margin: "0.5rem 0" }}>
                    {t("getStarted.file_editor_infos")}
                  </p>

                  <Link
                    className="button is-primary-gradient"
                    style={{ marginTop: "1rem", marginBottom: "0.5em" }}
                    to="/editor"
                  >
                    {t("translationEditor.title")}
                  </Link>

                  {/* <hr />

                  {guideEditorSteps.map((item, index) => (
                    <div className="flex-column">
                      <div
                        className="guide-text flex-row flex-1"
                        style={{ marginRight: 0 }}
                      >
                        <div
                          className="flex-column"
                          style={{
                            marginTop: "1rem",
                            alignItems: "flex-start",
                          }}
                        >
                          <div className="guide-number flex-row flex-center">
                            {index + 1}
                          </div>
                          <div
                            className={
                              "guide-stepper flex-1 " +
                              (index + 1 >= guideEditorSteps.length
                                ? "no-border"
                                : "")
                            }
                            style={{ minHeight: "0" }}
                          ></div>
                        </div>

                        <div className="guide-text-container flex-column flex-1 ">
                          <h3 className="guide-title">
                            <Trans>{item.title}</Trans>
                          </h3>
                          <p>
                            <Trans>{item.text}</Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              )}

              {selectedPart === "direct" && (
                <div className="flex-column flex-1">
                  <h2
                    className="sub-title"
                    style={{ marginBottom: "0.25em", marginTop: "0.5em" }}
                  >
                    {t("translate.file_translations")}
                  </h2>

                  <p style={{ margin: "0.5rem 0" }}>
                    {t("translate.dashboard_disclaimer")}
                  </p>

                  <Link
                    className="button is-primary-gradient"
                    style={{ marginTop: "1rem", marginBottom: "0.5em" }}
                    to="/translate"
                  >
                    {t("translate.title")}
                  </Link>

                  {/* <hr />

                  {guideEditorSteps.map((item, index) => (
                    <div className="flex-column">
                      <div
                        className="guide-text flex-row flex-1"
                        style={{ marginRight: 0 }}
                      >
                        <div
                          className="flex-column"
                          style={{
                            marginTop: "1rem",
                            alignItems: "flex-start",
                          }}
                        >
                          <div className="guide-number flex-row flex-center">
                            {index + 1}
                          </div>
                          <div
                            className={
                              "guide-stepper flex-1 " +
                              (index + 1 >= guideEditorSteps.length
                                ? "no-border"
                                : "")
                            }
                            style={{ minHeight: "0" }}
                          ></div>
                        </div>

                        <div className="guide-text-container flex-column flex-1 ">
                          <h3 className="guide-title">
                            <Trans>{item.title}</Trans>
                          </h3>
                          <p>
                            <Trans>{item.text}</Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              )}
            </div>
          </div>
          <div className="right-infos-div">
            <PremiumItem
              noPrice={true}
              premiumTier={userAccountTier}
              style={{ marginBottom: "1.5rem" }}
            >
              <>
                {alreadyGotFreeCredits === false && (
                  <button
                    className="button is-warn-gradient free-credit-button"
                    style={{ fontSize: 20 }}
                    onClick={() => {
                      handleGetFreeCredits()
                    }}
                    disabled={preventDoubleSubmit}
                  >
                    {preventDoubleSubmit ? (
                      <AnimatedLoader smallNoContainer={true} />
                    ) : (
                      t("credits.get_free_credits_button", {
                        amount: (5000).toLocaleString(),
                      })
                    )}
                  </button>
                )}

                {alreadyGotFreeCredits === true && userAccountTier < 3 && (
                  <Link
                    className="button is-primary-gradient free-credit-button"
                    style={{ fontSize: 20 }}
                    to={"/billing/offers"}
                  >
                    {preventDoubleSubmit ? (
                      <AnimatedLoader smallNoContainer={true} />
                    ) : (
                      t("credits.upgrade_account")
                    )}
                  </Link>
                )}
              </>
            </PremiumItem>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
