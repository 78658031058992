import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getProjectTraducers = ({ projectId }: { projectId: number }) => {
  return axios.get(`${BASE_URL}/project/${projectId}/traducers`, {
    withCredentials: true,
  })
}

export const getProjectTraducerByToken = ({ token }: { token: string }) => {
  return axios.get(`${BASE_URL}/project_traducer/${token}`, {
    withCredentials: true,
  })
}

export const postTraducterInvitation = ({
  mail,
  projectId,
}: {
  mail: string
  projectId: number
}) => {
  return axios.post(
    `${BASE_URL}/project/${projectId}/traducer_invitation`,
    { mail: mail, project_id: projectId },
    {
      withCredentials: true,
    },
  )
}

export const postJoinProject = ({ projectId }: { projectId: number }) => {
  return axios.post(
    `${BASE_URL}/project/${projectId}/join`,
    { project_id: projectId },
    {
      withCredentials: true,
    },
  )
}

export const getTraducerInvitations = () => {
  return axios.get(`${BASE_URL}/traducer_invitations`, {
    withCredentials: true,
  })
}
