import { useContext, useEffect, useMemo, useState } from "react"

import { javascript } from "@codemirror/lang-javascript"
import ReactCodeMirror from "@uiw/react-codemirror"
import { flattie } from "flattie"
import { getEncoding } from "js-tiktoken"
import posthog from "posthog-js"
import ReactGA from "react-ga4"
import { Trans, useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { AuthContext } from "../../context/Auth"
import { TranslationContext } from "../../context/Translation"
import { postTranslate } from "../../services/translate"
import AnimatedLoader from "../components/AnimatedLoader"
import Dropdown from "../components/Dropdown"
import Modal from "../components/Modal"
import ModalLimitReached from "../components/ModalLimitReached"

const encoder = getEncoding("o200k_base")

export const Translate = () => {
  const { t } = useTranslation()

  const { userAccountTier, langUsed, listLangUsed, setUserSubscription } =
    useContext(AuthContext)

  const { languages } = useContext(TranslationContext)

  const navigate = useNavigate()

  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState(false)
  const [preventEditable, setPreventEditable] = useState(false)
  const [notEnoughCredits, setNotEnoughCredits] = useState(false)

  const [tokenLength, setTokenLength] = useState<number>(0)
  const [wordCount, setWordCount] = useState<number>(0)
  const [fileKeyCount, setFileKeyCount] = useState<number | undefined>(
    undefined,
  )
  const [realCost, setRealCost] = useState<number | undefined>()

  const [inputCode, setInputCode] = useState("")
  const [outputCode, setOutputCode] = useState()

  const [inputLang, setInputLang] = useState<string[]>()
  const [outputLang, setOutputLang] = useState<string[]>()
  const [parserType, setParserType] = useState<string>()
  const [modelUsed, setModelUsed] = useState<string>("gpt-4o")

  const [modalTokenLimitIsShown, setModalTokenLimitIsShown] =
    useState<boolean>(false)

  useEffect(() => {
    document.title = t("translate.title")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/translate",
        title: "Direct translation",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const filePrice = useMemo(() => {
    const gptInputTokenPrice = modelUsed === "gpt-4o" ? 0.01 : 0.0005
    const gptOutputTokenPrice = modelUsed === "gpt-4o" ? 0.03 : 0.0015
    const marginMultiplier = modelUsed === "gpt-4o" ? 12.5 : 50

    return (
      tokenLength > 0
        ? (tokenLength + 50) * (gptInputTokenPrice / 1000) * marginMultiplier +
          tokenLength * (gptOutputTokenPrice / 1000) * marginMultiplier
        : 0
    ).toFixed(2)
  }, [tokenLength, modelUsed])

  const onChange = (value: any) => {
    setRealCost(undefined)
    setPreventDoubleSubmit(false)
    // const token = encode(value)
    setInputCode(value)

    // calculateCost(value)
    // }
  }

  useEffect(() => {
    const token = encoder.encode(inputCode)

    // if (inputLang) {
    const segmenter = new Intl.Segmenter("en-US", {
      granularity: "word",
    })
    const segments = segmenter.segment(inputCode)
    let cost = Array.from(segments).length
    if ((token?.length || 0) > cost * 2) {
      cost = token.length
    }
    setTokenLength(token?.length || 0)
    setWordCount(cost)

    try {
      const inputValue = flattie(JSON.parse(inputCode))
      setFileKeyCount(Object.entries(inputValue).length)
    } catch (error) {
      setFileKeyCount(undefined)
    }
  }, [inputCode])

  const handleTranslate = () => {
    if (inputLang && outputLang) {
      setRealCost(undefined)
      setPreventDoubleSubmit(true)
      setPreventEditable(true)
      champoTranslate()
    }
  }

  const champoTranslate = async () => {
    if (inputLang && outputLang) {
      try {
        const result: any = await postTranslate({
          inputData: inputCode,
          inputLang: inputLang[2],
          outputLang: outputLang[2],
          modelUsed: modelUsed,
          parser: parserType ?? "unknown code",
        })
        await setUserSubscription()

        setRealCost(result.data.cost)
        setOutputCode(result.data.output)
        setPreventEditable(false)
      } catch (error) {
        let errorMessage: any = error
        if (errorMessage?.response?.data === "Not enough credits") {
          setNotEnoughCredits(true)
          setModalTokenLimitIsShown(true)
        }
        setRealCost(undefined)
        setPreventDoubleSubmit(false)
        setPreventEditable(false)
      }
    }
  }

  const programmingLanguages = [
    "JSON",
    "JS / TypeScript",
    "GetText",
    "XML",
    "YAML",
    "SOAP",
  ]

  const [resultCopied, setResultCopied] = useState<boolean>(false)

  const copyResult = (result: string) => {
    setResultCopied(true)
    navigator.clipboard.writeText(result)

    setTimeout(() => {
      setResultCopied(false)
    }, 4000)
  }

  // const [langLimit, setLangLimit] = useState<number>(2)
  // useEffect(() => {
  //   if (userAccountTier === 4) {
  //     setLangLimit(10000)
  //   }
  //   if (userAccountTier === 3) {
  //     setLangLimit(10)
  //   }
  //   if (userAccountTier === 2) {
  //     setLangLimit(5)
  //   }
  //   if (userAccountTier === 1) {
  //     setLangLimit(2)
  //   }
  //   if (userAccountTier === 0) {
  //     setLangLimit(1)
  //   }
  // }, [userAccountTier])

  const getFlagClass = (lang: string) => {
    const locale = new Intl.Locale(lang)
    return "fi fi-" + locale?.region?.toLowerCase()
  }

  return (
    <>
      <div className="champo-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined">
          translate
        </span>

        <div className="flex-column">
          <h1 className="champo-title">{t("translate.title")}</h1>
        </div>
      </div>

      <div className="basic-container Translate">
        <div className="language-buttons">
          <div className="button-div flex-row">
            <div className="flex-column" style={{ marginRight: "1rem" }}>
              <p
                className="infos-credits"
                style={{ margin: 0, marginBottom: "0.25rem" }}
              >
                <b className="infos-creits-label">{t("translate.file_type")}</b>
              </p>
              <Dropdown
                className="file-type-selector language-selector"
                title={parserType ? parserType : t("translate.select")}
              >
                {programmingLanguages.map((item) => (
                  <div
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setPreventDoubleSubmit(false)
                      setParserType(item)
                      return true
                    }}
                    className="dropdown-item"
                  >
                    <div
                      className={`dropdown-link flex-row ${
                        false ? "selected" : ""
                      }`}
                    >
                      <span
                        className="text-ellipsis flex-1 "
                        style={{ marginLeft: "1.3rem" }}
                      >
                        {item}
                      </span>
                    </div>
                  </div>
                ))}
              </Dropdown>
            </div>
            <div className="flex-column flex-1">
              <p
                className="infos-credits"
                style={{ margin: 0, marginBottom: "0.25rem" }}
              >
                <b className="infos-creits-label">{t("translate.inputLang")}</b>
              </p>
              <Dropdown
                className="full-width language-selector"
                title={
                  inputLang ? (
                    <div className="flex-row">
                      <div>
                        <span className={getFlagClass(inputLang[2])}></span>
                      </div>
                      <div style={{ marginLeft: "1rem" }}>{inputLang[2]}</div>
                    </div>
                  ) : (
                    t("translate.select")
                  )
                }
                rightMenu={true}
              >
                {languages.map((item) => (
                  <div
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setInputLang(item)
                      return true
                    }}
                    className="dropdown-item"
                  >
                    <div
                      className={`dropdown-link flex-row ${
                        inputLang && item[2] === inputLang[2] ? "selected" : ""
                      }`}
                    >
                      <div
                        className="dropdown-circle"
                        style={{ marginLeft: "0.3rem" }}
                      >
                        <span className={getFlagClass(item[2])}></span>
                      </div>
                      <span
                        className="text-ellipsis flex-1 "
                        style={{ marginLeft: "1.3rem" }}
                      >
                        {item[2]}
                      </span>
                    </div>
                  </div>
                ))}
              </Dropdown>
            </div>
          </div>

          <div className="button-div flex-column">
            <div
              className="infos-credits flex-row"
              style={{ margin: 0, marginBottom: "0.25rem" }}
            >
              <b className="infos-creits-label">{t("translate.outputLang")}</b>
            </div>
            <Dropdown
              className="full-width language-selector"
              title={
                outputLang ? (
                  <div className="flex-row">
                    <div>
                      <span className={getFlagClass(outputLang[2])}></span>
                    </div>
                    <div style={{ marginLeft: "1rem" }}>{outputLang[2]}</div>
                  </div>
                ) : (
                  t("translate.select_language")
                )
              }
              rightMenu={true}
            >
              {listLangUsed?.length === 1 && (
                <p style={{ margin: "0 0.5rem 0.5rem" }}>
                  {t("usage.used_language")}
                </p>
              )}
              {listLangUsed?.length > 1 && (
                <p style={{ margin: "0 0.5rem 0.25rem" }}>
                  {t("usage.used_languages")}
                </p>
              )}

              {listLangUsed?.map((item) => (
                <div
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    if (item[2]) {
                      setOutputLang(item)
                      setPreventDoubleSubmit(false)
                    }
                  }}
                  className="dropdown-item"
                >
                  <div
                    className={`dropdown-link flex-row ${
                      outputLang && item[2] === outputLang[2] ? "selected" : ""
                    }`}
                  >
                    <div
                      className="dropdown-circle"
                      style={{ marginLeft: "0.3rem" }}
                    >
                      <span className={getFlagClass(item[2])}></span>
                    </div>
                    <span
                      className="text-ellipsis flex-1 "
                      style={{ marginLeft: "1.3rem" }}
                    >
                      {item[2]}
                    </span>
                  </div>
                </div>
              ))}
              {listLangUsed?.length > 0 && (
                <hr style={{ margin: "0.5rem 1rem" }} />
              )}
              {languages
                .filter(
                  (i) =>
                    !listLangUsed
                      ?.map((j) => (j[2] ? j[2] : null))
                      ?.includes(i[2]),
                )
                ?.map((item) => (
                  <div
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      setOutputLang(item)
                      setPreventDoubleSubmit(false)
                    }}
                    className="dropdown-item"
                  >
                    <div
                      className={`dropdown-link flex-row ${
                        outputLang && item[2] === outputLang[2]
                          ? "selected"
                          : ""
                      }`}
                    >
                      <div
                        className="dropdown-circle"
                        style={{ marginLeft: "0.3rem" }}
                      >
                        <span className={getFlagClass(item[2])}></span>
                      </div>
                      <span
                        className="text-ellipsis flex-1 "
                        style={{ marginLeft: "1.3rem" }}
                      >
                        {item[2]}
                      </span>
                    </div>
                  </div>
                ))}
            </Dropdown>
          </div>
        </div>
        <div className="translation-container">
          <div
            className="flex-column code-full-container"
            style={{ height: "100%" }}
          >
            <ReactCodeMirror
              className="codemirror-container"
              height="100%"
              style={{
                flex: 1,
                width: "100%",
                marginBottom: "0.5rem",
              }}
              placeholder={t("translate.input_code_placeholder")}
              value={inputCode}
              theme="dark"
              extensions={[javascript({ jsx: true })]}
              onChange={onChange}
              editable={!preventEditable}
            />

            <div
              className="copy-button-cointainer flex-column  hide-tablet"
              style={{
                marginLeft: "0.5rem",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  lineHeight: "22px",
                  margin: 0,
                }}
              >
                <span className={"text"}>
                  <Trans>{t("translate.word", { count: wordCount })}</Trans>
                </span>

                {/* <b className={"text"}>
                  {tokenLength} <span style={{ opacity: 0.5 }}>tokens</span>
                </b> */}
              </p>
              <div className="flex-row full-width">
                <p
                  className="flex-1"
                  style={{ lineHeight: "18px", fontSize: 14, opacity: 0.5 }}
                >
                  <Trans>
                    {t("translationEditor.syncable_keys_lower", {
                      count: fileKeyCount || 0,
                    })}
                  </Trans>
                </p>

                {tokenLength > 0 && !fileKeyCount && (
                  <p
                    style={{
                      lineHeight: "18px",
                      fontSize: 14,
                      marginRight: "1rem",
                    }}
                    className="warn-text"
                  >
                    Not parsed as JSON
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className="flex-column code-full-container last-child"
            style={{ height: "100%" }}
          >
            <ReactCodeMirror
              className="codemirror-container"
              height="100%"
              style={{
                opacity: !outputCode ? 0.25 : 1,
                flex: 1,
                width: "100%",
                marginBottom: "0.5rem",
              }}
              placeholder={t("translate.output_code_placeholder")}
              value={outputCode}
              theme="dark"
              extensions={[javascript({ jsx: true })]}
              onChange={onChange}
              editable={false}
            />
            <div className="copy-button-cointainer">
              <button
                onClick={() => {
                  if (outputCode) copyResult(outputCode)
                }}
                disabled={resultCopied || !outputCode}
                className="copy-button button is-bordered"
              >
                {resultCopied ? (
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{
                      marginLeft: "-0.5rem",
                      marginRight: "0.5rem",
                      opacity: 0.5,
                    }}
                  >
                    check
                  </span>
                ) : (
                  <span
                    className="msymbol material-symbols-outlined"
                    style={{
                      marginLeft: "-0.5rem",
                      marginRight: "0.5rem",
                      opacity: 0.5,
                    }}
                  >
                    content_copy
                  </span>
                )}
                {resultCopied
                  ? t("translate.copy_result")
                  : t("translate.result_copied")}
              </button>
            </div>
          </div>

          <div
            className="copy-button-cointainer flex-column hide-gt-tablet"
            style={{
              marginLeft: "0.25rem",
              marginTop: "0.5rem",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
            }}
          >
            <p
              style={{
                fontSize: 18,
                lineHeight: "20px",
                margin: 0,
              }}
            >
              <b className={3000 - tokenLength < 0 ? "danger-text" : "text"}>
                {tokenLength}{" "}
                <span style={{ opacity: 0.5 }}>
                  / 3000 {t("translate.tokens")}
                </span>
              </b>
            </p>
            <p
              style={{
                fontSize: 16,
                lineHeight: "20px",
                margin: 0,
              }}
            >
              <b className="warn-text half-opacity">{t("commons.important")}</b>{" "}
              - <Trans>{t("translate.limit_soon_removed")}</Trans>
            </p>
          </div>
        </div>

        <div className="translate-buttons flex-row">
          {/* <Dropdown
            buttonClassName="model-button"
            title={modelUsed === "gpt-4-turbo-preview" ? "GPT 4" : "GPT 3.5"}
            customMenuStyle={{ top: -97, left: "0rem" }}
          >
            <div
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                setPreventDoubleSubmit(false)
                setModelUsed("gpt-3.5-turbo")
              }}
              className="dropdown-item"
            >
              <div
                className={`dropdown-link flex-row ${
                  modelUsed === "gpt-3.5-turbo" ? "selected" : ""
                }`}
              >
                GPT 3.5
              </div>
            </div>
            <div
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                if (userAccountTier < 2) {
                  navigate("/billing")
                } else {
                  setPreventDoubleSubmit(false)
                  setModelUsed("gpt-4-turbo-preview")
                }
              }}
              className="dropdown-item"
              style={{ minWidth: 225 }}
            >
              <div
                className={`dropdown-link flex-row ${
                  modelUsed === "gpt-4-turbo-preview" ? "selected" : ""
                }`}
              >
                <div className="flex-1">GPT 4 </div>
                <div className="tier2-div" style={{ marginRight: "-0.6rem" }}>
                  Tier 2
                </div>
              </div>
            </div>
          </Dropdown> */}
          {notEnoughCredits ? (
            <Link
              to="/billing"
              className="translate-button button is-primary-gradient"
            >
              {t("credits.getCredits")}
            </Link>
          ) : (
            <button
              onClick={handleTranslate}
              className="translate-button button is-primary-gradient"
              disabled={preventDoubleSubmit || !inputLang || !outputLang}
            >
              {preventEditable ? (
                <>
                  <div
                    className=" flex-row flex-center"
                    style={{ marginRight: "0.5rem" }}
                  >
                    <AnimatedLoader noContainer={true} />
                  </div>
                  {t("translate.generating")}
                </>
              ) : (
                <>
                  {realCost && preventDoubleSubmit ? (
                    <>
                      <div>
                        {t("translate.translated", {
                          amount: realCost.toFixed(2),
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>{t("translate.translate")}</div>
                    </>
                  )}
                </>
              )}
            </button>
          )}
        </div>
      </div>

      <Modal
        containerStyle={{ maxWidth: 800 }}
        isShown={modalTokenLimitIsShown}
        closeModal={() => {
          setModalTokenLimitIsShown(false)
        }}
      >
        <ModalLimitReached
          closeModal={() => setModalTokenLimitIsShown(false)}
          limitType="tokens"
        ></ModalLimitReached>
      </Modal>
    </>
  )
}

export default Translate
