import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getFileKeysByLang = ({
  fileId,
  lang,
  pageNumber,
  statuses,
}: {
  fileId: number
  lang: string[]
  pageNumber: number
  statuses?: string[]
}) => {
  return axios.get(
    `${BASE_URL}/project/file/${fileId}/page/${pageNumber}?lang=${JSON.stringify(lang)}${statuses ? "&statuses=" + JSON.stringify(statuses) : ""}`,
    {
      withCredentials: true,
    },
  )
}

export const getSearchKeys = ({
  projectId,
  search,
}: {
  projectId: number
  search: string
}) => {
  return axios.get(`${BASE_URL}/project/${projectId}/search/${search}`, {
    withCredentials: true,
  })
}

export const getDownloadFileByLang = ({
  fileId,
  lang,
}: {
  fileId: number
  lang: string
}) => {
  return axios.get(`${BASE_URL}/project/download_file/${fileId}/lang/${lang}`, {
    withCredentials: true,
  })
}

export const getMissingKeysByFileLang = ({
  fileId,
  lang,
}: {
  fileId: number
  lang: string
}) => {
  return axios.get(
    `${BASE_URL}/project/get_missing_keys/${fileId}/lang/${lang}`,
    {
      withCredentials: true,
    },
  )
}

export const updateFileKey = ({
  translatedKeyId,
  value,
  status,
}: {
  translatedKeyId: number
  value: string
  status: string
}) => {
  return axios.put(
    `${BASE_URL}/project/file/translated_key/${translatedKeyId}`,
    {
      value,
      status,
    },
    {
      withCredentials: true,
    },
  )
}

export const postMissingFileKey = ({
  lang,
  keyId,
  value,
  status,
}: {
  lang: string
  keyId: number
  value: string
  status: string
}) => {
  return axios.post(
    `${BASE_URL}/project/file/key/${keyId}/lang/${lang}`,
    {
      value,
      status,
    },
    {
      withCredentials: true,
    },
  )
}

export const postImportFile = ({
  lang,
  projectName,
  is_source_lang,
  filename,
  newkeys,
}: {
  lang: string
  projectName: string
  is_source_lang: boolean
  filename: string
  newkeys: any[]
}) => {
  return axios.post(
    `${BASE_URL}/project/${projectName}/import_file`,
    {
      lang,
      projectName,
      is_source_lang,
      filename,
      newkeys,
    },
    { withCredentials: true },
  )
}

export const deleteFile = ({ fileId }: { fileId: number }) => {
  return axios.delete(`${BASE_URL}/project/file/${fileId}`, {
    withCredentials: true,
  })
}

export const deleteTranslatedKey = ({
  translatedKeyId,
}: {
  translatedKeyId: number
}) => {
  return axios.delete(
    `${BASE_URL}/project/file/translated_key/${translatedKeyId}`,
    {
      withCredentials: true,
    },
  )
}
