import { Link } from "react-router-dom"

type Props = {
  children: React.ReactNode
  onClick?(): void | null
  to?: string
  rel?: string
  target?: string
  className?: string
}

const TextLink = (props: Props) => {
  if (props.onClick) {
    return (
      <button
        className={(props?.className ? props?.className : "") + " TextLink"}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )
  } else if (props.to) {
    return (
      <Link
        className={(props?.className ? props?.className : "") + " TextLink"}
        to={props.to}
        rel={props.rel}
        target={props.target}
      >
        {props.children}
      </Link>
    )
  } else {
    return <></>
  }
}

export default TextLink
