import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getUsagePage = ({ currentPage }: { currentPage: number }) => {
  return axios.get(`${BASE_URL}/requests/${currentPage}`, {
    withCredentials: true,
  })
}

export const postLogout = () => {
  return axios.post(
    `${BASE_URL}/logout`,
    {},
    {
      withCredentials: true,
    },
  )
}
