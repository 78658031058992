const vueI18n = `import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  fallbackLocale: 'en',
  messages: {
    en: {
      dessert_cake: 'I would like to eat a cake. | I would like to eat {count} cakes.',
      dessert_muffin: 'I would like to eat a muffin. | I would like to eat {count} muffins.',
      dessert: 'I would like to eat something.'
    }
  }
})
`

export default vueI18n
