import { CSSProperties } from "react"

export const AmountLabeled = ({
  label,
  amount,
  amountSlashed,
  icon,
  iconColor,
  style,
  className,
}: {
  label: string
  amount: number
  icon?: string
  iconColor?: string
  amountSlashed?: string
  style?: CSSProperties
  className?: string
}) => {
  return (
    <div
      className={"flex-row white-div amount-labeled " + className}
      style={{ alignItems: "center", opacity: 0.75, ...style }}
    >
      {icon && (
        <div
          className={
            "flex-row flex-center amount-icon-container " +
            (icon === "token" ? "yellow-red" : "")
          }
          style={{
            // background: iconColor + "22",
            height: "54px",
            width: "54px",
            borderRadius: "50%",
            marginRight: "0.75rem",
            // opacity: 0.75,
          }}
        >
          <span
            className="msymbol material-symbols-outlined amount-icon"
            style={{ fontSize: "32px" }}
          >
            {icon}
          </span>
        </div>
      )}
      <div className="flex-column">
        <p className="infos-credits" style={{ marginBottom: 0 }}>
          <span className="infos-creits-label">{label}</span>
        </p>
        <p className="balance-text" style={{ marginBottom: 0 }}>
          <span className="balance-amount">
            <span
              style={
                Number(amount) > 0
                  ? {
                      opacity: 1,
                    }
                  : { opacity: 0.5 }
              }
            >
              {amount.toLocaleString()}
            </span>
            {amountSlashed && (
              <span className="half-opacity">
                <span style={{ fontSize: "1.25rem" }}>&nbsp;/&nbsp;</span>
                {amountSlashed}
              </span>
            )}
          </span>
        </p>
      </div>
    </div>
  )
}

export default AmountLabeled
