import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../context/Auth"
import { sendPasswordToken } from "../../services/auth"
import posthog from "posthog-js"
import ReactGA from "react-ga4"

export const Profile = () => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  const [mail, setMail] = useState("")
  const [isError, setError] = useState<string | undefined>()
  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState(false)

  useEffect(() => {
    document.title = t("auth.profile")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/profile",
        title: "Profile",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // const validateEmail = (email: string) => {
  //   return String(email)
  //     .toLowerCase()
  //     .match(
  //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //     )
  // }

  const handleMail = (event: any) => {
    setMail(event.target.value)
  }

  // const postChangeMail = async () => {
  //   setError(undefined)

  //   console.log(validateEmail(mail))
  //   if (!validateEmail(mail)) {
  //     setError("mail_error")
  //   } else {
  //     // const valid_request = await register({ username, password, mail })
  //     const valid_request = true
  //     if (valid_request === true) {
  //       setError(undefined)
  //     } else {
  //       setError("existing_username_or_mail")
  //     }
  //   }
  // }

  const postSendToken = async () => {
    try {
      await sendPasswordToken({ mail: currentUser.mail })
      setPreventDoubleSubmit(false)
    } catch (error) {
      setPreventDoubleSubmit(false)
    }
  }

  useEffect(() => {
    setError(undefined)
    setMail(currentUser.mail)
  }, [currentUser.mail])

  return (
    <>
      <div className="champo-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined">
          account_circle
        </span>

        <div className="flex-column">
          <h1 className="champo-title">{t("auth.profile")}</h1>
        </div>
      </div>

      <div className="basic-container Profile">
        <h2
          className="sub-title"
          style={{
            margin: 0,
            marginBottom: "1rem",
          }}
        >
          {t("auth.id")}
        </h2>

        <p className="infos-credits" style={{ marginBottom: 0, width: "100%" }}>
          <b className="infos-creits-label">{t("auth.username")}</b>
        </p>
        <p className="balance-text">
          <span
            className="balance-amount"
            style={{ opacity: 1, fontSize: "1.75rem" }}
          >
            {currentUser.username}
          </span>
        </p>

        <p className="infos-credits" style={{ marginBottom: 0, width: "100%" }}>
          <b className="infos-creits-label">{t("auth.mail")}</b>
        </p>
        <div className="balance-text">
          <form
            className="flex-column"
            onSubmit={(event) => {
              event.preventDefault()
              // postRegister()
            }}
          >
            <div style={{ display: "flex" }}>
              <div className="control has-icons-left" style={{ minWidth: 300 }}>
                <input
                  disabled
                  className={`input ${
                    isError === "existing_username_or_mail" ||
                    isError === "mail_error"
                      ? "is-danger"
                      : ""
                  }`}
                  type="text"
                  placeholder={t("auth.mail")}
                  value={mail}
                  onChange={handleMail}
                />
                <span className="icon is-small is-left">
                  <span className="msymbol material-symbols-outlined">
                    email
                  </span>
                </span>
              </div>
            </div>
            {/* <div style={{ marginTop: "0.5rem" }}>
              <button
                className="button is-bordered"
                disabled={
                  isError === "existing_username_or_mail" ||
                  isError === "mail_error" ||
                  mail === currentUser.mail
                }
              >
                {t("auth.change_mail")}
              </button>
            </div> */}
          </form>
        </div>

        <p className="infos-credits" style={{ marginBottom: 0, width: "100%" }}>
          <b className="infos-creits-label">{t("auth.password")}</b>
        </p>
        <div className="balance-text">
          <button
            className="button is-bordered"
            disabled={preventDoubleSubmit}
            onClick={() => {
              setPreventDoubleSubmit(true)
              postSendToken()
            }}
          >
            {t("auth.change_password")}
          </button>
        </div>

        {/* <h2
          className="sub-title"
          style={{
            margin: 0,
            marginBottom: "1rem",
          }}
        >
          Coordonées
        </h2> */}
      </div>
    </>
  )
}

export default Profile
