import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

import AnimatedLoader from "../../components/AnimatedLoader"

import { useNavigate } from "react-router-dom"
import { PaymentContext } from "../../../context/Payment"
import posthog from "posthog-js"
import ReactGA from "react-ga4"

export const GetFreeCredits = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    preventDoubleSubmit,
    actionRef,

    setPreventDoubleSubmit,
    setActionRef,
    getFreeCredits,
  } = useContext(PaymentContext)

  useEffect(() => {
    document.title = t("credits.get_free_credits")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/billing/get-free-credits",
        title: "Get Free Credits",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetFreeCredits = async () => {
    setPreventDoubleSubmit(true)
    await getFreeCredits()
    navigate("/billing/offers")
  }

  return (
    <>
      <p className="infos-credits">
        {t("credits.free_credits_infos")}
        <br />
        {t("credits.free_credits_infos2")}
      </p>

      <div className="full-width">
        <button
          className="button is-warn-gradient"
          style={{ fontSize: 20 }}
          onClick={() => {
            setActionRef("getFreeCredit")
            handleGetFreeCredits()
          }}
          disabled={preventDoubleSubmit}
        >
          {actionRef === "getFreeCredit" ? (
            <AnimatedLoader smallNoContainer={true} />
          ) : (
            t("credits.get_free_credits_button", {
              amount: (5000).toLocaleString(),
            })
          )}
        </button>
      </div>
    </>
  )
}

export default GetFreeCredits
