import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { confirmSetupIntent, createSetupIntent } from "../../services/credits"
import AnimatedLoader from "./AnimatedLoader"

type Props = {
  reloadCredits: () => void
}

const SetupForm = ({ reloadCredits }: Props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setPreventDoubleSubmit(true)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      console.log(submitError);
      return;
    }

    const setupIntent = await createSetupIntent()
    const clientSecret = setupIntent.data.client_secret

    localStorage.setItem("setupIntentId", setupIntent.data.si_id)
    localStorage.setItem("paymentType", "add_payment_method")
    
    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: process.env.REACT_APP_FRONT_URL + "/billing",
      },
      redirect: "if_required",
    })

    if (error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(error.message)
      setPreventDoubleSubmit(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      await confirmSetupIntent({ si_id: setupIntent.data.si_id })
      localStorage.removeItem("paymentType")
      localStorage.removeItem("setupIntentId")
      reloadCredits()
    }
  }

  return (
    <form
      onSubmit={(e) => {
        setPreventDoubleSubmit(true)
        handleSubmit(e)
      }}
    >
      <div
        style={{
          minHeight: 230,
        }}
      >
        <h1 className="modal-title">{t("credits.payment_method")}</h1>
        <div
          style={{
            minHeight: 235,
          }}
        >
          <PaymentElement />
        </div>

        {stripe && (
          <button
            className="button is-primary-gradient full-width"
            style={{
              marginTop: "1.5rem",
              height: 48,
              fontSize: 20,
              fontWeight: 800,
              marginBottom: "1rem",
            }}
            type="submit"
            disabled={!stripe || preventDoubleSubmit}
          >
            {preventDoubleSubmit ? (
              <AnimatedLoader noContainer={true} />
            ) : (
              <>{t("credits.add_payment_method")}</>
            )}
          </button>
        )}
      </div>
    </form>
  )
}

export default SetupForm
