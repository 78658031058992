const i18nJs = `{
  "dessert_cake": {
    "one": "I would like to eat a cake.",
    "other": "I would like to eat %{count} cakes."
  },
  "dessert_muffin": {
    "one": "I would like to eat a muffin.",
    "other": "I would like to eat %{count} muffins."
  },
  "dessert": "I would like to eat something."
}`

export default i18nJs
