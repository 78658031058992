import { useContext, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"

import AnimatedLoader from "../../components/AnimatedLoader"

import Modal from "../../components/Modal"

import { Link, useNavigate } from "react-router-dom"
import { AuthContext } from "../../../context/Auth"
import { PaymentContext } from "../../../context/Payment"
import ModalPayment from "../../components/ModalPayment"
import { PremiumItem } from "../../components/PremiumItem"
import posthog from "posthog-js"
import ReactGA from "react-ga4"
import { UIContext } from "../../../context/UI"

export const Offers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  
  const { isDark } = useContext(UIContext)
  const { userAccountTier } = useContext(AuthContext)
  const {
    preventDoubleSubmit,
    actionRef,
    nextInvoiceDate,
    modalChangeSub,
    newTierRequested,
    setPreventDoubleSubmit,
    setActionRef,
    setModalSubscriptionIsShown,
    setPriceIdSelected,
    setTierSelected,
    setIsStripePaymentLoaded,
    setModalChangeSub,
    setNewTierRequested,
    changeSub,

    modalSubscriptionIsShown,
    priceIdSelected,
    refreshCredits,
    refreshInvoices,
    refreshPaymentMethods,
    tierSelected,
    isStripePaymentLoaded,

    creditsToGive,
    newPaymentAmount,
  } = useContext(PaymentContext)

  useEffect(() => {
    document.title = t("credits.upgrade_account")

    if (process.env.REACT_APP_ENV === "production") {
      posthog?.capture("$pageview")
      ReactGA.send({
        hitType: "pageview",
        page: "/billing/upgrade-account",
        title: "Upgrade Account",
      })
    }

    return () => {
      if (process.env.REACT_APP_ENV === "production") {
        posthog?.capture("$pageleave")
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpgradeSubscription = async (tier_number: number) => {
    setPreventDoubleSubmit(true)
    await changeSub(tier_number)
    navigate("/billing/premium-account")
  }

  return (
    <>
      {userAccountTier > 0 && (
        <div className="flex-row full-width">
          <Link
            className="button is-bordered"
            style={{ paddingLeft: 8, marginBottom: "1rem" }}
            to="/billing/premium-account"
          >
            <span
              className="msymbol material-symbols-outlined"
              style={{ marginRight: 8 }}
            >
              keyboard_arrow_left
            </span>
            {t("commons.back")}
          </Link>
        </div>
      )}

      <div
        className="flex-row full-width flex-column-custom"
        style={{ marginBottom: "1rem" }}
      >
        <div className="flex-row flex-1 flex-column-phone-custom">
          <PremiumItem
            style={{
              marginRight: "0.5rem",
              opacity: userAccountTier === 1 ? 0.5 : 1,
            }}
            premiumTier={1}
          >
            {userAccountTier === 0 ? (
              <button
                style={{
                  margin: "0.75rem",
                  height: "48px",
                  marginLeft: "0.75rem",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  fontSize: "20px",
                }}
                className="button is-primary-gradient"
                onClick={() => {
                  if (process.env.REACT_APP_TIER_1_PRICE_ID) {
                    setPriceIdSelected(process.env.REACT_APP_TIER_1_PRICE_ID)
                    setTierSelected(1)
                    setIsStripePaymentLoaded(false)
                    setModalSubscriptionIsShown(true)
                  }
                }}
              >
                {t("credits.get_item")}
              </button>
            ) : (
              <button
                style={{ margin: "0.75rem", marginLeft: "0.75rem" }}
                className="button is-primary-gradient"
                disabled={preventDoubleSubmit || userAccountTier === 1}
                onClick={() => {
                  setNewTierRequested(1)
                  setModalChangeSub(true)
                }}
              >
                {t("credits.change_offer")}
              </button>
            )}
          </PremiumItem>
          <PremiumItem
            style={{
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
              opacity: userAccountTier === 2 ? 0.5 : 1,
            }}
            premiumTier={2}
          >
            {userAccountTier === 0 ? (
              <button
                style={{
                  margin: "0.75rem",
                  height: "48px",
                  marginLeft: "0.75rem",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  fontSize: "20px",
                }}
                className="button is-primary-gradient"
                onClick={() => {
                  if (process.env.REACT_APP_TIER_2_PRICE_ID) {
                    setPriceIdSelected(process.env.REACT_APP_TIER_2_PRICE_ID)
                    setTierSelected(2)
                    setIsStripePaymentLoaded(false)
                    setModalSubscriptionIsShown(true)
                  }
                }}
              >
                {t("credits.get_item")}
              </button>
            ) : (
              <button
                style={{ margin: "0.75rem", marginLeft: "0.75rem" }}
                className="button is-primary-gradient"
                disabled={preventDoubleSubmit || userAccountTier === 2}
                onClick={() => {
                  setNewTierRequested(2)
                  setModalChangeSub(true)
                }}
              >
                {t("credits.change_offer")}
              </button>
            )}
          </PremiumItem>
        </div>

        <div className="flex-row flex-1 flex-column-phone-custom">
          <PremiumItem
            style={{
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
              opacity: userAccountTier === 3 ? 0.5 : 1,
            }}
            premiumTier={3}
          >
            {userAccountTier === 0 ? (
              <button
                style={{
                  margin: "0.75rem",
                  height: "48px",
                  marginLeft: "0.75rem",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  fontSize: "20px",
                }}
                className="button is-primary-gradient"
                onClick={() => {
                  if (process.env.REACT_APP_TIER_3_PRICE_ID) {
                    setPriceIdSelected(process.env.REACT_APP_TIER_3_PRICE_ID)
                    setTierSelected(3)
                    setIsStripePaymentLoaded(false)
                    setModalSubscriptionIsShown(true)
                  }
                }}
              >
                {t("credits.get_item")}
              </button>
            ) : (
              <button
                style={{ margin: "0.75rem", marginLeft: "0.75rem" }}
                className="button is-primary-gradient"
                disabled={preventDoubleSubmit || userAccountTier === 3}
                onClick={() => {
                  setNewTierRequested(3)
                  setModalChangeSub(true)
                }}
              >
                {t("credits.change_offer")}
              </button>
            )}
          </PremiumItem>
          <PremiumItem
            style={{
              marginLeft: "0.5rem",
              opacity: userAccountTier === 4 ? 0.5 : 1,
            }}
            premiumTier={4}
          >
            {userAccountTier === 0 ? (
              <button
                style={{
                  margin: "0.75rem",
                  height: "48px",
                  marginLeft: "0.75rem",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  fontSize: "20px",
                }}
                className="button is-warn-gradient"
                onClick={() => {
                  if (process.env.REACT_APP_TIER_4_PRICE_ID) {
                    setPriceIdSelected(process.env.REACT_APP_TIER_4_PRICE_ID)
                    setTierSelected(4)
                    setIsStripePaymentLoaded(false)
                    setModalSubscriptionIsShown(true)
                  }
                }}
              >
                {t("credits.get_item")}
              </button>
            ) : (
              <button
                style={{ margin: "0.75rem", marginLeft: "0.75rem" }}
                className="button is-warn-gradient"
                disabled={preventDoubleSubmit || userAccountTier === 4}
                onClick={() => {
                  setNewTierRequested(4)
                  setModalChangeSub(true)
                }}
              >
                {t("credits.change_offer")}
              </button>
            )}
          </PremiumItem>
        </div>
      </div>

      {userAccountTier === 0 && (
        <div
          className="white-box no-padding premium-item full-width flex-row flex-column-tablet flex-1 premium-item-tier-0"
          style={{ marginBottom: "2rem" }}
        >
          <div className="premium-item-header flex-column header-row">
            <h3 className="tier-title flex-row">Tier 0</h3>
            <h3 className="price-title flex-row half-opacity">
              {t("credits.free")}
            </h3>
          </div>

          <hr style={{ margin: 0 }} className="hide-gt-tablet" />
          <ul
            className="flex-1 flex-column"
            style={{ justifyContent: "center" }}
          >
            
            <li>
              <Trans>
                {t("credits.free_tokens", {
                  amount: Number(5000).toLocaleString(),
                })}
              </Trans>
            </li>
            <li>
              <Trans>{t("credits.project_limit", { count: 1 })}</Trans>
            </li>
            <li>
              <Trans>{t("credits.source_keys", { amount: 1000 })}</Trans>
            </li>
          </ul>

          {/* <div className="flex-column flex-center">
            <Link
              to="/billing/buy-credits"
              style={{ margin: "0.75rem", marginBottom: 5 }}
              className="button is-primary-gradient"
            >
              {t("credits.getCredits")}
            </Link>
            <p
              style={{
                margin: 0,
                marginBottom: "0.25rem",
                color: "#888",
                fontFamily: "Poppins, sans-serif",
                fontSize: "0.85rem",
              }}
            >
              <b>{t("credits.starting_at")}</b>
            </p>
          </div> */}
        </div>
      )}

      <p
        style={{
          fontSize: "18px",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        {t("credits.premium_infos")}
        <br />
        {t("credits.premium_infos_2")}
      </p>

      <div className="full-width flex-row flex-column-phone">
        <a
          className="button is-bordered mail-button"
          href="mailto:support@champo.ai"
        >
          <span
            className="msymbol material-symbols-outlined half-opacity"
            style={{ marginRight: 16 }}
          >
            mail
          </span>
          {t("commons.send_mail")}
        </a>
        <Link
                className="social-link flex-row flex-center"
                to="https://x.com/intent/follow?screen_name=GabFrk"
                target="_blank"
                style={{ marginLeft: "1.5rem", marginRight: "2.5rem" }}
              >
                <img
                  style={{ width: 32, height: 32 }}
                  src={
                    "/assets/monochrome-social/" +
                    (isDark ? "x-white.svg" : "x.svg")
                  }
                  alt=""
                />
              </Link>

              <Link
                className="social-link flex-row flex-center"
                to="https://www.linkedin.com/in/gabriel-franck-546a009b"
                target="_blank"
              >
                <img
                  style={{ width: 32, height: 32 }}
                  src={
                    "/assets/monochrome-social/" +
                    (isDark ? "linkedin-white.svg" : "linkedin.svg")
                  }
                  alt=""
                />
              </Link>
      </div>

      <Modal
        isShown={modalChangeSub}
        closeModal={() => {
          setModalChangeSub(false)
        }}
        containerStyle={{ maxWidth: 800 }}
      >
        <h1 className="modal-title">{t("credits.change_offer")}</h1>

        {userAccountTier && newTierRequested && (
          <div className="flex-row">
            <PremiumItem
              style={{ marginRight: "0.5rem" }}
              premiumTier={userAccountTier}
            >
              {""}
            </PremiumItem>
            <div className="flex-row flex-center">
              <span
                className="msymbol material-symbols-outlined"
                style={{ fontSize: 50 }}
              >
                keyboard_arrow_right
              </span>
            </div>
            <PremiumItem
              style={{ marginLeft: "0.5rem" }}
              premiumTier={newTierRequested}
            >
              {""}
            </PremiumItem>
          </div>
        )}
        {newTierRequested && (
          <div className="flex-column full-width">
            {newTierRequested > userAccountTier ? (
              <>
                {creditsToGive && (
                  <p
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Trans>
                      {t("credits.prorata_infos", {
                        amount: creditsToGive.toLocaleString(),
                      })}
                    </Trans>
                  </p>
                )}

                <button
                  style={{ margin: "1rem 0 0.5rem", height: 48, fontSize: 18 }}
                  className="button is-primary-gradient"
                  disabled={preventDoubleSubmit || userAccountTier === 4}
                  onClick={() => {
                    setActionRef("changeOfferTier")
                    handleUpgradeSubscription(newTierRequested)
                  }}
                >
                  {actionRef === "changeOfferTier" ? (
                    <AnimatedLoader smallNoContainer={true} />
                  ) : (
                    <>
                      {t("credits.pay")}{" "}
                      {newPaymentAmount && (
                        <>{Number(newPaymentAmount || 0).toFixed(2)}€</>
                      )}
                    </>
                  )}
                </button>

                <p
                  className="text-center full-width half-opacity"
                  style={{ marginBottom: "1rem" }}
                >
                  {t("credits.prorata_payment_infos", {
                    amount: Number(newPaymentAmount || 0).toLocaleString(),
                  })}
                </p>
              </>
            ) : (
              <>
                {nextInvoiceDate && (
                  <p
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Trans>
                      {t("credits.change_date_infos", {
                        changeDate: new Date(
                          nextInvoiceDate,
                        ).toLocaleDateString(),
                      })}
                    </Trans>
                  </p>
                )}

                <button
                  style={{ margin: "1rem 0" }}
                  className="button is-bordered"
                  disabled={preventDoubleSubmit}
                  onClick={() => {
                    setActionRef("changeOfferTier")
                    handleUpgradeSubscription(newTierRequested)
                  }}
                >
                  {actionRef === "changeOfferTier" ? (
                    <AnimatedLoader smallNoContainer={true} />
                  ) : (
                    t("credits.change_offer")
                  )}
                </button>
              </>
            )}
          </div>
        )}
      </Modal>

      <Modal
        containerStyle={{ maxWidth: 800 }}
        isShown={modalSubscriptionIsShown}
        closeModal={() => {
          setIsStripePaymentLoaded(false)
          setModalSubscriptionIsShown(false)
        }}
      >
        <div className="payment-container">
          <div className="payment-stripe-div">
            {priceIdSelected && (
              <ModalPayment
                isLoaded={() => {
                  setIsStripePaymentLoaded(true)
                }}
                priceId={priceIdSelected}
                refreshResult={async () => {
                  await refreshCredits()
                  await refreshInvoices()
                  await refreshPaymentMethods()
                  navigate("/billing/premium-account")
                }}
              />
            )}
          </div>

          {tierSelected && isStripePaymentLoaded && (
            <div className="payment-item-div">
              <PremiumItem
                style={{
                  marginBottom: "1rem",
                  borderRadius: 6,
                  height: "100%",
                }}
                premiumTier={tierSelected}
              >
                <></>
              </PremiumItem>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Offers
