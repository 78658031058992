import "react-toastify/dist/ReactToastify.css"
import "./styles/App.scss"
import "./translations"

import { useCallback, useContext, useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { AuthContext } from "./context/Auth"

import { Navbar } from "./ui/navbar/Navbar"
import { TopBar } from "./ui/navbar/TopBar"

// Pages
import { UIContext } from "./context/UI"
import i18n from "./translations"
import CheckResultBadge from "./ui/components/CheckResultBadge"
import DropdownLanguage from "./ui/components/DropdownLanguage"
import ApiKeys from "./ui/pages/ApiKeys"
import { Auth } from "./ui/pages/Auth"
import AuthRegister from "./ui/pages/AuthRegister"
import Billing from "./ui/pages/Billing"
import BuyCredits from "./ui/pages/Billing/BuyCredits"
import GetFreeCredits from "./ui/pages/Billing/GetFreeCredits"
import Invoices from "./ui/pages/Billing/Invoices"
import Offers from "./ui/pages/Billing/Offers"
import PremiumAccount from "./ui/pages/Billing/PremiumAccount"
import ResiliateSubscription from "./ui/pages/Billing/ResiliateSubscription"
import { Dashboard } from "./ui/pages/Dashboard"
import ForgotPassword from "./ui/pages/ForgotPassword"
import LandingPage from "./ui/pages/LandingPage"
import { Profile } from "./ui/pages/Profile"
import ResetPassword from "./ui/pages/ResetPassword"
import TraducerInvitation from "./ui/pages/TraducerInvitation"
import Translate from "./ui/pages/Translate"
import TranslationEditor from "./ui/pages/TranslationEditor"
import EditFile from "./ui/pages/TranslationEditor/EditFile"
import ImportFile from "./ui/pages/TranslationEditor/ImportFile"
import { Project } from "./ui/pages/TranslationEditor/Project"
import ProjectFiles from "./ui/pages/TranslationEditor/ProjectFiles"
import Projects from "./ui/pages/TranslationEditor/Projects"
import SearchResult from "./ui/pages/TranslationEditor/SearchResult"
import { Usage } from "./ui/pages/Usage"

export const App = (props: any) => {
  const { isLoggedIn, loadingFinished } = useContext(AuthContext)

  const setLang = async (lang: string) => {
    await i18n.changeLanguage(lang)
  }

  useEffect(() => {
    let currentLang = localStorage.getItem("currentLang")
    if (currentLang) setLang(currentLang)
  }, [])

  const getAvailablesRoutes = useCallback(() => {
    if (loadingFinished) {
      if (isLoggedIn) {
        return (
          <Routes>
            <Route
              path="/get-started"
              element={withNavbar(Dashboard)({ ...props })}
            />
            <Route
              path="/translate"
              element={withNavbar(Translate)({ ...props })}
            />
            <Route path="/usage" element={withNavbar(Usage)({ ...props })} />
            <Route
              path="/api-keys"
              element={withNavbar(ApiKeys)({ ...props })}
            />

            <Route
              path="editor"
              element={withNavbar(TranslationEditor)({ ...props })}
            >
              <Route path="projects" element={<Projects />} />

              <Route path="project/:project_id" element={<Project />}>
                <Route path="search/:search_text" element={<SearchResult />} />
                <Route path="files" element={<ProjectFiles />} />

                <Route path="file/import" element={<ImportFile />} />
                <Route path="file/import-new-file" element={<ImportFile />} />
                <Route path="file/:file_id/sync" element={<ImportFile />} />
                <Route
                  path="file/:file_id/lang/:lang/sync"
                  element={<ImportFile />}
                />

                <Route path="file/:file_id/page/:page" element={<EditFile />} />
                <Route path="*" element={<Navigate to="/editor/projects" />} />
              </Route>
            </Route>

            <Route path="billing" element={withNavbar(Billing)({ ...props })}>
              <Route path="free-credits" element={<GetFreeCredits />} />
              <Route path="offers" element={<Offers />} />
              <Route path="buy-credits" element={<BuyCredits />} />
              <Route path="premium-account" element={<PremiumAccount />} />
              <Route path="invoices" element={<Invoices />} />
              <Route
                path="resiliate-subscription"
                element={<ResiliateSubscription />}
              />
              <Route path="*" element={<Navigate to="buy-credits" />} />
            </Route>
            <Route
              path="/profile"
              element={withNavbar(Profile)({ ...props })}
            />
            <Route
              path="/reset-password/:token"
              element={withNavbar(ResetPassword)({ ...props })}
            />
            <Route path="*" element={<Navigate to="/get-started" />} />
          </Routes>
        )
      } else {
        return (
          <Routes>
            <Route
              path="/"
              element={landingContainer(LandingPage)({ ...props })}
            />
            <Route
              path="/auth"
              element={withoutNavbarAndTopbar(Auth)({ ...props })}
            />
            <Route
              path="/register"
              element={withoutNavbarAndTopbar(AuthRegister)({ ...props })}
            />
            <Route
              path="/forgot-password"
              element={withoutNavbarAndTopbar(ForgotPassword)({ ...props })}
            />
            <Route
              path="/traducer-invitation/:token"
              element={withoutNavbarAndTopbar(TraducerInvitation)({ ...props })}
            />
            <Route
              path="/reset-password/:token"
              element={withoutNavbarAndTopbar(ResetPassword)({ ...props })}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )
      }
    }
  }, [isLoggedIn, loadingFinished, props])

  return <BrowserRouter>{getAvailablesRoutes()}</BrowserRouter>
}

const withNavbar = (Component: any) => (props: any) => (
  <div className="main-container-navbar">
    <Navbar />
    <div className="main-content">
      <Component {...props} />
    </div>
  </div>
)
const withoutNavbarAndTopbar = (Component: any) => (props: any) => {
  const { isDark, toggleDarkMode } = useContext(UIContext)
  return (
    <div className="main-container-fullscreen">
      <div className="main-content">
        <TopBar hideLinks={true} />
        <Component {...props} />
      </div>
      <div className="language-overlay flex-row">
        <button
          className="button is-bordered"
          style={{ margin: 0, marginRight: "0.5rem", padding: "0.5rem" }}
          onClick={() => toggleDarkMode()}
        >
          <span className="msymbol material-symbols-outlined">
            {!isDark ? "dark_mode" : "light_mode"}
          </span>
        </button>
        <DropdownLanguage className="full-width" />
      </div>
    </div>
  )
}
const landingContainer = (Component: any) => (props: any) => {
  const { isDark, toggleDarkMode } = useContext(UIContext)

  return (
    <div className="main-container-fullscreen">
      <div className="main-content">
        <Component {...props} />
      </div>
      <div className="language-overlay flex-row">
        <button
          className="button is-bordered"
          style={{
            margin: 0,
            marginRight: "0.5rem",
            padding: "0.5rem",
            width: "40px",
          }}
          onClick={() => toggleDarkMode()}
        >
          <span className="msymbol material-symbols-outlined">
            {!isDark ? "dark_mode" : "light_mode"}
          </span>
        </button>
        <DropdownLanguage />
        <CheckResultBadge />
      </div>
    </div>
  )
}

export default App
