import auth from "./auth.json"
import commons from "./commons.json"
import getStarted from "./getStarted.json"
import usage from "./usage.json"
import landingPage from "./landingPage.json"
import apiKeys from "./apiKeys.json"
import credits from "./credits.json"
import translate from "./translate.json"
import translationEditor from "./translationEditor.json"

export const zhCN = {
  auth,
  commons,
  getStarted,
  usage,
  landingPage,
  apiKeys,
  credits,
  translate,
  translationEditor,
}
